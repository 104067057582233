import React from 'react'
import './styles.scss'

export default function Banner(props) {
    return (
        <div className="banner-wrap">
            <h1>{props.title}</h1>
            {props.sub ? <p>{props.sub}</p> : null}
        </div>
    )
}