import step3 from "../../../assets/point-guide/step3_KOR.png";
import PHstep3 from "../../../assets/point-guide/ph/step3_PH.png";
import INstep3 from "../../../assets/point-guide/in/step3_IN.png";
import INHINDIstep3 from "../../../assets/point-guide/in/step3_IN_HINDI.png";
import THstep3 from "../../../assets/point-guide/th/step3_TH.png";
import VNstep3 from "../../../assets/point-guide/vn/step3_VN.png";
import IDstep3 from "../../../assets/point-guide/id/step3_ID.png";
import { useEffect, useState } from "react";
export default function Step3() {
    const [country, setCountry] = useState("ko");
    const [lang, setLang] = useState("ko");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const uri = new URL(window.location);

        const langValue = uri.searchParams.get("lang");
        if (langValue) setLang(langValue);

        const countryValue = uri.searchParams.get("country");
        if (countryValue) setCountry(countryValue);

        setLoading(true);
    }, []);

    const getImageSrcByCountryAndLang = (country, lang) => {
        if (country === "in" && lang === "hi") {
            return INHINDIstep3;
        }

        const countryImageMap = {
            ko: step3,
            ph: PHstep3,
            in: INstep3,
            th: THstep3,
            vn: VNstep3,
            id: IDstep3,
        };

        return countryImageMap[country] || step3;
    };

    const imageSrc = getImageSrcByCountryAndLang(country, lang);

    return loading ? (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <img src={imageSrc} style={{ width: "100%", maxWidth: "800px", height: "auto", marginBottom: "-0.5px" }} alt={""} />
        </div>
    ) : (
        <div></div>
    );
}
