import { useEffect, useState } from "react";

export default function useGetPolicyDate({ lang }) {
    const [optionDates, setOptionDates] = useState([""]);

    useEffect(() => {
        switch (lang) {
            case "ko":
                setOptionDates(["231016", "231113", "231208"]);
                break;
            case "th":
                // MEMO(philip): Thailand don't support history yet.
                break;
            case "en":
            default:
                setOptionDates(["231016", "231113", "231208"]);
        }
    }, [lang]);

    const dateNumbers = optionDates.map((option) => Number(option)).filter(Boolean);

    const latestDate = String(Math.max(...dateNumbers));

    return { optionDates, latestDate };
}
