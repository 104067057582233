import React from "react";

export default function EnPolicy231208({ theme }) {
    return (
        <div className={`policy-en ${theme === "dark" ? "dark" : ""}`}>
            <p>Privacy Policy</p>
            <b className="red">Last updated : 08/12/2023</b>
            <p>Welcome to CELEBE (the “Platform”).</p>
            <p>The Platform is provided and controlled by CELEBE KOREA CO., LTD (“CELEBE”, “we” or “us”).</p>
            <p>
                We are committed to protecting and respecting your privacy. This Privacy Statement (hereinafter referred to as <b>“this policy”</b>) describes our processing of personal information
                that we collect from you or that you provide us with. If you do not agree with this policy, you should not use this platform.
            </p>
            <p>
                If you have any questions about how we process your personal information, please contact us at <b>Q&amp;A in CELEBe.</b>
            </p>
            <h2 id="summary">
                <strong>SUMMARY</strong>
            </h2>
            <p>
                <strong>What information we collect about you</strong>
            </p>
            <p>
                We collect and process information you give us when you create an account and use the Platform. This includes technical and behavioural information about your use of the Platform. We
                also collect information about you if you download the app and use the Platform without creating an account. For more information.
            </p>
            <p>
                <strong>How we use the information about you</strong>
            </p>
            <p>
                We use your information to provide the Platform to you and to improve and administer it. In order to provide an effective and dynamic Platform, and where we have determined it is in
                our legitimate interests, we use your information to improve and develop the Platform, prevent crime and ensure users’ safety. Where we have your consent, we will also use your
                personal data to serve you targeted advertising and promote the Platform.
            </p>
            <p>
                <strong>Who we share your information with</strong>
            </p>
            <p>
                We share your data with third party service providers who help us to deliver the Platform including cloud storage providers. We also share your information with business partners,
                other companies in the same group as CELEBE (including CELEBE KOREA CO., LTD in the Republic of Korea which provides certain services for us in connection with the Platform), content
                moderation services, measurement providers, advertisers and analytics providers. We may share your information with law enforcement agencies, public authorities or with other third
                parties only where we are legally required to do so or if such use is reasonably necessary (for instance, to ensure your or someone else’s safety).
            </p>
            <p>
                <strong>How long we keep hold of your information</strong>
            </p>
            <p>
                We retain your information for as long as it is necessary to provide you with the service so that we can fulfil our contractual obligations and exercise our rights in relation to the
                information involved. Where we do not need your information in order to provide the service to you, we retain it only as long as we have a legitimate business purpose in keeping such
                data or where we are subject to a legal obligation to retain the data. We will also retain your data if necessary for legal claims.
            </p>
            <p>
                <strong>How we notify you of any changes to this Privacy Policy</strong>
            </p>
            <p>
                We will notify all users of any material changes to this policy through a notice on our Platform or by other means. We update the “Last Updated” date at the top of this policy, which
                reflects the effective date of the policy. By accessing or using the Platform, you acknowledge that you have read this policy and that you understand your rights in relation to your
                personal data and how we will collect, use and process it.
            </p>
            <ol type="1">
                <li>
                    <strong>The Types of Personal Data We Use</strong>
                </li>
            </ol>
            <p>We collect and use the following information about you:</p>
            <ul>
                <li>
                    <strong>Your Profile Information</strong>
                </li>
            </ul>
            <p>
                You give us information when you register on the Platform, including your username, date of birth, email address, and information you disclose in your user profile and your photograph
                or profile video.
            </p>
            <p>
                <strong>User Content and Behavioural Information</strong>
            </p>
            <p>
                We process the content you generate and view on the Platform, including preferences you set (such as choice of language), photographs and videos you upload, comments and live-streams
                you make (“User Content”). We collect information through surveys, challenges and competitions in which you participate. We also collect information regarding your use of the Platform,
                e.g. how you engage with the Platform, including how often you use the Platform and how you interact with content we show you, the ads you view, videos you watch and problems
                encountered, the content you like, the content you save to “Favourites”, the words you search and the users you follow.
            </p>
            <p>
                We infer your interests, gender and age for the purpose of personalising content. We also infer the interests of our users to better optimise advertising across our Platform. If you
                have consented, we will use this information for the purpose of serving personalised advertising.
            </p>
            <p>
                We review User Content for content moderation purposes to enforce our community guidelines, comply with legal obligations, and prevent and respond to abuse, fraud, illegal activity and
                other potentially harmful content on the Platform.
            </p>
            <p>
                We also process information about your followers, the likes you receive and responses to content you upload, for the purposes of personalising your Feed, promoting your content to
                other users and exploring whether your profile presents opportunities for collaboration.
            </p>
            <ul>
                <li>
                    <strong>Information from Third Parties</strong>
                </li>
            </ul>
            <p>You may choose to share certain data with us from third parties or, through your use of the Platform, we may collect such third party data automatically.</p>
            <ul>
                <li>
                    <strong>Business Partners</strong>
                </li>
            </ul>
            <p>
                If you choose to register to use the Platform using your social media account details, you will provide us or allow your social network to provide us with your username and public
                profile. We will likewise share certain information with the relevant social network such as your app ID, access token and the referring URL.
            </p>
            <ul>
                <li>
                    <strong>Advertisers and Advertising Networks</strong>
                </li>
            </ul>
            <p>We may post customized advertisements on our platform from time to time to provide you with this platform free of charge.</p>
            <p>
                We use the information we collect from you and the interaction between you and this platform and other third-party sites to provide more relevant advertising by maximizing your
                interests.
            </p>
            <p>
                We use the above information to understand the websites you visit, the apps you downloaded, your purchase history, to predict your future interests and evaluate how effective
                advertising is on our platform.
            </p>
            <p>
                We use cookies and similar technologies on our app to advertise on our platform and collect the above information using similar information received from third parties operating the
                site you visit
            </p>
            <ul>
                <li>
                    <strong>Technical Information We Collect About You</strong>
                </li>
            </ul>
            <p>
                We collect certain information from you when you use the Platform including when you are using the app without an account. Such information includes your IP address, instance IDs
                (which allow us to determine which devices to deliver messages to), mobile carrier, time zone settings, identifier for advertising purposes and the version of the app you are using. We
                will also collect information regarding the device you are using to access the Platform such as the model of your device, the device system, network type, device ID, your screen
                resolution and operating system, audio settings and connected audio devices. Where you log-in from multiple devices, we will be able to use your profile information to identify your
                activity across devices.
            </p>
            <ul>
                <li>
                    <strong>Location Information</strong>
                </li>
            </ul>
            <p>
                In accordance with the Act on the Protection and Use of Location Information, the Company handles personal location information as follows. We may collect GPS-based location
                information with your consent in order to provide you with customized content or advertisements that are optimized for you. Your location information collected will provide you with an
                appropriate celeb experience. If you do not allow the transmission of location information in the terminal settings, location information is not transmitted. However, the company may
                estimate the approximate location of the customer from network information such as IP address even if the customer does not permit the transmission of location information. We do not
                provide personal location information to third parties without the user’s prior consent.
            </p>
            <ul>
                <li>
                    <strong>Information About Your Friends</strong>
                </li>
            </ul>
            <p>
                You can choose whether to find other users of the Platform using our “Find Friends” function and to invite your contacts to join you on the Platform. We will use the contact
                information you have for that person, such as from within your telephone’s contact list or Facebook friends list, and give you the option to send them either an SMS, email or third
                party message inviting them to view your CELEBE profile.
            </p>
            <p>
                We use your personal data to find matches based on your contact information and your address book. However, your contact information and your address book is private and will not be
                disclosed. We use a technical system to find matches without sending your own information or your address book to us in plain text.
            </p>
            <p>
                If you want to find other users through your SNS contacts, you can grant us access to your Facebook contact list. We will then collect your public Facebook information as well as names
                and profiles of your Facebook contacts. We will match this to registered users and show you any matches so you can follow them.
            </p>
            <ul>
                <li>
                    <strong>In-app Purchases</strong>
                </li>
            </ul>
            <p>
                If you make in-app purchases, please review our Virtual Items Policy. Your purchase will be made via your Apple iTunes or Google Play account. We do not collect any financial or
                billing information from you in relation to such a transaction. Please review the relevant app store’s terms and notices to learn about how your data is used. We keep a record of the
                purchases you make, the time at which you make those purchases and the amount spent so that we can credit your account with the correct value in points.
            </p>
            <ul>
                <li>
                    <strong>Cookies</strong>
                </li>
            </ul>
            <p>
                We, our vendors and service providers, automatically collect information using cookies and other similar technologies (e.g., web beacons, flash cookies, etc.) to measure and analyze
                the web pages you click and how you use this platform, improve our service experience, and provide customized advertising to you.
            </p>
            <p>Cookies are small files that allow this platform to provide specific capabilities and are installed on your device.</p>
            <p>
                Web beacons are very small images or data inserted within an image, sometimes referred to as “pixel tags” or “clear GIFs” and recognize cookies, time and date of viewing pages,
                descriptions of pages with pixel tags installed, and similar information collected from your computer or device.
            </p>
            <p>By using this platform, you agree to use cookies.</p>
            <p>
                We also allow our business partners, advertising networks, and other advertising vendors and service providers (including analytical vendors and service providers) to collect
                information about your online activities through cookies.
            </p>
            <p>Such third parties may use this information to place advertisements on our platform and online to suit your interests, preferences and characteristics.</p>
            <p>
                We are not responsible for the processing of personal information by any of the above third parties, and this policy does not address the practice of processing information by any
                third parties.
            </p>
            <p>You can adjust your browser settings to reject or disable cookies.</p>
            <p>Because each browser is different, please refer to the instructions or descriptions provided by your browser.</p>
            <p>Rejecting or disabling certain types of cookies may require additional action.</p>
            <p>
                For example, there is a difference in how browsers and mobile apps function, so you may need to take different actions to block cookies used for customized ads in your browser or to
                adjust your device settings or mobile app permissions.
            </p>
            <p>Additionally, your choice of blocking is limited to the specific browser or device you use when blocking, so separate blocking actions may be required for each browser or device.</p>
            <p>If you reject, disable, or delete cookies, some features of this platform may not be available to you.</p>
            <ol start="2" type="1">
                <li>
                    <strong>How We Use Your Personal Data</strong>
                </li>
            </ol>
            <p>We will use the information we collect about you based on the legal grounds described below.</p>
            <p>In accordance with, and to perform our contract with you, we will use your information to:</p>
            <ul>
                <li>Notify you about changes to our service</li>
                <li>Provide you with user support</li>
                <li>Personalise the content you receive and provide you with tailored content that will be of interest to you</li>
                <li>Enable you to share User Content and interact with other users</li>
                <li>Enable you to activate our messenger service, if you choose to use our messenger service</li>
                <li>Enable you to participate in the virtual items program</li>
                <li>Communicate with you</li>
                <li>Help us prevent and respond to abuse, fraud, illegal activity and other potentially harmful content on the Platform</li>
                <li>Ensure your safety and security, including reviewing User Content, messages and associated metadata for breaches of our Community Guidelines and our Terms of Service</li>
                <li>Ensure content is presented in the most effective manner for you and your device</li>
                <li>Understand how people use the Platform so that we can improve and promote popular topics, hashtags and campaigns on the Platform</li>
                <li>Carry out data analysis and test the Platform to ensure its stability and security</li>
                <li>Allow you to participate in interactive features of the Platform</li>
                <li>
                    Enable you to socialise on the Platform. For example, we may allow other users to identify you via the “Find Friends” function or through their phone contacts or connect you with
                    other users by tracking who you share links with
                </li>
                <li>Verify that you are of appropriate age to use the Platform</li>
                <li>Provide personalised advertising based on the information our advertising partners provide to us</li>
                <li>Provide you with location-based services</li>
                <li>Enforce our terms and policies</li>
                <li>Manage the Platform, including handling failures</li>
            </ul>
            <ol start="3" type="1">
                <li>
                    <strong>How We Share Your Personal Data</strong>
                </li>
            </ol>
            <p>We share your data with the following selected third parties:</p>
            <ul>
                <li>
                    <strong>Business Partners</strong>
                </li>
            </ul>
            <p>
                If you choose to register to use the Platform using your social network account details, you provide us or allow your social network to provide us with your username and public
                profile. We will likewise share certain information with the relevant social network such as your app ID, access token and the referring URL.
            </p>
            <p>
                Where you opt to share content on social media platforms, the video, username and any text associated with the post will be shared on that platform or, in the case of sharing via
                instant messaging platforms, a link to the content will be shared.
            </p>
            <ul>
                <li>
                    <strong>Payment Providers</strong>
                </li>
            </ul>
            <p>
                If you choose to buy virtual items we will share data with the relevant payment provider to facilitate this transaction. We share a transaction ID to enable us to identify you and
                credit your account with the correct value in points once you have made the payment.
            </p>
            <ul>
                <li>
                    <strong>Service Providers</strong>
                </li>
            </ul>
            <p>
                We provide information and content to service providers who support our business, such as cloud service providers and providers of content moderation services to ensure that the
                Platform is a safe and enjoyable place.
            </p>
            <ul>
                <li>
                    <strong>Analytics and Measurement Providers</strong>
                </li>
            </ul>
            <p>
                We use analytics and measurement providers to help us improve the Platform including by assisting us with content measurement and following your activity on our Platform across your
                devices.
            </p>
            <ul>
                <li>
                    <strong>Advertisers</strong>
                </li>
            </ul>
            <p>
                We only share aggregated user information with advertisers. Aggregated information is information that is grouped together and is not specific to an individual user. This is done to
                help measure the effectiveness of an advertising campaign by showing advertisers how many users of the Platform have viewed or clicked on an advertisement.
            </p>
            <ul>
                <li>
                    <strong>Our Corporate Group</strong>
                </li>
            </ul>
            <p>
                We may share your information with other members, subsidiaries, or affiliates of our corporate group where it is necessary to provide the Platform in accordance with the Terms of
                Service.
            </p>
            <p>We share information to improve and optimise the Platform, including to prevent illegal use and to support users.</p>
            <ul>
                <li>
                    <strong>Law Enforcement / Legal Obligation</strong>
                </li>
            </ul>
            <p>
                We may share your information with law enforcement agencies, public authorities or other third parties if we consider that we are legally required to do so or if such use is reasonably
                necessary to:
            </p>
            <ul>
                <li>Comply with a legal process or request</li>
                <li>Enforce our Terms of Service and other agreements, policies, and standards, including investigation of any potential violation</li>
                <li>Detect, prevent or otherwise address abuse, fraud, illegal activity or security or technical issues</li>
                <li>
                    Protect the rights, property or safety of us, our users, a third party or the public as required or permitted by law (including exchanging information with other companies and
                    organisations for the purposes of fraud protection and credit risk reduction)
                </li>
                <li>
                    <strong>Public Profiles</strong>
                </li>
            </ul>
            <p>
                If your profile is public, your content will be visible to anyone on the Platform and may also be accessed or shared by your friends and followers as well as third parties such as
                search engines, content aggregators and news sites. You can change who can see a video each time you upload a video. You can also change your profile to private by changing your
                settings to ‘Private account’ in ‘Privacy and safety’ settings.
            </p>
            <ul>
                <li>
                    <strong>Sale or Merger</strong>
                </li>
            </ul>
            <p>We disclose your information to third parties:</p>
            <p>
                in the event that we sell or buy any business or assets (for example, as a result of liquidation, bankruptcy or otherwise). In such transactions, we will disclose your data to the
                prospective seller or buyer of such business or assets; or
            </p>
            <p>
                if we sell, buy, merge, are acquired by, or partner with other companies or businesses, or sell some or all of our assets. In such transactions, user information may be among the
                transferred assets.
            </p>
            <ol start="4" type="1">
                <li>
                    <strong>Your Rights</strong>
                </li>
            </ol>
            <p>
                You can access and edit most of your profile information as you subscribe to CELEBE. You may delete user content that you have uploaded. We also provide a number of setup tools that
                you can adjust, and you have control over who can view your video, who can send you a message, and who can leave comments on your video. If desired, you can delete all of your accounts
                from Settings. If you have any questions about how to use the tool or would like to know about your rights in your country of residence, please contact us at <b>Q&amp;A at CELEBe.</b>
            </p>
            <ol start="5" type="1">
                <li>
                    <strong>The Security of Your Personal Data</strong>
                </li>
            </ol>
            <p>
                We take steps to ensure that your information is treated securely and in accordance with this policy. Unfortunately, the transmission of information via the internet is not completely
                secure. Although we will do our best to protect your personal data, for example, by encryption, we cannot guarantee the security of your information transmitted via the Platform, which
                means any transmission is at your own risk.
            </p>
            <p>
                We have appropriate technical and organisational measures to ensure a level of security appropriate to the risk that may be posed to you and other users. We maintain these technical
                and organisational measures and will amend them from time to time to improve the overall security of our systems.
            </p>
            <p>
                We will, from time to time, include links to and from the websites of our partner networks, advertisers and affiliates. If you follow a link to any of these websites, please note that
                these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any
                information to these websites.
            </p>
            <ol start="6" type="1">
                <li>
                    <strong>How Long We Keep Your Personal Data</strong>
                </li>
            </ol>
            <p>
                We retain your information for as long as it is necessary to provide you with the service so that we can fulfil our contractual obligations and exercise our rights in relation to the
                information involved. Where we do not need your information in order to provide the service to you, we retain it only for so long as we have a legitimate business purpose in keeping
                such data.
            </p>
            <ol start="7" type="1">
                <li>
                    <strong>Information Relating to Children</strong>
                </li>
            </ol>
            <p>
                CELEBE is not directed at children under the age of 13. If you believe that we have personal data about or collected from a child under the relevant age, contact us at{" "}
                <strong>Q&amp;A in CELEBe.</strong>
            </p>
            <ol start="8" type="1">
                <li>
                    <strong>User Complaints</strong>
                </li>
            </ol>
            <p>
                If you would like to complain about the way we process your personal information, please contact us first at <strong>Q&amp;A in CELEBe</strong> and we will try to expedite your
                request. The foregoing does not infringe on your right to file a complaint with the relevant Privacy Regulator.
            </p>
            <ol start="9" type="1">
                <li>
                    <strong>Changes</strong>
                </li>
            </ol>
            <p>
                We will notify you of any material changes to this policy through a notice provided via the Platform or by other means. The “Last Updated” date at the top of this policy reflects the
                effective date of such policy changes.
            </p>
            <p>
                We are committed to protecting and respecting your privacy. This policy explains our practices concerning the personal data we collect from you, or that you provide to us. If you do
                not agree with this policy, you should not use the Platform. To view previous versions of our Privacy Policy, please click here.
            </p>
            <ol start="10" type="1">
                <li>
                    <strong>Contact</strong>
                </li>
            </ol>
            <p>
                If you have any questions about how we use your personal data, contact us at <strong>Q&amp;A in the app</strong>
            </p>
            <p>
                <strong>Additional Terms and Conditions Applicable to Specific Jurisdictions</strong>
            </p>
            <p>
                In the event of a conflict between the terms of a particular jurisdictional add-on (hereinafter referred to as the “Additional Terms”) and the main body of this policy in the
                jurisdiction where you access this service or use this service, the additional terms and conditions in that jurisdiction shall apply first apply.
            </p>
            <p>
                <strong>Republic of Korea</strong>
            </p>
            <p>
                If you are using our services in Korea, the following additional terms and conditions apply. In the event of a conflict between the Additional Terms and Conditions and the Body Clause
                of this Policy, the Additional Terms and Conditions shall prevail.
            </p>
            <p>
                <strong>How you share your personal information</strong>
            </p>
            <p>
                In addition to the body of this policy, if we use information such as user-generated and video content that you post on our platform for promotional purposes at your choice as part of
                our advertising and marketing campaign, your personal information may be disclosed to the recipient.
            </p>
            <p>
                <strong>Information Retention</strong>
            </p>
            <p>
                We destroy any personal information that has been achieved for the purpose of the collection you have agreed to, or that the period of use you agreed to, or that has expired as
                specified in this policy. However, we will continue to retain your personal information during the legal period, including but not limited to:
            </p>
            <p>
                <strong>The Consumer Protection Act in Electronic Commerce, etc. Act</strong>
            </p>
            <ul>
                <li>Your order cancellation record, payment record for purchase items, and our product/service delivery record: 5 years</li>
                <li>Record of handling consumer complaints or disputes: 3 years</li>
                <li>Advertising and marking records: 6 months</li>
            </ul>
            <p>
                <strong>Communications Secrets Act</strong>
            </p>
            <ul>
                <li>Your visit to our website is: 3 months</li>
            </ul>
            <p>
                <strong>Destruction of Personal Information</strong>
            </p>
            <p>We destroy your personal information in a way that the relevant department cannot recover.</p>
            <p>
                <strong>Your Rights</strong>
            </p>
            <ul>
                <li>
                    <strong>Right to Information and Account</strong> You have the right to request access to your personal information, correction, deletion, suspension of processing of inaccurate
                    information, and deletion of account. You may exercise your rights by asking at <span onClick={() => (window.location.href = "celebe://deeplink/qna")}>Q&A </span>in CELEBe or{" "}
                    <span onClick={() => (window.location.href = "https://forms.gle/U28UTEu8wE8WJLxx9")}>https://forms.gle/U28UTEu8wE8WJLxx9</span>.
                </li>
            </ul>
            <p>
                <strong>Information Security</strong>
            </p>
            <p>
                We are committed to protecting Seller’s expenses and users from unauthorized access to or from unauthorized alteration, unauthorized disclosure or destruction of the information we
                have. To this end, we are implementing technical, administrative, and physical protection measures, including establishing an internal management plan for information protection,
                granting access to only the necessary personnel, and controlling access to personal information processing facilities.
            </p>
            <p>
                <strong>Change in Policy</strong>
            </p>
            <p>
                If we make any changes to this policy, we will notify you of any changes on our website or on our Services page at least 7 days prior to the effective date of the revised policy (30
                days prior if the amendment significantly affects your rights or obligations under this policy).
            </p>
            <p>
                <strong>Child Information</strong>
            </p>
            <p>CELEBE are not intended for children under 14 years of age.</p>
            <p>
                <strong>Consignment and/or Transfer of Personal Information</strong>
            </p>
            <p>
                In some cases, we obtain your consent or notify you to entrust your information to our affiliates, cloud service providers, IT service providers and data centers, and some of our
                trustees are located overseas (see the list here). The parties receiving and processing your information are committed to taking all possible physical and technical steps to access,
                store, and protect your privacy in compliance with national and international regulations. If the transfer of personal information is unnecessary in providing this service to you, you
                may ask Q&amp;A at CELEBe to refuse the transfer of personal information.
            </p>
            <p>
                <strong>Other inquiries</strong>
            </p>
            <p>Please write your inquiries at Q&amp;A at CELEBe</p>
        </div>
    );
}
