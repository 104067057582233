import React from "react"
import KoTerm221103 from "./KoTerm221103"
import KoTerm231113 from "./KoTerm231113"
import KoTerm231211 from "./KoTerm231211"
import KoTerm240725 from "./KoTerm240725"

export default function TermsKo({ theme, date }) {
    const policy = () => {
        switch (date) {
            case "221103":
                return <KoTerm221103 />
            case "231113":
                return <KoTerm231113 />
            case "231211":
                return <KoTerm231211 />
            case "240725":
                return <KoTerm240725 />
            default:
                return ""
        }
    }
    return (
        <div className={`terms-ko ${theme === "dark" ? "dark" : ""}`}>
            <h1>셀러비 서비스 약관</h1>
            {policy()}
        </div>
    )
}
