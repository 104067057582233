import React from "react";

export default function AdvertisingInformationKo() {
    return (
        <div className="apps">
            <p className="title-1">광고성 정보 수신 동의</p>
            <p style={{ marginBottom: 10 }}>
                본 동의는 셀러비코리아에서 수집한 아래와 같은 항목을 이용하여 고객님에게 전자적 전송매체를 통해 마케팅 등의 목적으로 개인에게 최적화된 광고 또는 정보를 전송하는 것에 대한 광고성 정보
                수신동의로서, 고객님은 본 동의를 거부할 권리가 있으며, 본 동의를 거부할 경우 받는 별도의 불이익은 없습니다.
            </p>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <p>이용 목적</p>
                        </td>
                        <td>
                            <p>전자적 전송매체를 통해, 셀러비의 상품 또는 서비스에 대한 개인 맞춤형 광고·정보 전송</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>이용 항목</p>
                        </td>
                        <td>
                            <p>이름, 이메일, 성별,</p>
                            <p>생년월일, 관심사, 고객ID, 기기 식별 ID, IP 주소</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>보유 및 이용 기간</p>
                        </td>
                        <td>
                            <p>동의 철회 전 또는 탈퇴시까지</p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ margin: "10px 0px" }}>안내</p>
            <ul style={{ listStyle: "inside", margin: "10px", color: "#fff", lineHeight: "23px", fontSize: "12px" }}>
                <li style={{ textIndent: "-12px", marginLeft: "10px" }}>셀러비 탈퇴 전까지는 위와 같은 광고성 정보 수신 동의에 따라서 다양한 정보 및 광고를 받아보실 수 있습니다.</li>
                <li style={{ textIndent: "-12px", marginLeft: "10px" }}>광고성 정보 수신을 동의하지 않더라도 고객 보호를 위해 안내메일은 발송될 수 있습니다.</li>
                <li style={{ textIndent: "-12px", marginLeft: "10px" }}>
                    법령에 따른 개인정보의 수집/이용, 계약의 이행/편의증진을 위한 개인정보 처리위탁 및 개인정보 처리와 관련된 일반 사항은 서비스의 개인정보 처리방침에 따릅니다.
                </li>
                <li style={{ textIndent: "-12px", marginLeft: "10px" }}>{`동의 철회(수신거부)는 [마이페이지] > [설정] > [알림]에서 가능합니다.`}</li>
            </ul>
        </div>
    );
}
