import React from "react"
import EnTerm221103 from "./EnTerm221103"
import EnTerm231113 from "./EnTerm231113"
import EnTerm231211 from "./EnTerm231211"
import EnTerm240725 from "./EnTerm240725"

export default function TermsEn({ theme, date }) {
    const policy = () => {
        switch (date) {
            case "221103":
                return <EnTerm221103 />
            case "231113":
                return <EnTerm231113 />
            case "231211":
                return <EnTerm231211 />
            case "240725":
                return <EnTerm240725 />
            default:
                return ""
        }
    }
    return <div className={`terms-en ${theme === "dark" ? "dark" : ""}`}>{policy()}</div>
}
