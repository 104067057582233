import React from 'react';

export default function TermsTh({ theme }) {

    return (
        <div className={`policy-th ${theme === 'dark' ? 'dark' : ''}`}>
            <p className="title-1">นโยบายความเป็นส่วนตัว</p>
            <br />
            <p className="title-5">ปรับปรุงล่าสุด : 3 พฤศจิกายน พ.ศ. 2565</p>
            <p className="title-5"> ยินดีต้อนรับสู่ CELEBE (“แพลตฟอร์ม”) </p>
            <p>
                แพลตฟอร์มนี้ให้บริการและอยู่ภายใต้การควบคุมโดย บริษัท เซเลบี โคเรีย จำกัด (“CELEBE”, “เรา” หรือ “พวกเรา”)
            </p>
            <p>
                เรามุ่งมั่นที่จะปกป้องและเคารพความเป็นส่วนตัวของคุณ   นโยบายความเป็นส่วนตัวนี้ (ซึ่งต่อไปในเอกสารนี้เรียกว่า “นโยบายนี้”) อธิบายการประมวลผลข้อมูลส่วนบุคคลที่เรารวบรวมจากคุณหรือที่คุณให้ไว้กับเรา   คุณไม่ควรใช้แพลตฟอร์มนี้ หากคุณไม่เห็นด้วยกับนโยบายนี้
            </p>
            <p>
                หากคุณมีคำถามใด ๆ เกี่ยวกับวิธีที่เราประมวลผลข้อมูลส่วนบุคคลของคุณ โปรดติดต่อเราที่ info@celebe.io
            </p>
            <p className="title-3">สรุป</p>
            <p className="title-3">ข้อมูลส่วนตัวของคุณที่เราทำการเก็บรวบรวม?</p>
            <p>
                เรารวบรวมและประมวลผลข้อมูลที่คุณให้กับเราเมื่อคุณสร้างบัญชีและใช้งานแพลตฟอร์ม ซึ่งรวมถึงข้อมูลทางเทคนิคและพฤติกรรมเกี่ยวกับการใช้แพลตฟอร์มของคุณ   นอกจากนี้เรายังรวบรวมข้อมูลเกี่ยวกับคุณหากคุณดาวน์โหลดแอปพลิเคชันและใช้แพลตฟอร์มโดยไม่ได้สร้างบัญชี เพื่อให้ได้ข้อมูลเพิ่มเติม
            </p>
            <p className="title-3">วิธีการใช้ข้อมูลส่วนบุคคลของคุณ?</p>
            <p>
                เราจะใช้ข้อมูลของคุณเพื่อให้บริการ แพลตฟอร์มแก่คุณ รวมถึงการปรับปรุงและการจัดการแพลตฟอร์ม และเพื่อให้แพลตฟอร์มมีประสิทธิภาพและมีลักษณะแบบพลวัต และเมื่อเราพิจารณาแล้วว่าเป็นผลประโยชน์โดยชอบด้วยกฎหมายของเรา เราจะใช้ข้อมูลของคุณเพื่อปรับปรุงและพัฒนาแพลตฟอร์ม ป้องกันอาชญากรรม และรับรองความปลอดภัยของผู้ใช้   ในกรณีที่เราได้รับความยินยอมจากคุณ เราจะใช้ข้อมูลส่วนบุคคลของคุณเพื่อให้บริการโฆษณาตามเป้าหมายและส่งเสริมแพลตฟอร์ม
            </p>
            <p className="title-3">เราแบ่งปันข้อมูลของคุณกับใครบ้าง?</p>
            <p>
                เราจะแบ่งปันข้อมูลของคุณกับผู้ให้บริการบุคคลที่สามที่ช่วยเราในการให้บริการแพลตฟอร์ม รวมถึง            ผู้ให้บริการการเก็บข้อมูลบนคลาวด์   นอกจากนี้เรายังแบ่งปันข้อมูลของคุณกับพันธมิตรทางธุรกิจ บริษัท           อื่น ๆ ในกลุ่มเดียวกับ CELEBE (รวมถึง บริษัท เซเลบี โคเรีย จำกัด ในสาธารณรัฐเกาหลีซึ่งให้บริการบางอย่างแก่เรา ในส่วนที่เกี่ยวข้องกับแพลตฟอร์ม) บริการการตรวจเนื้อหา ผู้ให้บริการการวัดผล ผู้โฆษณา และ          ผู้ให้บริการวิเคราะห์   เราอาจแบ่งปันข้อมูลของคุณกับหน่วยงานบังคับใช้กฎหมาย หน่วยงานของรัฐ หรือบุคคลที่สามอื่นๆ เฉพาะในกรณีที่กฎหมายกำหนดให้เราทำเช่นนั้น หรือหากการใช้ข้อมูลดังกล่าวมี                   ความจำเป็นตามสมควร (เช่น เพื่อความปลอดภัยของคุณหรือบุคคลอื่น)
            </p>
            <p className="title-3">ระยะเวลาการเก็บข้อมูลส่วนบุคคลของคุณ?</p>
            <p>
                เราจะเก็บรักษาข้อมูลของคุณไว้ตราบเท่าที่จำเป็นในการให้บริการแก่คุณ เพื่อให้เราสามารถปฏิบัติตามภาระผูกพันตามสัญญาและใช้สิทธิของเราในส่วนที่เกี่ยวข้องกับข้อมูลที่เกี่ยวข้อง   ในกรณีที่เราไม่จำเป็นต้องใช้ข้อมูลของคุณเพื่อให้บริการแก่คุณ เราจะเก็บรักษาข้อมูลของคุณไว้ตราบเท่าที่เรามีวัตถุประสงค์ทางธุรกิจ         ที่ถูกต้องตามกฎหมายในการเก็บข้อมูลดังกล่าว หรือในกรณีที่เราอยู่ภายใต้ภาระผูกพันทางกฎหมายในการเก็บรักษาข้อมูล   นอกจากนี้ เราจะเก็บรักษาข้อมูลของคุณไว้หากจำเป็นกรณีที่มีการเรียกร้องทางกฎหมาย
            </p>
            <p className="title-3">วิธีการในการแจ้งให้คุณทราบเกี่ยวกับการเปลี่ยนแปลงนโยบายความเป็นส่วนตัวนี้?</p>
            <p>
                เราจะแจ้งให้ผู้ใช้ทุกคนทราบถึงการเปลี่ยนแปลงที่สำคัญในนโยบายนี้ผ่านทางประกาศบนแพลตฟอร์มของเราหรือโดยวิธีการอื่น   เราจะปรับปรุงวันที่ “ปรับปรุงล่าสุด” ที่ด้านบนของนโยบายนี้ ซึ่งสะท้อนถึงวันที่นโยบายนี้มีผลบังคับใช้   เมื่อคุณเข้าถึงหรือใช้แพลตฟอร์ม ถือว่าคุณรับทราบว่าคุณได้อ่านนโยบายนี้แล้ว และคุณเข้าใจถึงสิทธิเกี่ยวกับข้อมูลส่วนบุคคลของคุณ และวิธีการที่เราเก็บรวบรวม ใช้ และประมวลผลข้อมูลดังกล่าว
            </p>
            <p className="title-3">1. ประเภทของข้อมูลส่วนบุคคลที่เราใช้</p>
            <p>
                เรารวบรวมและใช้ข้อมูลของคุณดังต่อไปนี้:
            </p>
            <p className="title-3">ข้อมูลโปรไฟล์ของคุณ</p>
            <p>
                คุณจะต้องให้ข้อมูลแก่เราเมื่อคุณลงทะเบียนใช้งานบนแพลตฟอร์ม ซึ่งได้แก่ ชื่อผู้ใช้ วันเกิด ที่อยู่อีเมล และ/หรือ หมายเลขโทรศัพท์ ข้อมูลที่คุณเปิดเผยในโปรไฟล์ผู้ใช้และรูปถ่ายหรือวิดีโอโปรไฟล์ของคุณ
            </p>
            <p className="title-3">เนื้อหาและข้อมูลพฤติกรรมของผู้ใช้</p>
            <p>
                เราจะประมวลผลเนื้อหาที่คุณสร้างและดูบนแพลตฟอร์ม รวมถึงการตั้งค่าต่าง ๆ (เช่น ตัวเลือกภาษา) ภาพถ่ายและวิดีโอที่คุณอัปโหลด ความคิดเห็น และสตรีมสดที่คุณสร้างขึ้น (“เนื้อหาของผู้ใช้”)   เรารวบรวมข้อมูลผ่านการสำรวจ ความท้าทาย และการแข่งขันที่คุณเข้าร่วม   นอกจากนี้ เรายังรวบรวมข้อมูลเกี่ยวกับการใช้แพลตฟอร์มของคุณ เช่น ลักษณะการมีส่วนร่วมกับแพลตฟอร์ม รวมถึงความถี่ในการใช้งานแพลตฟอร์มและวิธีการโต้ตอบกับเนื้อหาที่เราแสดงให้คุณเห็น โฆษณาที่ดู วิดีโอที่ดู และปัญหาที่พบ เนื้อหาที่ชอบ เนื้อหาที่บันทึกลงใน “รายการโปรด” คำที่ค้นหาและผู้ใช้ที่คุณติดตาม
                เราจะอนุมานความสนใจ เพศ และอายุของคุณเพื่อวัตถุประสงค์ในการปรับแต่งเนื้อหาให้เหมาะสมกับคุณยิ่งขึ้น   นอกจากนี้ เราจะอนุมานความสนใจของผู้ใช้ของเราเพื่อเพิ่มประสิทธิภาพในการโฆษณาทั่วทั้งแพลตฟอร์มของเราให้ดียิ่งขึ้น   หากคุณยินยอม เราจะใช้ข้อมูลนี้เพื่อวัตถุประสงค์ในการแสดงโฆษณาที่ปรับให้เหมาะกับแต่ละบุคคล
                เราตรวจสอบเนื้อหาของผู้ใช้เพื่อวัตถุประสงค์ในการตรวจสอบเนื้อหาให้เป็นไปตามแนวทางปฏิบัติของชุมชนของเรา ปฏิบัติตามภาระผูกพันทางกฎหมาย และป้องกันและตอบสนองต่อการละเมิด การฉ้อโกง กิจกรรมที่ผิดกฎหมาย และเนื้อหาที่อาจเป็นอันตรายบนแพลตฟอร์ม
                นอกจากนี้ เรายังประมวลผลข้อมูลเกี่ยวกับผู้ติดตามของคุณ ยอดการกดถูกใจ (Like) ที่คุณได้รับและ       การตอบสนองต่อเนื้อหาที่คุณอัปโหลด เพื่อวัตถุประสงค์ในการปรับแต่งฟีด (Feed) ของคุณ ส่งเสริมเนื้อหาของคุณให้กับผู้ใช้รายอื่น และสำรวจว่ามีโอกาสในการทำงานร่วมกันหรือไม่
            </p>
            <p className="title-3">ข้อมูลจากบุคคลที่สาม</p>
            <p>
                คุณอาจเลือกที่จะแบ่งปันข้อมูลบางอย่างกับเราจากบุคคลที่สาม หรือผ่านการใช้แพลตฟอร์มของคุณ และเราอาจเก็บรวบรวมข้อมูลของบุคคลที่สามดังกล่าวโดยอัตโนมัติ
            </p>
            <p className="title-3">พันธมิตรทางธุรกิจ</p>
            <p>
                หากคุณเลือกที่จะลงทะเบียนเพื่อใช้งานแพลตฟอร์มโดยใช้รายละเอียดบัญชีสื่อสังคมออนไลน์ของคุณ  ถือว่าคุณได้ให้เราหรืออนุญาตให้เครือข่ายสังคมของคุณให้ข้อมูลเกี่ยวกับชื่อผู้ใช้และโปรไฟล์สาธารณะของคุณแก่เรา   นอกจากนี้ เราจะแบ่งปันข้อมูลบางอย่างกับเครือข่ายสังคมที่เกี่ยวข้อง เช่น รหัสของแอปพลิเคชัน โทเค็นการเข้าถึง (Access Token) และที่อยู่เว็บ (URL) อ้างอิง
            </p>
            <p className="title-3">ผู้โฆษณาและเครือข่ายโฆษณา</p>
            <p>
                เราอาจโพสต์โฆษณาแบบกำหนดเองบนแพลตฟอร์มของเราเป็นครั้งคราวเพื่อให้บริการแพลตฟอร์มนี้          แก่คุณ โดยไม่มีค่าใช้จ่าย
                เราจะใช้ข้อมูลที่เรารวบรวมจากคุณและการโต้ตอบระหว่างคุณกับแพลตฟอร์มนี้และเว็บไซต์บุคคลที่สามอื่นๆ เพื่อนำเสนอโฆษณาที่เกี่ยวข้องมากขึ้นโดยการเพิ่มผลประโยชน์ของคุณให้สูงที่สุด
                เราใช้ข้อมูลข้างต้นเพื่อทำความเข้าใจเว็บไซต์ที่คุณเยี่ยมชม แอปพลิเคชันที่คุณดาวน์โหลด ประวัติการซื้อของคุณ เพื่อคาดการณ์ความสนใจในอนาคตของคุณ และประเมินว่าโฆษณาบนแพลตฟอร์มของเรา               มีประสิทธิผลเพียงใด
                เราใช้คุกกี้และเทคโนโลยีที่คล้ายกันในแอปพลิเคชันของเราเพื่อโฆษณาบนแพลตฟอร์มของเรา และรวบรวมข้อมูลข้างต้นโดยใช้ข้อมูลที่คล้ายคลึงกันที่ได้รับจากบุคคลที่สามที่ให้บริการเว็บไซต์ที่คุณเยี่ยมชม
            </p>
            <p className="title-3">ข้อมูลทางเทคนิคที่เรารวบรวมที่เกี่ยวข้องกับคุณ</p>
            <p>
                เรารวบรวมข้อมูลบางอย่างจากคุณเมื่อคุณใช้งานแพลตฟอร์ม รวมถึงเมื่อคุณใช้แอปพลิเคชันโดยไม่มีบัญชี   ข้อมูลดังกล่าวรวมถึงที่อยู่ IP, รหัสชั่วคราว (Instance ID) (ซึ่งช่วยให้เรากำหนดได้ว่าจะส่งข้อมูลไปยังอุปกรณ์ใด) ผู้ให้บริการมือถือ การตั้งค่าเขตเวลา ตัวระบุเพื่อวัตถุประสงค์ในการโฆษณา และเวอร์ชันของ           แอปพลิเคชันที่คุณกำลังใช้   นอกจากนี้ เราจะรวบรวมข้อมูลเกี่ยวกับอุปกรณ์ที่คุณใช้ในการเข้าถึงแพลตฟอร์ม เช่น รุ่นของอุปกรณ์ ระบบอุปกรณ์ ประเภทเครือข่าย รหัสของอุปกรณ์ ความละเอียดหน้าจอและระบบปฏิบัติการ การตั้งค่าเสียง และอุปกรณ์เสียงที่เชื่อมต่อ   เมื่อคุณล็อกอินเข้าสู่ระบบจากอุปกรณ์            หลายเครื่อง เราจะสามารถใช้ข้อมูลโปรไฟล์ของคุณเพื่อระบุกิจกรรมของคุณในอุปกรณ์ต่างๆ ได้
            </p>
            <p className="title-3">ข้อมูลตำแหน่ง</p>
            <p>
                ตามพระราชบัญญัติว่าด้วยการคุ้มครองและการใช้ข้อมูลตำแหน่ง บริษัทจะจัดการข้อมูลตำแหน่งของบุคคลดังต่อไปนี้   เราอาจรวบรวมข้อมูลตำแหน่งตามระบบกำหนดตำแหน่งบนโลก (GPS) โดยได้รับ              ความยินยอมจากคุณ เพื่อมอบเนื้อหาหรือโฆษณาที่ปรับแต่งสำหรับคุณโดยเฉพาะ   ข้อมูลตำแหน่งของคุณ จะช่วยให้คุณได้รับประสบการณ์ที่เหมาะสม   หากคุณไม่อนุญาตให้ส่งข้อมูลตำแหน่งในการตั้งค่า            เครื่องปลายทาง (เทอร์มินัล) จะไม่มีการส่งข้อมูลตำแหน่ง   อย่างไรก็ตาม บริษัทอาจประมาณตำแหน่งโดยประมาณของลูกค้าจากข้อมูลเครือข่าย เช่น ที่อยู่ IP แม้ว่าลูกค้าจะไม่อนุญาตให้ส่งข้อมูลตำแหน่งก็ตาม   เราจะไม่ให้ข้อมูลตำแหน่งส่วนบุคคลแก่บุคคลที่สามโดยไม่ได้รับความยินยอมล่วงหน้าจากผู้ใช้
            </p>
            <p className="title-3">ข้อมูลเกี่ยวกับเพื่อนของคุณ</p>
            <p>
                คุณสามารถเลือกว่าจะค้นหาผู้ใช้รายอื่นของแพลตฟอร์มโดยใช้ฟังก์ชัน “ค้นหาเพื่อน” ของเรา และเชิญ ผู้ติดต่อของคุณให้เข้าร่วมกับคุณบนแพลตฟอร์มหรือไม่   เราจะใช้ข้อมูลติดต่อที่คุณมีสำหรับบุคคลนั้น เช่น จากรายชื่อผู้ติดต่อในโทรศัพท์ของคุณหรือรายชื่อเพื่อนบน Facebook และให้ตัวเลือกแก่คุณในการส่งข้อความสั้น (SMS) อีเมล หรือข้อความของบุคคลที่สาม เพื่อเชิญให้บุคคลดังกล่าวดูโปรไฟล์ CELEBE ของคุณ
                เราจะใช้ข้อมูลส่วนบุคคลของคุณเพื่อค้นหารายการที่ตรงกันโดยอิงจากข้อมูลติดต่อและสมุดที่อยู่ของคุณ   อย่างไรก็ตาม ข้อมูลติดต่อและสมุดที่อยู่ของคุณเป็นข้อมูลส่วนตัวและจะไม่ถูกเปิดเผย   เราจะใช้ระบบ            ทางเทคนิคเพื่อค้นหารายการที่ตรงกันโดยไม่ต้องส่งข้อมูลของคุณหรือสมุดที่อยู่ของคุณมาที่เราในรูปแบบข้อความธรรมดา
                หากคุณต้องการค้นหาผู้ใช้รายอื่นผ่านผู้ติดต่อ SNS ของคุณ คุณสามารถอนุญาตให้เราเข้าถึงรายชื่อ           ผู้ติดต่อ Facebook ของคุณได้   จากนั้นเราจะรวบรวมข้อมูล Facebook สาธารณะของคุณ รวมทั้งชื่อและโปรไฟล์ของผู้ติดต่อ Facebook ของคุณ   เราจะจับคู่ข้อมูลเหล่านี้กับผู้ใช้ที่ลงทะเบียนและแสดงรายการ            ที่ตรงกันเพื่อให้คุณสามารถติดตามบุคคลดังกล่าวได้
            </p>
            <p className="title-3">การซื้อในแอปพลิเคชัน</p>
            <p>
                หากคุณทำการซื้อในแอปพลิเคชัน โปรดอ่านนโยบายสินค้าเสมือนจริงของเรา   การซื้อของคุณ           จะดำเนินการผ่านบัญชี Apple iTunes หรือ Google Play   เราจะไม่รวบรวมข้อมูลทางการเงินหรือการเรียกเก็บเงินจากคุณในส่วนที่เกี่ยวกับธุรกรรมดังกล่าว   นอกจากนี้ โปรดอ่านข้อกำหนดและประกาศของ App Store ที่เกี่ยวข้องเพื่อเรียนรู้เกี่ยวกับวิธีการใช้ข้อมูลของคุณ   เราจะเก็บบันทึกการซื้อของคุณ เวลาที่คุณ         ทำการซื้อ และจำนวนเงินที่ใช้จ่าย เพื่อให้เราสามารถให้เครดิตบัญชีของคุณได้อย่างถูกต้องในรูปของคะแนน
            </p>
            <p className="title-3">2. คุกกี้</p>
            <p>
                เรา ผู้ขาย และผู้ให้บริการของเรา รวบรวมข้อมูลโดยอัตโนมัติโดยใช้คุกกี้และเทคโนโลยีอื่นที่คล้ายคลึงกัน (เช่น เว็บบีคอน แฟลชคุกกี้ ฯลฯ) เพื่อวัดและวิเคราะห์หน้าเว็บที่คุณคลิกและวิธีที่คุณใช้แพลตฟอร์มนี้ ปรับปรุงประสบการณ์การบริการของเรา และแสดงโฆษณาที่ปรับแต่งให้เหมาะสมกับคุณ
                คุกกี้คือไฟล์ขนาดเล็กที่ช่วยให้แพลตฟอร์มนี้มีความสามารถเฉพาะและถูกติดตั้งไว้บนอุปกรณ์ของคุณ
                เว็บบีคอนคือรูปภาพขนาดเล็กมากหรือข้อมูลที่แทรกอยู่ในรูปภาพ ซึ่งบางครั้งเรียกว่า “พิกเซลแท็ก” หรือ “ไฟล์ GIF พื้นหลังโปร่งใส” และจดจำคุกกี้ เวลา และวันที่ในการดูหน้าเว็บ คำอธิบายของหน้าเว็บ             ที่ติดตั้งแท็กพิกเซล และข้อมูลที่คล้ายกันซึ่งรวบรวมจากคอมพิวเตอร์หรืออุปกรณ์ของคุณ
                เมื่อคุณใช้แพลตฟอร์มนี้ ถือว่าคุณได้ตกลงที่จะใช้คุกกี้
                นอกจากนี้เรายังอนุญาตให้พันธมิตรทางธุรกิจ เครือข่ายโฆษณา ผู้ให้บริการโฆษณาและผู้ให้บริการอื่น ๆ (รวมถึงผู้ให้บริการวิเคราะห์และผู้ให้บริการ) รวบรวมข้อมูลเกี่ยวกับกิจกรรมออนไลน์ของคุณผ่านคุกกี้
                บุคคลที่สามดังกล่าวอาจใช้ข้อมูลนี้เพื่อลงโฆษณาบนแพลตฟอร์มของเราและระบบออนไลน์เพื่อให้เหมาะกับความสนใจ ความชอบ และลักษณะเฉพาะของคุณ
                เราไม่รับผิดชอบต่อการประมวลผลข้อมูลส่วนบุคคลโดยบุคคลที่สามใด ๆ ข้างต้น และนโยบายนี้ไม่ได้กล่าวถึงการปฏิบัติในการประมวลผลข้อมูลโดยบุคคลที่สาม
                คุณสามารถปรับการตั้งค่าเบราว์เซอร์ของคุณเพื่อปฏิเสธหรือปิดใช้งานคุกกี้
                เนื่องจากเบราว์เซอร์แต่ละตัวมีความแตกต่างกัน โปรดดูคำแนะนำหรือคำอธิบายของเบราว์เซอร์ที่คุณใช้
                การปฏิเสธหรือปิดใช้งานคุกกี้บางประเภทอาจต้องมีการดำเนินการเพิ่มเติม
                ตัวอย่างเช่น เบราว์เซอร์และแอปพลิเคชันบนอุปกรณ์เคลื่อนที่มีความแตกต่างกัน ดังนั้นคุณอาจต้องดำเนินการที่แตกต่างกันเพื่อบล็อกคุกกี้ที่ใช้สำหรับโฆษณาที่ปรับแต่งขึ้นสำหรับเบราว์เซอร์ของคุณ หรือ            เพื่อปรับการตั้งค่าอุปกรณ์หรือการอนุญาตแอปพลิเคชันบนอุปกรณ์เคลื่อนที่
                นอกจากนี้ การเลือกการบล็อกของคุณจะมีผลเฉพาะเบราว์เซอร์หรืออุปกรณ์คุณใช้ในการบล็อกเท่านั้น ดังนั้นอาจจำเป็นต้องทำการบล็อกแยกต่างหากในแต่ละเบราว์เซอร์หรืออุปกรณ์
                หากคุณปฏิเสธ ปิดใช้งาน หรือลบคุกกี้ คุณอาจไม่สามารถคุณลักษณะบางอย่างของแพลตฟอร์มนี้ได้
            </p>
            <p className="title-3">3. วิธีการใช้ข้อมูลส่วนบุคคลของคุณ</p>
            <p>
                เราจะใช้ข้อมูลส่วนบุคคลของคุณที่เรารวบรวมไว้ตามมูลเหตุอันจะอ้างกฎหมายได้ที่อธิบายไว้ด้านล่างนี้
                ตามสัญญาที่ทำขึ้นกับคุณและเพื่อปฏิบัติตามสัญญาที่ทำขึ้นกับคุณ เราจะใช้ข้อมูลของคุณเพื่อ
                - 	จัดหาแพลตฟอร์มและบริการที่เกี่ยวข้อง
                - 	แจ้งให้คุณทราบเกี่ยวกับการเปลี่ยนแปลงบริการของเรา
                - 	ให้การสนับสนุนผู้ใช้แก่คุณ
                - 	บังคับใช้ข้อกำหนด เงื่อนไข และนโยบายของเรา
                - 	บริหารจัดการแพลตฟอร์ม รวมถึงการแก้ไขปัญหา
                - 	ปรับแต่งเนื้อหาที่คุณได้รับและจัดหาเนื้อหาที่ปรับแต่งให้เหมาะกับคุณ เพื่อประโยชน์ของคุณ
                - 	ช่วยให้คุณสามารถแบ่งปันเนื้อหาของผู้ใช้และโต้ตอบกับผู้ใช้รายอื่น
                - 	ช่วยให้คุณเข้าร่วมในโปรแกรมสินค้าเสมือนจริง และ
                - 	สื่อสารกับคุณ
                เพื่อปฏิบัติตามภาระผูกพันทางกฎหมายของเราและตามความจำเป็นในการดำเนินงานเพื่อสาธารณประโยชน์หรือเพื่อปกป้องผลประโยชน์ที่สำคัญของผู้ใช้ของเราและบุคคลอื่น เราจะใช้ข้อมูลของคุณเพื่อช่วยในการป้องกันและตอบสนองต่อการละเมิด การฉ้อโกง กิจกรรมที่ผิดกฎหมาย และเนื้อหาที่อาจเป็นอันตรายบนแพลตฟอร์ม
                เพื่อผลประโยชน์ที่ชอบด้วยกฎหมายของเราในการจัดหาแพลตฟอร์มที่มีประสิทธิภาพและเป็นพลวัต            เราอาจใช้ข้อมูลของคุณเพื่อ
                - 	รับรองความปลอดภัยของคุณ รวมถึงการตรวจสอบเนื้อหาของผู้ใช้ ข้อความ และคำอธิบาย                ชุดข้อมูล (metadata) ที่เกี่ยวข้องสำหรับการละเมิดหลักเกณฑ์ของชุมชนและข้อกำหนดในการให้บริการของเรา
                - 	ตรวจสอบให้แน่ใจว่าเนื้อหาถูกนำเสนอในลักษณะที่มีประสิทธิภาพสูงสุดสำหรับคุณและอุปกรณ์ของคุณ
                - 	ทำความเข้าใจว่าผู้คนใช้แพลตฟอร์มอย่างไร เพื่อให้เราสามารถปรับปรุง ส่งเสริม และพัฒนาแพลตฟอร์มให้ดียิ่งขึ้น
                - 	ส่งเสริมหัวข้อ แฮชแท็ก และแคมเปญยอดนิยมบนแพลตฟอร์ม
                - 	ทำการวิเคราะห์ข้อมูลและทดสอบแพลตฟอร์มเพื่อให้มั่นใจในความเสถียรและความปลอดภัย
                - 	จัดทำโฆษณาที่ไม่ได้ปรับให้เหมาะกับแต่ละบุคคล ซึ่งทำให้คุณสามารถใช้บริการหลายอย่างของเราได้โดยไม่มีค่าใช้จ่าย
                - 	อนุมานความสนใจของคุณเพื่อปรับการเสนอโฆษณาให้เหมาะสม ซึ่งเมื่อคุณยินยอมให้มีการโฆษณาที่ปรับให้เหมาะกับแต่ละบุคคล อาจขึ้นอยู่กับข้อมูลที่พันธมิตรโฆษณาของเราให้ไว้กับเรา
                - 	วัดประสิทธิภาพของโฆษณาที่คุณเห็นบนแพลตฟอร์มของเรา
                - 	ให้ข้อมูลกับอัลกอริทึมของเรา เพื่อให้เราสามารถนำเสนอเนื้อหาที่เกี่ยวข้องกับคุณมากที่สุด และเพื่อป้องกันอาชญากรรมและการใช้แพลตฟอร์มในทางที่ผิด
                - 	ทำการสำรวจที่เกี่ยวข้องกับบริการ ผลิตภัณฑ์ และคุณลักษณะของเรา
                - 	อนุญาตให้คุณใช้คุณสมบัติเชิงโต้ตอบของแพลตฟอร์ม และ
                - 	ช่วยให้คุณสามารถเข้าสังคมบนแพลตฟอร์ม   ตัวอย่างเช่น เราอาจอนุญาตให้ผู้ใช้รายอื่นระบุตัวคุณผ่านฟังก์ชัน “ค้นหาเพื่อน” หรือผ่านผู้ติดต่อทางโทรศัพท์ หรือเชื่อมต่อคุณกับผู้ใช้รายอื่นโดยการติดตามว่าคุณแบ่งปันการเชื่อมโยง (ลิงก์) กับใครบ้าง
                เมื่อเราประมวลผลข้อมูลของคุณเพื่อบรรลุผลประโยชน์โดยชอบด้วยกฎหมายของเรา เราจะทำ           การทดสอบความสมดุลเพื่อตรวจสอบว่าการใช้ข้อมูลส่วนบุคคลนั้นจำเป็นในการบรรลุวัตถุประสงค์ทางธุรกิจของเราอย่างแท้จริงหรือไม่   โดยในการทดสอบความสมดุลนี้ เราคำนึงถึงสิทธิความเป็นส่วนตัวของผู้ใช้ของเรา และจัดให้มีการป้องกันที่เหมาะสมเพื่อปกป้องข้อมูลส่วนบุคคลของผู้ใช้
            </p>
            <p className="title-3">4. วิธีการแบ่งปันข้อมูลส่วนบุคคลของคุณ</p>
            <p>
                เราจะแบ่งปันข้อมูลของคุณกับบุคคลที่สามที่เลือก ดังต่อไปนี้
            </p>
            <p className="title-3">พันธมิตรทางธุรกิจ</p>
            <p>
                หากคุณเลือกที่จะลงทะเบียนเพื่อใช้งานแพลตฟอร์มโดยใช้รายละเอียดบัญชีสื่อสังคมออนไลน์ของคุณ  ถือว่าคุณได้ให้เราหรืออนุญาตให้เครือข่ายสังคมของคุณให้ข้อมูลเกี่ยวกับชื่อผู้ใช้และโปรไฟล์สาธารณะของคุณแก่เรา   นอกจากนี้ เราจะแบ่งปันข้อมูลบางอย่างกับเครือข่ายสังคมที่เกี่ยวข้อง เช่น รหัสของแอปพลิเคชัน โทเค็นการเข้าถึง (Access Token) และที่อยู่เว็บ (URL) อ้างอิง
                เมื่อคุณเลือกแบ่งปันเนื้อหาบนแพลตฟอร์มสื่อสังคมออนไลน์ วิดีโอ ชื่อผู้ใช้ และข้อความใด ๆ ที่เกี่ยวข้องกับโพสต์จะถูกแบ่งปันบนแพลตฟอร์มนั้น หรือในกรณีของการแบ่งปันข้อมูลผ่านแพลตฟอร์มการส่งข้อความโต้ตอบแบบทันที การเชื่อมโยงไปยังเนื้อหานั้นจะถูกแชร์
            </p>
            <p className="title-3">ผู้ให้บริการด้านการชำระเงิน</p>
            <p>
                หากคุณเลือกซื้อสินค้าเสมือนจริง เราจะแบ่งปันข้อมูลกับผู้ให้บริการด้านการชำระเงินที่เกี่ยวข้อง               เพื่ออำนวยความสะดวกในการทำธุรกรรม   เราจะแบ่งปันรหัสธุรกรรมเพื่อให้เราสามารถระบุตัวคุณและ             ให้เครดิตบัญชีของคุณอย่างถูกต้องในรูปของคะแนนเมื่อคุณชำระเงินแล้ว
            </p>
            <p className="title-3">ผู้ให้บริการ</p>
            <p>
                เราให้ข้อมูลและเนื้อหาแก่ผู้ให้บริการที่สนับสนุนธุรกิจของเรา เช่น ผู้ให้บริการระบบคลาวด์ และ            ผู้ให้บริการตรวจสอบเนื้อหา เพื่อให้แน่ใจว่าแพลตฟอร์มเป็นสถานที่ที่ปลอดภัยและน่าเพลิดเพลิน
            </p>
            <p className="title-3">ผู้ให้บริการด้านการวิเคราะห์และการวัดผล</p>
            <p>
                เราใช้ผู้ให้บริการวิเคราะห์และวัดผลเพื่อช่วยในการปรับปรุงแพลตฟอร์ม รวมถึงการวัดเนื้อหาและติดตามกิจกรรมของคุณบนแพลตฟอร์มของเราบนอุปกรณ์ของคุณทุกเครื่อง
            </p>
            <p className="title-3">ผู้โฆษณา</p>
            <p>
                เราแบ่งปันข้อมูลรวมของผู้ใช้กับผู้โฆษณาเท่านั้น   ข้อมูลรวมคือข้อมูลที่จัดกลุ่มเข้าด้วยกันและ                    ไม่เฉพาะเจาะจงสำหรับผู้ใช้แต่ละราย ซึ่งมีวัตถุประสงค์เพื่อให้สามารถวัดประสิทธิภาพของแคมเปญโฆษณาโดยการแสดงจำนวนของผู้ใช้แพลต์ฟอร์มที่ดูหรือคลิกโฆษณา
            </p>
            <p className="title-3">กลุ่มบริษัทของเรา</p>
            <p>
                เราอาจแบ่งปันข้อมูลของคุณกับสมาชิกรายอื่น บริษัทย่อย หรือบริษัทในเครือในกลุ่มบริษัทของเรา              ในกรณีที่จำเป็นต้องจัดหาแพลตฟอร์มตามข้อกำหนดในการให้บริการ
                เราจะแบ่งปันข้อมูลเพื่อปรับปรุงและเพิ่มประสิทธิภาพแพลตฟอร์ม รวมถึงเพื่อป้องกันการใช้งาน              ที่ผิดกฎหมายและเพื่อให้การสนับสนุนแก่ผู้ใช้
            </p>
            <p className="title-3">การบังคับใช้กฎหมาย/ภาระผูกพันทางกฎหมาย</p>
            <p>
                เราอาจแบ่งปันข้อมูลของคุณกับหน่วยงานบังคับใช้กฎหมาย หน่วยงานของรัฐ หรือบุคคลที่สามอื่นๆ      หากเราพิจารณาว่ากฎหมายกำหนดให้เราทำเช่นนั้น หรือหากการใช้ข้อมูลดังกล่าวมีความจำเป็นตามสมควรเพื่อ
                - 	ปฏิบัติตามกระบวนการทางกฎหมายหรือคำร้องขอ
                - 	บังคับใช้ข้อกำหนดในการให้บริการของเรา และข้อตกลง นโยบาย และมาตรฐานอื่น ๆ รวมถึง            การสอบสวนการละเมิดที่อาจเกิดขึ้น
                - 	ตรวจจับ ป้องกัน หรือจัดการกับการละเมิด การฉ้อโกง กิจกรรมที่ผิดกฎหมาย หรือปัญหาด้าน   ความปลอดภัย หรือปัญหาทางเทคนิค หรือ
                - 	ปกป้องสิทธิ์ ทรัพย์สิน หรือความปลอดภัยของเรา ผู้ใช้ของเรา บุคคลที่สาม หรือสาธารณะ ตามที่กฎหมายกำหนดหรืออนุญาต (รวมถึงการแลกเปลี่ยนข้อมูลกับบริษัทและองค์กรอื่น ๆ เพื่อวัตถุประสงค์ในการป้องกันการฉ้อโกงและการลดความเสี่ยงด้านเครดิต)
            </p>
            <p className="title-3">โปรไฟล์สาธารณะ</p>
            <p>
                หากโปรไฟล์ของคุณเป็นโปรไฟล์สาธารณะ เนื้อหาของคุณจะปรากฏแก่ทุกคนบนแพลตฟอร์ม และอาจมีการเข้าถึงหรือแบ่งปันเนื้อหาดังกล่าวโดยเพื่อนและผู้ติดตามของคุณ ตลอดจนบุคคลที่สาม เช่น เสิร์ชเอ็นจิ้น        ผู้รวบรวมเนื้อหา และเว็บไซต์ข่าว   คุณสามารถเปลี่ยนผู้ที่สามารถดูวิดีโอได้ทุกครั้งที่คุณอัปโหลดวิดีโอ   นอกจากนี้ คุณยังสามารถเปลี่ยนโปรไฟล์ของคุณเป็นโปรไฟล์ส่วนตัวได้โดยเปลี่ยนการตั้งค่าของคุณเป็น “บัญชีส่วนตัว” ในการตั้งค่า “ความเป็นส่วนตัวและความปลอดภัย”
            </p>
            <p className="title-3">การขายหรือการควบรวมกิจการ</p>
            <p>
                เราจะเปิดเผยข้อมูลของคุณแก่บุคคลที่สาม
                ในกรณีที่เราขายหรือซื้อธุรกิจหรือสินทรัพย์ใด ๆ (เช่น เป็นผลมาจากการชำระบัญชี การล้มละลาย หรืออย่างอื่น)   ในการทำธุรกรรมดังกล่าว เราจะเปิดเผยข้อมูลของคุณแก่ผู้จะซื้อหรือผู้จะขายธุรกิจหรือสินทรัพย์ดังกล่าว หรือ
                หากเราขาย ซื้อ ควบรวมกิจการ ถูกซื้อกิจการ โดยหรือเป็นพันธมิตรกับบริษัทหรือธุรกิจอื่น หรือขายสินทรัพย์ของเราทั้งหมดหรือบางส่วน   ในธุรกรรมดังกล่าว ข้อมูลผู้ใช้อาจรวมอยู่ในสินทรัพย์ที่โอน
            </p>
            <p className="title-3">5. สิทธิของคุณ</p>
            <p>
                คุณสามารถเข้าถึงและแก้ไขข้อมูลโปรไฟล์ส่วนใหญ่ของคุณได้เมื่อคุณสมัครรับข้อมูลจาก CELEBE              คุณสามารถลบเนื้อหาผู้ใช้ที่คุณอัปโหลด   นอกจากนี้ เรายังมีเครื่องมือการตั้งค่าจำนวนหนึ่งที่คุณสามารถปรับเปลี่ยนได้ และคุณสามารถควบคุมได้ว่าใครสามารถดูวิดีโอของคุณ ใครสามารถส่งข้อความถึงคุณ และ       ใครสามารถแสดงความคิดเห็นบนวิดีโอของคุณได้  หากต้องการ คุณสามารถลบบัญชีทั้งหมดของคุณจาก           การตั้งค่า   หากคุณมีคำถามใด ๆ เกี่ยวกับวิธีการใช้เครื่องมือหรือต้องการทราบเกี่ยวกับสิทธิของคุณ           ในประเทศที่คุณพำนัก โปรดติดต่อเราที่ info@celebe.io
            </p>
            <p className="title-3">6. ความปลอดภัยของข้อมูลส่วนบุคคลของคุณ</p>
            <p>
                เราดำเนินการตามขั้นตอนต่าง ๆ เพื่อให้แน่ใจว่าข้อมูลของคุณได้รับการปฏิบัติอย่างปลอดภัยและสอดคล้องกับนโยบายนี้   แต่น่าเสยดายที่การส่งข้อมูลผ่านอินเทอร์เน็ตไม่ปลอดภัยอย่างสมบูรณ์   แม้ว่า            เราจะพยายามอย่างเต็มที่ในการปกป้องข้อมูลส่วนบุคคลของคุณ ตัวอย่างเช่น โดยการเข้ารหัส แต่เรา         ไม่สามารถรับประกันความปลอดภัยของข้อมูลของคุณที่ส่งผ่านแพลตฟอร์ม ซึ่งหมายความว่าคุณจะต้อง          แบกรับความเสี่ยงในการส่งข้อมูลด้วยตนเอง
                เรามีมาตรการทางเทคนิคและมาตรการเชิงบริหารจัดการที่เหมาะสมเพื่อให้แน่ใจว่ามีระดับ               ความปลอดภัยที่เหมาะสมกับความเสี่ยงที่อาจเกิดกับคุณและผู้ใช้รายอื่น   เราคงไว้ซึ่งมาตรการทางเทคนิคและมาตรการเชิงบริการจัดการเหล่านี้ และจะแก้ไขมาตรการเหล่านี้เป็นครั้งคราวเพื่อปรับปรุงความปลอดภัยโดยรวมของระบบของเรา
                เราจะใส่การเชื่อมโยงไปยังและจากเว็บไซต์ของเครือข่ายพันธมิตร ผู้โฆษณา และบริษัทในเครือ            เป็นครั้งคราว   หากคุณคลิกการเชื่อมโยงไปยังเว็บไซต์ใด ๆ เหล่านี้ โปรดทราบว่าเว็บไซต์เหล่านี้มีนโยบายความเป็นส่วนตัวของตนเอง และเราจะไม่รับผิดชอบหรือมีความรับผิดใด ๆ สำหรับนโยบายเหล่านี้ ดังนั้น โปรดตรวจสอบนโยบายเหล่านี้ก่อนที่คุณจะส่งข้อมูลใด ๆ ไปยังเว็บไซต์เหล่านี้
            </p>
            <p className="title-3">7. ระยะเวลาการเก็บข้อมูลส่วนบุคคลคุณ</p>
            <p>
                เราเก็บรักษาข้อมูลของคุณไว้ตราบเท่าที่จำเป็นในการให้บริการแก่คุณ เพื่อให้เราสามารถปฏิบัติตามภาระผูกพันตามสัญญาและใช้สิทธิของเราในส่วนที่เกี่ยวข้องกับข้อมูลที่เกี่ยวข้อง   ในกรณีที่เราไม่จำเป็นต้องใช้ข้อมูลของคุณเพื่อให้บริการแก่คุณ เราจะเก็บรักษาข้อมูลของคุณไว้ตราบเท่าที่เรามีวัตถุประสงค์ทางธุรกิจ          ที่ถูกต้องตามกฎหมายในการเก็บข้อมูลดังกล่าว
            </p>
            <p className="title-3">8. ข้อมูลส่วนบุคคลของเด็ก</p>
            <p>
                CELEBE ไม่ได้มุ่งเป้าไปที่เด็กอายุต่ำกว่า 13 ปี   หากคุณเชื่อว่าเรามีข้อมูลส่วนบุคคลเกี่ยวกับหรือรวบรวมจากเด็กที่อายุต่ำกว่าเกณฑ์ โปรดติดต่อเราที่ info@celebe.io
            </p>
            <p className="title-3">9. ข้อร้องเรียนของผู้ใช้</p>
            <p>
                หากคุณต้องการร้องเรียนเกี่ยวกับวิธีที่เราประมวลผลข้อมูลส่วนบุคคลของคุณ โปรดติดต่อเราก่อนที่ info@celebe.io และเราจะพยายามเร่งดำเนินการตามคำขอของคุณ   สิ่งที่กล่าวมานี้จะไม่จำกัดสิทธิของคุณในการยื่นคำร้องต่อหน่วยงานกำกับดูแลความเป็นส่วนตัวที่เกี่ยวข้อง
            </p>
            <p className="title-3">10. การเปลี่ยนแปลง</p>
            <p>
                เราจะแจ้งให้ผู้ใช้ทุกคนทราบถึงการเปลี่ยนแปลงที่สำคัญในนโยบายนี้ผ่านทางประกาศบนแพลตฟอร์มของเราหรือโดยวิธีการอื่น   เราจะปรับปรุงวันที่ “ปรับปรุงล่าสุด” ที่ด้านบนของนโยบายนี้ ซึ่งสะท้อนถึงวันที่การเปลี่ยนแปบงดังกล่าวมีผลบังคับใช้
                เรามุ่งมั่นที่จะปกป้องและเคารพความเป็นส่วนตัวของคุณ   นโยบายความเป็นส่วนตัวนี้อธิบาย              การประมวลผลข้อมูลส่วนบุคคลที่เรารวบรวมจากคุณหรือที่คุณให้ไว้กับเรา   คุณไม่ควรใช้แพลตฟอร์มนี้           หากคุณไม่เห็นด้วยกับนโยบายนี้
            </p>
            <p className="title-3">11. ข้อมูลการติดต่อ</p>
            <p>
                หากคุณมีคำถามใด ๆ เกี่ยวกับวิธีที่เราใช้ข้อมูลส่วนบุคคลของคุณ โปรดติดต่อเราที่ info@celebe.io
            </p>
            <p className="title-3">ข้อกำหนดและเงื่อนไขเพิ่มเติมที่ใช้บังคับกับเขตอำนาจศาล</p>
            <p>
                ในกรณีที่มีข้อขัดแย้งระหว่างข้อกำหนดเพิ่มเติมเกี่ยวกับเขตอำนาจศาลเขตใดเขตหนึ่ง (ซึ่งต่อไปในเอกสารนี้เรียกว่า “ข้อกำหนดเพิ่มเติม”) และเนื้อหาหลักของนโยบายนี้ในเขตอำนาจศาลที่คุณเข้าถึงบริการนี้หรือใช้บริการนี้ ข้อกำหนดและเงื่อนไขเพิ่มเติมในเขตอำนาจศาลนั้นจะมีผลบังคับใช้ก่อน
            </p>
            <p className="title-3">สาธารณรัฐเกาหลี</p>
            <p>
                หากคุณกำลังใช้บริการของเราในสาธารรัฐเกาหลี ข้อกำหนดและเงื่อนไขเพิ่มเติมต่อไปนี้จะมีผลบังคับใช้ ในกรณีที่มีข้อขัดแย้งระหว่างข้อกำหนดและเงื่อนไขเพิ่มเติมและส่วนเนื้อหาของนโยบายนี้ ข้อกำหนดและเงื่อนไขเพิ่มเติมจะมีผลเหนือกว่า
            </p>
            <p className="title-3">วิธีที่คุณแบ่งปันข้อมูลส่วนบุคคลของคุณ</p>
            <p>
                นอกเหนือจากเนื้อหาของนโยบายนี้ หากเราใช้ข้อมูล เช่น เนื้อหาที่ผู้ใช้สร้างขึ้นและเนื้อหาวิดีโอที่คุณโพสต์บนแพลตฟอร์มของเราเพื่อวัตถุประสงค์ในการส่งเสริมการขายตามที่คุณเลือก ซึ่งเป็นส่วนหนึ่งของแคมเปญโฆษณาและการตลาดของเรา ข้อมูลส่วนบุคคลของคุณอาจถูกเปิดเผยต่อผู้รับข้อมูล
            </p>
            <p className="title-3">การเก็บรักษาข้อมูล</p>
            <p>
                เราจะทำลายข้อมูลส่วนบุคคลใด ๆ ที่ได้รับเพื่อวัตถุประสงค์ในการรวบรวมตามที่คุณได้ตกลงไว้ หรือระยะเวลาการใช้งานตามที่คุณได้ตกลงไว้ หรือข้อมูลที่หมดอายุตามที่ระบุไว้ในนโยบายนี้   อย่างไรก็ตาม            เราจะยังคงเก็บรักษาข้อมูลส่วนบุคคลของคุณตามระยะเวลาที่กฎหมายกำหนด ซึ่งรวมถึงแต่ไม่จำกัดเพียง
                - 	พระราชบัญญัติคุ้มครองผู้บริโภคในการพาณิชย์อิเล็กทรอนิกส์ ฯลฯ
                - 	บันทึกการยกเลิกคำสั่งซื้อ บันทึกการชำระเงินสำหรับสินค้าที่ซื้อ และบันทึกการจัดส่งผลิตภัณฑ์/บริการของเรา: 5 ปี
                - 	บันทึกการจัดการข้อร้องเรียนหรือข้อพิพาทของผู้บริโภค: 3 ปี
                - 	บันทึกการโฆษณาและการตลาด: 6 เดือน
                - พระราชบัญญัติความลับด้านการสื่อสาร
                -	ข้อมูลการเยี่ยมชมเว็บไซต์ของเรา: 3 เดือน
            </p>
            <p className="title-3">การทำลายข้อมูลส่วนบุคคล</p>
            <p>
                เราจะทำลายข้อมูลส่วนบุคคลของคุณในลักษณะที่แผนกที่เกี่ยวข้องไม่สามารถกู้คืนได้
            </p>
            <p className="title-3">สิทธิของคุณ</p>
            <p className="title-3">สิทธิในข้อมูล</p>
            <p>
                คุณมีสิทธิที่จะร้องขอเกี่ยวกับการเข้าถึงข้อมูลส่วนบุคคลของคุณ การแก้ไข การลบ และการระงับ              การประมวลผลข้อมูลที่ไม่ถูกต้อง
                คุณสามารถใช้สิทธิของคุณได้โดยการติดต่อที่ info@celebe.io
            </p>
            <p className="title-3">ความปลอดภัยของข้อมูล</p>
            <p>
                เรามุ่งมั่นที่จะปกป้องผู้ขายและผู้ใช้จากการเข้าถึงหรือจากการเปลี่ยนแปลงโดยไม่ได้รับอนุญาต              การเปิดเผยโดยไม่ได้รับอนุญาต หรือการทำลายข้อมูลที่เรามี   ด้วยเหตุนี้ เราจึงใช้มาตรการทางเทคนิค มาตรการด้านการบริหารจัดการ และมาตรการป้องกันทางกายภาพ รวมถึงการจัดทำแผนการจัดการภายในสำหรับการปกป้องข้อมูล การอนุญาตการเข้าถึงเฉพาะบุคลากรที่จำเป็น และการควบคุมการเข้าถึงสิ่งอำนวยความสะดวกที่ใช้ในการประมวลผลข้อมูลส่วนบุคคล
            </p>
            <p className="title-3">การเปลี่ยนแปลงนโยบาย</p>
            <p>
                หากเราทำการเปลี่ยนแปลงใด ๆ กับนโยบายนี้ เราจะแจ้งให้คุณทราบถึงการเปลี่ยนแปลงดังกล่าวบนเว็บไซต์ของเราหรือในหน้าบริการของเรา อย่างน้อย 7 วันก่อนวันที่นโยบายฉบับแก้ไขมีผลบังคับใช้ (ล่วงหน้า 30 วัน หากการแก้ไขเพิ่มเติมดังกล่าวมีผลกระทบอย่างมีนัยสำคัญต่อสิทธิหรือภาระผูกพันของคุณภายใต้นโยบายนี้)
            </p>
            <p className="title-3">ข้อมูลส่วนบุคคลของเด็ก</p>
            <p>
                CELEBE ไม่ได้มีไว้สำหรับเด็กอายุต่ำกว่า 12 ปี
            </p>
            <p className="title-3">การฝาก และ/หรือ การโอนข้อมูลส่วนบุคคล</p>
            <p>
                ในบางกรณี เราได้รับความยินยอมจากคุณหรือแจ้งให้คุณไว้วางใจเราในการจัดการข้อมูลของคุณ               โดยบริษัทในเครือ ผู้ให้บริการระบบคลาวด์ ผู้ให้บริการด้านเทคโนโลยีสารสนเทศ และศูนย์ข้อมูล และผู้ดูแลผลประโยชน์ของเราบางรายที่ตั้งอยู่ต่างประเทศ (คลิกเพื่อดูรายชื่อ)   ฝ่ายที่ได้รับข้อมูลและประมวลผลข้อมูลของคุณมุ่งมั่นที่จะดำเนินการตามบรรดาขั้นตอนทางกายภาพและขั้นตอนทางเทคนิคที่เป็นไปได้ เพื่อเข้าถึง จัดเก็บ และปกป้องความเป็นส่วนตัวของคุณตามระเบียบข้อบังคับระดับประเทศและระดับสากล   หาก             การถ่ายโอนข้อมูลส่วนบุคคลไม่ใช่สิ่งจำเป็นในการให้บริการนี้แก่คุณ คุณสามารถติดต่อเราได้ที่ info@celebe.io เพื่อปฏิเสธการถ่ายโอนข้อมูลส่วนบุคคล
            </p>
            <p style={{ margin: '20px 0' }}>
                สอบถามข้อมูลเพิ่มเติมได้ที่ info@celebe.io
            </p>
        </div>
    );
}