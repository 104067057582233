import React from "react";

export default function EnPolicy231016({ theme }) {
  return (
    <div className={`policy ${theme === "dark" ? "dark" : ""}`}>
      <p className="title-1">Privacy Policy</p>
      <p>Last updated : 16/10/2023</p>
      <p>Welcome to CELEBE (the &ldquo;Platform&rdquo;).</p>
      <p>
        The Platform is provided and controlled by CELEBE KOREA CO., LTD
        (&ldquo;CELEBE&rdquo;, &ldquo;we&rdquo; or &ldquo;us&rdquo;).
      </p>
      <p>
        We are committed to protecting and respecting your privacy. This Privacy
        Statement (hereinafter referred to as <strong>"this policy"</strong>)
        describes our processing of personal information that we collect from
        you or that you provide us with. If you do not agree with this policy,
        you should not use this platform.
      </p>
      <p>
        If you have any questions about how we process your personal
        information, please contact us at{" "}
        <a href="mailto:info@celebe.io">info@celebe.io</a>
      </p>
      <p className="title-2">
        <strong>SUMMARY</strong>
      </p>
      <p>What information do we collect about you?</p>
      <p>
        We collect and process information you give us when you create an
        account and use the Platform. This includes technical and behavioural
        information about your use of the Platform. We also collect information
        about you if you download the app and use the Platform without creating
        an account. For more information.
      </p>
      <p>How will we use the information about you?</p>
      <p>
        We use your information to provide the Platform to you and to improve
        and administer it. In order to provide an effective and dynamic
        Platform, and where we have determined it is in our legitimate
        interests, we use your information to improve and develop the Platform,
        prevent crime and ensure users&rsquo; safety. Where we have your
        consent, we will also use your personal data to serve you targeted
        advertising and promote the Platform.
      </p>
      <p>Who do we share your information with?</p>
      <p>
        We share your data with third party service providers who help us to
        deliver the Platform including cloud storage providers. We also share
        your information with business partners, other companies in the same
        group as CELEBE (including CELEBE KOREA CO., LTD in the Republic of
        Korea which provides certain services for us in connection with the
        Platform), content moderation services, measurement providers,
        advertisers and analytics providers. We may share your information with
        law enforcement agencies, public authorities or with other third parties
        only where we are legally required to do so or if such use is reasonably
        necessary (for instance, to ensure your or someone else&rsquo;s safety).
      </p>
      <p>How long do we keep hold of your information?</p>
      <p>
        We retain your information for as long as it is necessary to provide you
        with the service so that we can fulfil our contractual obligations and
        exercise our rights in relation to the information involved. Where we do
        not need your information in order to provide the service to you, we
        retain it only as long as we have a legitimate business purpose in
        keeping such data or where we are subject to a legal obligation to
        retain the data. We will also retain your data if necessary for legal
        claims.
      </p>
      <p>How will we notify you of any changes to this Privacy Policy?</p>
      <p>
        We will notify all users of any material changes to this policy through
        a notice on our Platform or by other means. We update the &ldquo;Last
        Updated&rdquo; date at the top of this policy, which reflects the
        effective date of the policy. By accessing or using the Platform, you
        acknowledge that you have read this policy and that you understand your
        rights in relation to your personal data and how we will collect, use
        and process it.
      </p>
      <p>1.The types of personal data we use</p>
      <p>We collect and use the following information about you:</p>
      <p>&nbsp;</p>
      <p>
        <strong>Your Profile Information</strong>
      </p>
      <p>
        You give us information when you register on the Platform, including
        your username, date of birth, email address, and information you
        disclose in your user profile and your photograph or profile video.
      </p>
      <p>User Content and Behavioural Information</p>
      <p>
        We process the content you generate and view on the Platform, including
        preferences you set (such as choice of language), photographs and videos
        you upload, comments and live-streams you make (&ldquo;User
        Content&rdquo;). We collect information through surveys, challenges and
        competitions in which you participate. We also collect information
        regarding your use of the Platform, e.g. how you engage with the
        Platform, including how often you use the Platform and how you interact
        with content we show you, the ads you view, videos you watch and
        problems encountered, the content you like, the content you save to
        &ldquo;Favourites&rdquo;, the words you search and the users you follow.
      </p>
      <p>
        We infer your interests, gender and age for the purpose of personalising
        content. We also infer the interests of our users to better optimise
        advertising across our Platform. If you have consented, we will use this
        information for the purpose of serving personalised advertising.
      </p>
      <p>
        We review User Content for content moderation purposes to enforce our
        community guidelines, comply with legal obligations, and prevent and
        respond to abuse, fraud, illegal activity and other potentially harmful
        content on the Platform.
      </p>
      <p>
        We also process information about your followers, the likes you receive
        and responses to content you upload, for the purposes of personalising
        your Feed, promoting your content to other users and exploring whether
        your profile presents opportunities for collaboration.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>Information from Third Parties</strong>
      </p>
      <p>
        You may choose to share certain data with us from third parties or,
        through your use of the Platform, we may collect such third party data
        automatically.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Business Partners</strong>
      </p>
      <p>
        If you choose to register to use the Platform using your social media
        account details, you will provide us or allow your social network to
        provide us with your username and public profile. We will likewise share
        certain information with the relevant social network such as your app
        ID, access token and the referring URL.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Advertisers and Advertising Networks</strong>
      </p>
      <p>
        We may post customized advertisements on our platform from time to time
        to provide you with this platform free of charge.
      </p>
      <p>
        We use the information we collect from you and the interaction between
        you and this platform and other third-party sites to provide more
        relevant advertising by maximizing your interests.
      </p>
      <p>
        We use the above information to understand the websites you visit, the
        apps you downloaded, your purchase history, to predict your future
        interests and evaluate how effective advertising is on our platform.
      </p>
      <p>
        We use cookies and similar technologies on our app to advertise on our
        platform and collect the above information using similar information
        received from third parties operating the site you visit
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>Technical Information We Collect About You</strong>
      </p>
      <p>
        We collect certain information from you when you use the Platform
        including when you are using the app without an account. Such
        information includes your IP address, instance IDs (which allow us to
        determine which devices to deliver messages to), mobile carrier, time
        zone settings, identifier for advertising purposes and the version of
        the app you are using. We will also collect information regarding the
        device you are using to access the Platform such as the model of your
        device, the device system, network type, device ID, your screen
        resolution and operating system, audio settings and connected audio
        devices. Where you log-in from multiple devices, we will be able to use
        your profile information to identify your activity across devices.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>Location Information</strong>
      </p>
      <p>
        In accordance with the Act on the Protection and Use of Location
        Information, the Company handles personal location information as
        follows. We may collect GPS-based location information with your consent
        in order to provide you with customized content or advertisements that
        are optimized for you. Your location information collected will provide
        you with an appropriate celeb experience. If you do not allow the
        transmission of location information in the terminal settings, location
        information is not transmitted. However, the company may estimate the
        approximate location of the customer from network information such as IP
        address even if the customer does not permit the transmission of
        location information. We do not provide personal location information to
        third parties without the user's prior consent.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>Information About Your Friends</strong>
      </p>
      <p>
        You can choose whether to find other users of the Platform using our
        &ldquo;Find Friends&rdquo; function and to invite your contacts to join
        you on the Platform. We will use the contact information you have for
        that person, such as from within your telephone's contact list or
        Facebook friends list, and give you the option to send them either an
        SMS, email or third party message inviting them to view your CELEBE
        profile.
      </p>
      <p>
        We use your personal data to find matches based on your contact
        information and your address book. However, your contact information and
        your address book is private and will not be disclosed. We use a
        technical system to find matches without sending your own information or
        your address book to us in plain text.
      </p>
      <p>
        If you want to find other users through your SNS contacts, you can grant
        us access to your Facebook contact list. We will then collect your
        public Facebook information as well as names and profiles of your
        Facebook contacts. We will match this to registered users and show you
        any matches so you can follow them.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>In-app purchases</strong>
      </p>
      <p>
        If you make in-app purchases, please review our Virtual Items Policy.
        Your purchase will be made via your Apple iTunes or Google Play account.
        We do not collect any financial or billing information from you in
        relation to such a transaction. Please review the relevant app store's
        terms and notices to learn about how your data is used. We keep a record
        of the purchases you make, the time at which you make those purchases
        and the amount spent so that we can credit your account with the correct
        value in points.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>Cookies</strong>
      </p>
      <p>
        We, our vendors and service providers, automatically collect information
        using cookies and other similar technologies (e.g., web beacons, flash
        cookies, etc.) to measure and analyze the web pages you click and how
        you use this platform, improve our service experience, and provide
        customized advertising to you.
      </p>
      <p>
        Cookies are small files that allow this platform to provide specific
        capabilities and are installed on your device.
      </p>
      <p>
        Web beacons are very small images or data inserted within an image,
        sometimes referred to as \\\"pixel tags\\\" or \\\"clear GIFs,\\\" and
        recognize cookies, time and date of viewing pages, descriptions of pages
        with pixel tags installed, and similar information collected from your
        computer or device.
      </p>
      <p>By using this platform, you agree to use cookies.</p>
      <p>
        We also allow our business partners, advertising networks, and other
        advertising vendors and service providers (including analytical vendors
        and service providers) to collect information about your online
        activities through cookies.
      </p>
      <p>
        Such third parties may use this information to place advertisements on
        our platform and online to suit your interests, preferences and
        characteristics.
      </p>
      <p>
        We are not responsible for the processing of personal information by any
        of the above third parties, and this policy does not address the
        practice of processing information by any third parties.
      </p>
      <p>You can adjust your browser settings to reject or disable cookies.</p>
      <p>
        Because each browser is different, please refer to the instructions or
        descriptions provided by your browser.
      </p>
      <p>
        Rejecting or disabling certain types of cookies may require additional
        action.
      </p>
      <p>
        For example, there is a difference in how browsers and mobile apps
        function, so you may need to take different actions to block cookies
        used for customized ads in your browser or to adjust your device
        settings or mobile app permissions.
      </p>
      <p>
        Additionally, your choice of blocking is limited to the specific browser
        or device you use when blocking, so separate blocking actions may be
        required for each browser or device.
      </p>
      <p>
        If you reject, disable, or delete cookies, some features of this
        platform may not be available to you.
      </p>
      <p>&nbsp;</p>
      <p>2.How we use your personal data</p>
      <p>&nbsp;</p>
      <p>
        We will use the information we collect about you based on the legal
        grounds described below.
      </p>
      <p>
        In accordance with, and to perform our contract with you, we will use
        your information to:
      </p>
      <p>provide the Platform and associated services;</p>
      <p>notify you about changes to our service;</p>
      <p>provide you with user support;</p>
      <p>enforce our terms, conditions and policies;</p>
      <p>administer the Platform including troubleshooting;</p>
      <p>
        personalise the content you receive and provide you with tailored
        content that will be of interest to you;
      </p>
      <p>enable you to share User Content and interact with other users;</p>
      <p>enable you to participate in the virtual items program; and</p>
      <p>communicate with you.</p>
      <p>
        In order to comply with our legal obligations and as necessary to
        perform tasks in the public interest or to protect the vital interests
        of our users and other people, we use your data to help us prevent and
        respond to abuse, fraud, illegal activity and other potentially harmful
        content on the Platform.
      </p>
      <p>
        In accordance with our legitimate interests to provide an effective and
        dynamic Platform, we may use your information to:
      </p>
      <p>
        ensure your safety and security, including reviewing User Content,
        messages and associated metadata for breaches of our Community
        Guidelines and our Terms of Service;
      </p>
      <p>
        ensure content is presented in the most effective manner for you and
        your device;
      </p>
      <p>
        understand how people use the Platform so that we can improve, promote
        and develop it;
      </p>
      <p>promote popular topics, hashtags and campaigns on the Platform;</p>
      <p>
        carry out data analysis and test the Platform to ensure its stability
        and security;
      </p>
      <p>
        provide non-personalised advertising, which keeps many of our services
        free;
      </p>
      <p>
        infer your interests for optimising our advertising offerings, which,
        where you&rsquo;ve consented to personalised advertising, may be based
        on the information our advertising partners provide to us;
      </p>
      <p>
        measure the effectiveness of the advertising you see on our Platform;
      </p>
      <p>
        inform our algorithms so we can deliver the most relevant content to you
        and to prevent crime and misuse of the Platform;
      </p>
      <p>carry out surveys regarding our services, products and features;</p>
      <p>
        allow you to participate in interactive features of the Platform; and
      </p>
      <p>
        enable you to socialise on the Platform. For example, we may allow other
        users to identify you via the "Find Friends" function or through their
        phone contacts or connect you with other users by tracking who you share
        links with.
      </p>
      <p>
        Where we process your information to fulfill our legitimate interests,
        we conduct a balancing test to check that using personal data is really
        necessary for us to achieve our business purpose. When we carry out this
        balancing test we also take into account the privacy rights of our users
        and put in place appropriate safeguards to protect their personal data.
      </p>
      <p>
        <br /> 3.How we share your personal data
      </p>
      <p>&nbsp;</p>
      <p>We share your data with the following selected third parties:</p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>Business Partners</strong>
      </p>
      <p>
        If you choose to register to use the Platform using your social network
        account details, you provide us or allow your social network to provide
        us with your username and public profile. We will likewise share certain
        information with the relevant social network such as your app ID, access
        token and the referring URL.
      </p>
      <p>
        Where you opt to share content on social media platforms, the video,
        username and any text associated with the post will be shared on that
        platform or, in the case of sharing via instant messaging platforms, a
        link to the content will be shared.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>Payment Providers</strong>
      </p>
      <p>
        If you choose to buy virtual items we will share data with the relevant
        payment provider to facilitate this transaction. We share a transaction
        ID to enable us to identify you and credit your account with the correct
        value in points once you have made the payment.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>Service Providers</strong>
      </p>
      <p>
        We provide information and content to service providers who support our
        business, such as cloud service providers and providers of content
        moderation services to ensure that the Platform is a safe and enjoyable
        place.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>Analytics and measurement providers</strong>
      </p>
      <p>
        We use analytics and measurement providers to help us improve the
        Platform including by assisting us with content measurement and
        following your activity on our Platform across your devices.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>Advertisers</strong>
      </p>
      <p>
        We only share aggregated user information with advertisers. Aggregated
        information is information that is grouped together and is not specific
        to an individual user. This is done to help measure the effectiveness of
        an advertising campaign by showing advertisers how many users of the
        Platform have viewed or clicked on an advertisement.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>Our Corporate Group</strong>
      </p>
      <p>
        We may share your information with other members, subsidiaries, or
        affiliates of our corporate group where it is necessary to provide the
        Platform in accordance with the Terms of Service.
      </p>
      <p>
        We share information to improve and optimise the Platform, including to
        prevent illegal use and to support users.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Law Enforcement / Legal Obligation</strong>
      </p>
      <p>
        We may share your information with law enforcement agencies, public
        authorities or other third parties if we consider that we are legally
        required to do so or if such use is reasonably necessary to:
      </p>
      <p>comply with a legal process or request;</p>
      <p>
        enforce our Terms of Service and other agreements, policies, and
        standards, including investigation of any potential violation;
      </p>
      <p>
        detect, prevent or otherwise address abuse, fraud, illegal activity or
        security or technical issues; or
      </p>
      <p>
        protect the rights, property or safety of us, our users, a third party
        or the public as required or permitted by law (including exchanging
        information with other companies and organisations for the purposes of
        fraud protection and credit risk reduction).
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>Public Profiles</strong>
      </p>
      <p>
        If your profile is public, your content will be visible to anyone on the
        Platform and may also be accessed or shared by your friends and
        followers as well as third parties such as search engines, content
        aggregators and news sites. You can change who can see a video each time
        you upload a video. You can also change your profile to private by
        changing your settings to 'Private account' in 'Privacy and safety'
        settings.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>Sale or Merger</strong>
      </p>
      <p>We disclose your information to third parties:</p>
      <p>
        in the event that we sell or buy any business or assets (for example, as
        a result of liquidation, bankruptcy or otherwise). In such transactions,
        we will disclose your data to the prospective seller or buyer of such
        business or assets; or
      </p>
      <p>
        if we sell, buy, merge, are acquired by, or partner with other companies
        or businesses, or sell some or all of our assets. In such transactions,
        user information may be among the transferred assets.
      </p>
      <p>&nbsp;</p>
      <p>4.Your Rights</p>
      <p>
        You can access and edit most of your profile information as you
        subscribe to CELEBE. You may delete user content that you have uploaded.
        We also provide a number of setup tools that you can adjust, and you
        have control over who can view your video, who can send you a message,
        and who can leave comments on your video. If desired, you can delete all
        of your accounts from Settings. If you have any questions about how to
        use the tool or would like to know about your rights in your country of
        residence, please contact us at{" "}
        <a href="mailto:info@celebe.io">info@celebe.io</a>.
      </p>
      <p>&nbsp;</p>
      <p>5.The security of your personal data</p>
      <p>
        We take steps to ensure that your information is treated securely and in
        accordance with this policy. Unfortunately, the transmission of
        information via the internet is not completely secure. Although we will
        do our best to protect your personal data, for example, by encryption,
        we cannot guarantee the security of your information transmitted via the
        Platform, which means any transmission is at your own risk.
      </p>
      <p>
        We have appropriate technical and organisational measures to ensure a
        level of security appropriate to the risk that may be posed to you and
        other users. We maintain these technical and organisational measures and
        will amend them from time to time to improve the overall security of our
        systems.
      </p>
      <p>
        We will, from time to time, include links to and from the websites of
        our partner networks, advertisers and affiliates. If you follow a link
        to any of these websites, please note that these websites have their own
        privacy policies and that we do not accept any responsibility or
        liability for these policies. Please check these policies before you
        submit any information to these websites.
      </p>
      <p>&nbsp;</p>
      <p>6.How long we keep your personal data</p>
      <p>
        We retain your information for as long as it is necessary to provide you
        with the service so that we can fulfil our contractual obligations and
        exercise our rights in relation to the information involved. Where we do
        not need your information in order to provide the service to you, we
        retain it only for so long as we have a legitimate business purpose in
        keeping such data.
      </p>
      <p>&nbsp;</p>
      <p>7.Information relating to children</p>
      <p>
        CELEBE is not directed at children under the age of 13. If you believe
        that we have personal data about or collected from a child under the
        relevant age, contact us at:{" "}
        <a href="mailto:info@celebe.io">info@celebe.io</a>
      </p>
      <p>&nbsp;</p>
      <p>8.User complaints</p>
      <p>
        If you would like to complain about the way we process your personal
        information, please contact us first at{" "}
        <a href="mailto:info@celebe.io">info@celebe.io</a> and we will try to
        expedite your request. The foregoing does not infringe on your right to
        file a complaint with the relevant Privacy Regulator.
      </p>
      <p>&nbsp;</p>
      <p>9.Changes</p>
      <p>
        We will notify you of any material changes to this policy through a
        notice provided via the Platform or by other means. The &ldquo;Last
        Updated&rdquo; date at the top of this policy reflects the effective
        date of such policy changes.
      </p>
      <p>
        We are committed to protecting and respecting your privacy. This policy
        explains our practices concerning the personal data we collect from you,
        or that you provide to us. If you do not agree with this policy, you
        should not use the Platform.
      </p>
      <p>&nbsp;</p>
      <p>10.contact</p>
      <p>
        If you have any questions about how we use your personal data, contact
        us at: <a href="mailto:info@celebe.io">info@celebe.io</a>
      </p>
      <p>
        Additional Terms and Conditions Applicable to Specific Jurisdictions
      </p>
      <p>
        In the event of a conflict between the terms of a particular
        jurisdictional add-on (hereinafter referred to as the \\\"Additional
        Terms\\\") and the main body of this policy in the jurisdiction where
        you access this service or use this service, the additional terms and
        conditions in that jurisdiction shall apply first apply.
      </p>
      <p>&nbsp;</p>
      <p>Republic of Korea</p>
      <p>
        If you are using our services in Korea, the following additional terms
        and conditions apply. In the event of a conflict between the Additional
        Terms and Conditions and the Body Clause of this Policy, the Additional
        Terms and Conditions shall prevail.
      </p>
      <p>&nbsp;</p>
      <p>How you share your personal information.</p>
      <p>
        In addition to the body of this policy, if we use information such as
        user-generated and video content that you post on our platform for
        promotional purposes at your choice as part of our advertising and
        marketing campaign, your personal information may be disclosed to the
        recipient.
      </p>
      <p>&nbsp;</p>
      <p>Information retention.</p>
      <p>
        We destroy any personal information that has been achieved for the
        purpose of the collection you have agreed to, or that the period of use
        you agreed to, or that has expired as specified in this policy. However,
        we will continue to retain your personal information during the legal
        period, including but not limited to:
      </p>
      <p>&nbsp;</p>
      <p>The Consumer Protection Act in Electronic Commerce, etc. Act:</p>
      <p>
        Your order cancellation record, payment record for purchase items, and
        our product/service delivery record: 5 years
      </p>
      <p>Record of handling consumer complaints or disputes: 3 years</p>
      <p>Advertising and marking records: 6 months</p>
      <p>Communications Secrets Act:</p>
      <p>Your visit to our website is: 3 months</p>
      <p>&nbsp;</p>
      <p>destruction of personal information</p>
      <p>
        We destroy your personal information in a way that the relevant
        department cannot recover.
      </p>
      <p>&nbsp;</p>
      <p>Your rights</p>
      <p>the right to information</p>
      <p>
        You have the right to request access to your personal information,
        correction, deletion and suspension of processing of inaccurate
        information.
      </p>
      <p>
        You may exercise your rights by contacting{" "}
        <a href="mailto:info@celebe.io">info@celebe.io</a>.
      </p>
      <p>&nbsp;</p>
      <p>Information security.</p>
      <p>
        We are committed to protecting Seller's expenses and users from
        unauthorized access to or from unauthorized alteration, unauthorized
        disclosure or destruction of the information we have. To this end, we
        are implementing technical, administrative, and physical protection
        measures, including establishing an internal management plan for
        information protection, granting access to only the necessary personnel,
        and controlling access to personal information processing facilities.
      </p>
      <p>&nbsp;</p>
      <p>a change in policy</p>
      <p>
        If we make any changes to this policy, we will notify you of any changes
        on our website or on our Services page at least 7 days prior to the
        effective date of the revised policy (30 days prior if the amendment
        significantly affects your rights or obligations under this policy).
      </p>
      <p>&nbsp;</p>
      <p>child information</p>
      <p>CELEBE are not intended for children under 12 years of age.</p>
      <p>&nbsp;</p>
      <p>Consignment and/or transfer of personal information.</p>
      <p>
        In some cases, we obtain your consent or notify you to entrust your
        information to our affiliates, cloud service providers, IT service
        providers and data centers, and some of our trustees are located
        overseas (see the list here). The parties receiving and processing your
        information are committed to taking all possible physical and technical
        steps to access, store, and protect your privacy in compliance with
        national and international regulations. If the transfer of personal
        information is unnecessary in providing this service to you, you may
        contact <a href="mailto:info@celebe.io">info@celebe.io</a> to refuse the
        transfer of personal information.
      </p>
      <p>
        Other inquiries: <a href="mailto:info@celebe.io">info@celebe.io</a>
      </p>
    </div>
  );
}
