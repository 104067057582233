import React, { useEffect } from "react";
import "./App.scss";
import Routes from "./components/CelebeRoutes";
import { BrowserRouter } from "react-router-dom";

function App() {
    return (
        <BrowserRouter>
            <div className="App">
                <Routes />
            </div>
        </BrowserRouter>
    );
}

export default App;
