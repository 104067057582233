import React from 'react';

export default function CommunityGuidelineEn({ theme }) {
    return (
        <div className={`guideline ${theme === 'dark' ? 'dark' : ''}`}>
            <p className='title-1'>Community Guideline</p>
            <br />
            <p>Last update date 2023. 05</p>
            <div>
                <p className='title-3'>Introduction</p>
                <p>The purpose of CELEBe is to unleash the creativity of all users and provide enjoyment.&nbsp;</p>
                <p>
                    The platform strives to create a global community where users can express their unique personalities, share their creativity with others, experience new worlds, and communicate
                    with people all over the world.
                </p>
                <p>As CELEBe grows, efforts are made to maintain a supportive environment for everyone.</p>
                <p>
                    The community guidelines establish norms and policies for behavior within the platform, providing policies for what content is allowed and what is not, making CELEBe a space for
                    everyone to enjoy.
                </p>
                <p>&nbsp;</p>
                <p>The top priorities of CELEBe are safety, diversity, inclusivity, and authenticity.</p>
                <p>The platform is committed to creating a safe environment for users to create inspiring content while respecting each other&apos;s personalities.&nbsp;</p>
                <p>&nbsp;</p>
                <p>The CELEBe community guidelines apply to all users and content within the platform.</p>
                <p>
                    Content is evaluated based on these guidelines before any problematic content is reported. If a violation of the guidelines is found, users can report it directly through the
                    app&apos;s features.
                </p>
                <p>&nbsp;</p>
                <p>
                    Any content (including videos, audio, comments, and text) that violates the guidelines may be removed, and content that violates the guidelines may be excluded from the Create To
                    Earn (C2E) program, which pays users for their content. Accounts and devices found to be continuously or seriously violating the community guidelines may be temporarily suspended
                    or permanently banned.
                </p>
                <p>Decisions are made based on information from other platforms and offline sources, and accounts may be reported to relevant law enforcement authorities as needed.</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>The algorithm used by CELEBe is designed with trust and safety in mind.</p>
                <p>
                    For some content, including spam and videos under review, or videos that may be offensive or shocking to general users, search results may be changed, and the content may be
                    limited in the main media. The platform recognizes that some content that must be deleted according to the guidelines may be valuable to someone else.
                </p>
                <p>
                    Therefore, exceptions are allowed for educational, documentary, scientific, or artistic content, satirical content, fictional content, expressing opposing opinions or news with
                    social importance, etc.
                </p>
                <p>Content guidelines are updated from time to time to meet new risks and behavior patterns so that users can remain a safe platform for guiding creativity and enjoying themselves.</p>

                <p className='title-3'>The body</p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>Violent and Hateful Organizations and Individuals</p>
                    </li>
                    <li>
                        <p>Hateful behavior</p>
                    </li>
                    <li>
                        <p>Illegal Activities and Restricted Items</p>
                    </li>
                    <li>
                        <p>Violent and unpleasant content</p>
                    </li>
                    <li>
                        <p>Suicide, self-harm, and dangerous behavior</p>
                    </li>
                    <li>
                        <p>Bullying and harassment</p>
                    </li>
                    <li>
                        <p>Adult naked body exposure and sexual activity</p>
                    </li>
                    <li>
                        <p>Safety of minors</p>
                    </li>
                    <li>
                        <p>Integrity and Authenticity</p>
                    </li>
                    <li>
                        <p>Personal Information</p>
                    </li>
                    <li>
                        <p>Violation of intellectual property rights</p>
                    </li>
                    <li>
                        <p>Platform Security</p>
                    </li>
                </ul>

                <p className='title-3'>▶ Violent and Hateful Organizations and Individuals</p>
                <p>&nbsp;The CELEBe platform maintains a strong stance against violence both within and outside the platform.</p>
                <p>Acts of threatening or promoting violence, or encouraging dangerous individuals or groups, are prohibited on CELEBe.</p>
                <p>
                    If an account is found to be promoting or glorifying violence outside of CELEBe or posing a threat to public safety, that account may be temporarily suspended or banned, and
                    threats may be reported to relevant legal authorities if necessary.
                </p>
                <p>
                    In order to effectively protect CELEBe, information from other platforms and offline sources may be used to identify violent and extremist individuals and groups. If such
                    individuals or groups are discovered on CELEBe, their accounts may be deleted.
                </p>
                <p>&nbsp;</p>
                <p>
                    <u>Threats and incitement of violence</u>
                </p>
                <p>CELEBe does not allow individuals or organizations that promote or participate in violence on the platform.&nbsp;</p>
                <p>Threats and incitement of violence are considered violent incitement, and we do not allow any behavior that may cause serious harm.</p>
                <p>
                    Dangerous individuals and organizations that promote violence, including mass murderers, serial killers and rapists, hate groups, criminal organizations, terrorist organizations,
                    and other non-state armed groups targeting civilians, may be removed from CELEBe.
                </p>
                <p>
                    <u>Therefore, the following content is prohibited from being posted, uploaded, streamed, or shared on the platform. This list is not exhaustive and may not include all cases:</u>
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>Attempting to cause physical harm to individuals or groups</p>
                    </li>
                    <li>
                        <p>Promoting or inciting physical violence or violent acts against others</p>
                    </li>
                    <li>
                        <p>Expressing a desire or conditions for others to commit acts of violence</p>
                    </li>
                    <li>
                        <p>A request to bring a weapon to a place with the intention of threatening or threatening an individual or group</p>
                    </li>
                    <li>
                        <p>Providing instructions on how to make or use weapons with the intent of promoting violence</p>
                    </li>
                </ul>
                <p>&nbsp;</p>
                <p>
                    <u>Terrorist organization</u>
                </p>
                <p>
                    Terrorists and terrorist organizations refer to non-state actors who exercise violence, threat, and/or serious crimes (e.g. crimes against humanity) against civilians to achieve
                    political, religious, national, or ideological goals.
                </p>
                <p>&nbsp;</p>
                <p>
                    <u>Criminal organization</u>
                </p>
                <p>
                    A criminal organization refers to a group that is involved in serious crimes, including violent crimes (e.g. murder, rape, robbery, and assault), trafficking (e.g. human
                    trafficking, organs, drugs, and weapons), kidnapping, economic crimes (e.g. extortion, embezzlement, fraud, and money laundering), or cybercrimes, at a supranational, national, or
                    local level.
                </p>
                <p>
                    <u>Therefore, the following contents are prohibited from being posted, uploaded, streamed, or shared. The list is not exhaustive and does not cover all cases.</u>
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>Content that praises, encourages, glorifies, or supports dangerous individuals and/or organizations.</p>
                    </li>
                    <li>
                        <p>Content that encourages participation in dangerous organizations or recruits people.</p>
                    </li>
                    <li>
                        <p>
                            Content that includes names, symbols, logos, flags, slogans, uniforms, gestures, salutes, images, portraits, songs, music, lyrics, or other objects that symbolize dangerous
                            individuals and/or organizations.
                        </p>
                    </li>
                </ul>

                <p className='title-3'>▶ Hateful behavior</p>
                <p>CELEBe is a platform that does not tolerate any discrimination and values diversity and inclusiveness.</p>
                <p>
                    Content that includes hate speech or behavior is not allowed, and accounts associated with hate speech outside of CELEBe may be temporarily suspended or prohibited from use for
                    violating the hate speech policy.
                </p>
                <p>&nbsp;</p>
                <p>
                    <u>Attacks on protected groups</u>
                </p>
                <p>
                    Content that attacks, threatens, incites violence against, or dehumanizes individuals or groups within the following protected categories is defined as content that includes hate
                    speech and behavior:
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>Race</p>
                    </li>
                    <li>
                        <p>Ethnicity</p>
                    </li>
                    <li>
                        <p>National origin</p>
                    </li>
                    <li>
                        <p>Religion</p>
                    </li>
                    <li>
                        <p>Caste</p>
                    </li>
                    <li>
                        <p>Sexual orientation</p>
                    </li>
                    <li>
                        <p>Gender</p>
                    </li>
                    <li>
                        <p>Gender identity</p>
                    </li>
                    <li>
                        <p>Serious diseases</p>
                    </li>
                    <li>
                        <p>Disabilities</p>
                    </li>
                    <li>
                        <p>Immigration status</p>
                    </li>
                </ul>
                <p>&nbsp;</p>
                <p>
                    <u>Therefore, the following types of content are prohibited from being posted, uploaded, streamed, or shared. This list is not exhaustive and does not include all cases:</u>
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>Hate speech content targeting individuals or groups in protected categories</p>
                    </li>
                    <li>
                        <p>Claims that they are physically, mentally, or morally inferior</p>
                    </li>
                    <li>
                        <p>Requests or justifications for violence against them</p>
                    </li>
                    <li>
                        <p>Claims that they are criminals</p>
                    </li>
                    <li>
                        <p>Referring to them as animals, inanimate objects, or subhuman beings</p>
                    </li>
                    <li>
                        <p>Promoting or justifying exclusion, segregation, or discrimination against them</p>
                    </li>
                    <li>
                        <p>Content that describes violence against individuals or groups within protectedcategories</p>
                    </li>
                </ul>
                <p>&nbsp;</p>
                <p>
                    <u>Discriminatory slurs</u>
                </p>
                <p>
                    Discriminatory slurs are defined as derogatory terms used with the intention of belittling ethnicities, races, and the protected groups mentioned above. To prevent the spread of
                    extremely offensive language, discriminatory slurs may be removed. Exceptions include when such terms are used in a modified form, to refer to oneself (e.g. in a song), or in cases
                    where they do not have a derogatory meaning.
                </p>
                <p>
                    <u>Therefore, the following types of content are prohibited from being posted, uploaded, streamed, or shared. This list is not exhaustive and does not include all cases:</u>
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>Content that uses or includes discriminatory slurs</p>
                    </li>
                </ul>
                <p>&nbsp;</p>
                <p>
                    <u>Hate ideology</u>
                </p>
                <p>
                    Hate ideology refers to the beliefs that express hostility towards people based on their status as a protected group. Hate ideology is opposed to the inclusive and supportive
                    values of CELEBe, and content that encourages such ideology may be removed.
                </p>
                <p>
                    <u>Therefore, the following types of content are prohibited from being posted, uploaded, streamed, or shared. This list is not exhaustive and does not include all cases:</u>
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>Content that praises, promotes, glorifies, or supports hate ideology</p>
                    </li>
                    <li>
                        <p>Names, symbols, logos, flags, slogans, uniforms, or emblems associated with hate ideology.</p>
                    </li>
                    <li>
                        <p>Content including portraits, songs, music, lyrics, or other objects</p>
                    </li>
                    <li>
                        <p>Content that denies past violent incidents even though there is a lot of evidence that has harmed the protection target</p>
                    </li>
                    <li>
                        <p>Content that compares specific groups to protected targets and claims superiority</p>
                    </li>
                    <li>
                        <p>Content dealing with conspiracy theories used to justify hate ideology</p>
                    </li>
                </ul>

                <p className='title-3'>▶ Illegal Activities and Restricted Items</p>
                <p>CELEBe is committed to creating a platform where activities that violate laws and regulations are not possible.</p>
                <p>
                    This includes the depiction, promotion, or facilitation of criminal activities, including human exploitation, as well as the trading, selling, advertising, and use of certain
                    regulated items.
                </p>
                <p>
                    Even if such activities or items may be legal in some jurisdictions, some content may still be removed if it is associated with illegal or regulated activities or items in most
                    countries and regions.
                </p>
                <p>&nbsp;</p>
                <p>
                    <u>Criminal Activities</u>
                </p>
                <p>Criminal activities include a wide range of actions that can be punishable by law, such as theft, assault, human exploitation, forgery, and other harmful behaviors.</p>
                <p>Content that tolerates or encourages criminal activities may be deleted to prevent normalization or imitation of such acts.</p>
                <p>
                    <u>Therefore, the following content is prohibited from being posted, uploaded, streamed, or shared. This list is not exhaustive and does not include all cases:</u>
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>Content that depicts or promotes physical harm such as violence or kidnapping.</p>
                    </li>
                    <li>
                        <p>Content that threatens the safety of others, such as false reporting or swatting.</p>
                    </li>
                    <li>
                        <p>
                            Content that depicts or encourages human exploitation, including human trafficking, debt bondage (forced labor to pay off debt), domestic servitude, sex trafficking, or
                            prostitution.
                        </p>
                    </li>
                    <li>
                        <p>Content that depicts or encourages illegal wildlife trade or poaching.</p>
                    </li>
                    <li>
                        <p>Content that proposes or solicits the purchase, sale, or trade of illegally acquired or counterfeit items.</p>
                    </li>
                    <li>
                        <p>Content that instructs how to commit criminal acts.</p>
                    </li>
                </ul>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>
                    <u>Weapons</u>
                </p>
                <p>CELEBe does not allow descriptions, promotion, or trade of firearms, ammunition, firearm accessories, or explosive weapons.</p>
                <p>Content related to methods of weapon production is also prohibited.</p>
                <p>
                    However, content related to weapons that are part of a museum collection, owned by law enforcement officers, or used in safe and controlled environments such as military parades or
                    shooting ranges may be allowed.
                </p>
                <p>
                    <u>Therefore, the following content is prohibited from being posted, uploaded, streamed, or shared. This list is not exhaustive and does not include all cases</u>
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>Content that shows firearms, firearm accessories, ammunition, or explosive weapon</p>
                    </li>
                    <li>
                        <p>Content that proposes or solicits the purchase, sale, or trade of firearms, accessories, ammunition, explosive weapons, or their production methods.</p>
                    </li>
                </ul>
                <p>&nbsp;</p>
                <p>
                    <u>Drugs, regulated drugs, alcohol and tobacco</u>
                </p>
                <p>CELEBe does not allow the description, encouragement or trade of drugs or other regulated drugs.</p>
                <p>The trade in tobacco and alcohol products is also prohibited within CELEBe.</p>
                <p>
                    <u>Therefore, the following content is prohibited from publishing, uploading, streaming, or sharing. The list is only partial and does not include all cases.</u>
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>Content that describes or encourages drug and drug consumption or encourages others to manufacture, use, or trade drugs and other regulated drugs</p>
                    </li>
                    <li>
                        <p>Content that proposes or requests the purchase, sale, or transaction of drugs and other regulated drugs, alcohol or tobacco products (including e-cigarette products)</p>
                    </li>
                    <li>
                        <p>Content that provides information on how to purchase illegal or regulated drugs</p>
                    </li>
                    <li>
                        <p>Content describing or encouraging the manufacture of illicit alcohol products</p>
                    </li>
                    <li>
                        <p>Content that describes or encourages the manual preparation of drugs to misuse or take legal drugs</p>
                    </li>
                </ul>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>
                    <u>Gambling</u>
                </p>
                <p>
                    CELEBe does not allow content that encourages gambling services or content that can be considered advertising for casinos, sports betting, or other commercial gambling activities.
                </p>
                <p>
                    <u>Therefore, the following content is prohibited from publishing, uploading, streaming, or sharing. The list is only partial and does not include all cases.</u>
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>Content encouraging casino, sports betting, poker, lottery, gambling related software and apps or other gambling services</p>
                    </li>
                </ul>
                <p>&nbsp;</p>
                <p>
                    <u>Act of fraud</u>
                </p>
                <p>CELEBe does not allow us to abuse your trust and cause economic and personal damage.</p>
                <p>Content that illegally cheats people for economic or personal gain, including fraud or asset theft schemes, may be deleted.</p>
                <p>
                    <u>
                        Therefore, the following content is prohibited from publishing, uploading, streaming, or sharing. The list is only partial and does not include all cases. The list is only
                        partial and does not include all cases.
                    </u>
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>Content that describes or encourages phishing</p>
                    </li>
                    <li>
                        <p>Content that describes or encourages Ponzi-type fraud, *multi-level marketing, or pyramid-type fraud</p>
                    </li>
                    <li>
                        <p>Content that describes or encourages high-yield investment fraud, *match-fixing, or any other kind of fraud</p>
                    </li>
                    <li>
                        <p>Content on cash payments, &apos;get rich&apos; advertising, multi-level sales (paying only without real products in a multi-level structure)</p>
                    </li>
                    <li>
                        <p>An exaggerated advertisement that claims that you can become rich quickly or that you can cure chronic diseases such as cancer with miraculous treatments</p>
                    </li>
                    <li>
                        <p>Promote cash payments or other multi-level sales</p>
                    </li>
                    <li>
                        <p>Accounts primarily intended for cash payments</p>
                    </li>
                    <li>
                        <p>Video promising &apos;this way I&apos;ll make $50,000 tomorrow&apos;</p>
                    </li>
                </ul>

                <p className='title-3'>▶ Violent and unpleasant content</p>
                <p>CELEBe values creativity but avoids violent content for shock value.</p>
                <p>We do not allow unnecessarily shocking, unpleasant, cruel, or harassing content.</p>
                <p>We also prohibit content that encourages, glorifies, or normalizes extreme violence or pain.</p>
                <p>If a post threatens public safety, we may temporarily suspend or permanently ban the account and report it to the relevant legal authorities.</p>
                <p>
                    <u>Therefore, the following content is prohibited from being posted, uploaded, streamed, or shared. This list is not exhaustive and does not include all cases:</u>
                </p>
                <p>*Content related to people, such as:</p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>Terrible deaths or accidents</p>
                    </li>
                    <li>
                        <p>Cut, damaged, burned, or charred corpses</p>
                    </li>
                    <li>
                        <p>Bloody content focusing on open wounds or injuries</p>
                    </li>
                    <li>
                        <p>Real-world physical violence, fights, and torture</p>
                    </li>
                </ul>
                <p>*Content related to animals, such as:</p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>Animal slaughter or death other than natural causes</p>
                    </li>
                    <li>
                        <p>Cut, damaged, burned, or charred animal corpses</p>
                    </li>
                    <li>
                        <p>Cruel acts towards animals and bloody content</p>
                    </li>
                </ul>

                <p className='title-3'>▶ Suicide, self-harm, and dangerous behavior</p>
                <p>CELEBe values the health and well-being of its users.</p>
                <p>We do not allow content that describes, encourages, normalizes, or glorifies activities that could lead to suicide, self-harm, or eating disorders.</p>
                <p>We also do not allow content that depicts users engaging in dangerous behavior that could result in serious injury or death, or that encourages others to do so.</p>
                <p>
                    However, we support users sharing personal experiences in a safe way to raise awareness about these issues and to seek help from the community. We also encourage users who know
                    someone struggling with thoughts of self-harm or suicide to immediately report them to local emergency services or suicide prevention hotlines. If CELEBe can help users who are at
                    risk of harming themselves, we may contact the appropriate local emergency services directly.
                </p>
                <p>&nbsp;</p>
                <p>
                    <u>Suicide</u>
                </p>
                <p>Content containing suicide, suicidal urges, or similar behaviors that could induce such actions may be removed.</p>
                <p>Content that describes suicide attempts or shows users engaging in behaviors that are highly likely to result in suicide or indicate an intention to do so may also be removed.</p>
                <p>
                    All forms of content that encourage, normalize, or glorify suicide, or that depict suicide as heroic or honorable, or that share information about suicide methods, are prohibited.
                </p>
                <p>
                    <u>Therefore, the following types of content are prohibited from being posted, uploaded, streamed, or shared. This list is not exhaustive and does not include all cases.</u>
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>Content that shares information about suicide methods</p>
                    </li>
                    <li>
                        <p>Content that describes, encourages, normalizes, or glorifies suicide</p>
                    </li>
                    <li>
                        <p>Suicide games, challenges, promises, or pranks</p>
                    </li>
                </ul>
                <p>&nbsp;</p>
                <p>
                    <u>Self-harm and eating disorders</u>
                </p>
                <p>To avoid normalizing or encouraging self-harm behavior, CELEBe does not allow images that depict such behavior regardless of the user&apos;s intent.</p>
                <p>Content that promotes or normalizes behavior that is likely to cause physical harm due to self-harm is also subject to removal.</p>
                <p>Content that encourages unhealthy eating habits that could have a negative impact on health is also not allowed within CELEBe.</p>
                <p>
                    <u>Therefore, the following types of content are prohibited from being posted, uploaded, streamed, or shared. This list is not exhaustive and does not include all cases.</u>
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>Content that describes, encourages, normalizes, or glorifies self-harm or eating disorders</p>
                    </li>
                    <li>
                        <p>Content that shares information about ways to engage in self-harm or eating disorders.</p>
                    </li>
                    <li>
                        <p>Content related to games, challenges, promises, or pranks related to self-harm or eating disorders</p>
                    </li>
                    <li>
                        <p>Content describing, encouraging, routine or glorifying dangerous weight loss behaviors, such as eating disorders</p>
                    </li>
                </ul>
                <p>&nbsp;</p>
                <p>
                    <u>Dangerous behavior</u>
                </p>
                <p>
                    CELEBe defines hazardous actions or other hazardous actions as cases in which non-professional actions without the necessary skills and safety measures can lead to serious injury
                    or death of the user or the public.
                </p>
                <p>It does not allow content to describe, encourage, routine, or glorify dangerous behaviors, including amateur stunts or dangerous challenges.</p>
                <p>
                    <u>Therefore, the following content is prohibited from publishing, uploading, streaming, or sharing. The list is only partial and does not include all cases.</u>
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>Content that shows improper use of dangerous tools, cars, or objects</p>
                    </li>
                    <li>
                        <p>Content that describes or encourages eating objects that are not edible or that can cause serious harm</p>
                    </li>
                    <li>
                        <p>Dangerous games, challenges or stunts that can lead to injury</p>
                    </li>
                </ul>

                <p className='title-3'>▶ Bullying and harassment</p>
                <p>CELEBe aims for an inclusive platform that enables unique self-expression without worrying about bullying.</p>
                <p>It does not allow insults, harassment or harassment of community members.</p>
                <p>Abusive content or behavior can cause serious psychological stress and may be deleted.</p>
                <p>&nbsp;</p>
                <p>
                    <u>abusive behavior</u>
                </p>
                <p>CELEBe prohibits any form of abuse-related content, including intimidation, ridicule, insults, intimidation or disparaging remarks intended to hurt.</p>
                <p>This prohibition policy also applies to content utilizing CELEBe&apos;s capabilities.</p>
                <p>To allow the general public to express important issues, critical comments about public figures may be allowed.</p>
                <p>However, serious abusive behavior against public figures is prohibited.</p>
                <p>
                    <u>Therefore, the following content is prohibited from publishing, uploading, streaming, or sharing. The list is only partial and does not include all cases.</u>
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>Content that insults or denigrates others based on intelligence, appearance, personality, or hygiene</p>
                    </li>
                    <li>
                        <p>Content that recommends that multiple people collude and tease someone</p>
                    </li>
                    <li>
                        <p>Content disparaging victims of violent tragedy</p>
                    </li>
                    <li>
                        <p>Content that demeans others using CELEBe interactive function</p>
                    </li>
                    <li>
                        <p>Content that describes intentional damage or intimidation, such as cyberstalking or trolling</p>
                    </li>
                    <li>
                        <p>Content that seeks serious damage, such as death or illness of a specific person or public figure</p>
                    </li>
                </ul>
                <p>&nbsp;</p>
                <p>
                    <u>Sexual harassment</u>
                </p>
                <p>Sexual harassment refers to unwanted or inappropriate sexual behavior toward others.</p>
                <p>CELEBe does not allow content that sexually teases others, including comments or access, regardless of your intentions.</p>
                <p>This is because such behavior can cause extreme stress on the target</p>
                <p>
                    <u>Therefore, the following content is prohibited from publishing, uploading, streaming, or sharing. The list is only partial and does not include all cases.</u>
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>Content that attempts unwanted sexual contact</p>
                    </li>
                    <li>
                        <p>Content that uses words or text (including emoji) and in-app features to pretend to engage in sexual activity with other users</p>
                    </li>
                    <li>
                        <p>Content that demeans other people&apos;s sexual behavior</p>
                    </li>
                    <li>
                        <p>Content that describes or implies participation in sexual or sexual acts by changing or transforming the image of others</p>
                    </li>
                    <li>
                        <p>
                            Content that reveals or threatens to disclose someone&apos;s personal sex life, including digital content, sexual past, and threatening to disclose the name of a past sex
                            partner
                        </p>
                    </li>
                    <li>
                        <p>Content that exposes or threatens to expose someone&apos;s sexual orientation without their consent or without their knowledge</p>
                    </li>
                </ul>
                <p>&nbsp;</p>
                <p>
                    <u>Hacking, Doxxing, Threatening</u>
                </p>
                <p>Threatening to hack or rob others with the intention of making fun of or threatening them can cause serious emotional and other offline damage.</p>
                <p>CELEBe defines doxing as the act of collecting and disclosing personal information or personal identification information (PII) for malicious purposes.</p>
                <p>This online behavior is considered a form of abuse and is prohibited within CELEBe.</p>
                <p>
                    <u>Therefore, the following content is prohibited from publishing, uploading, streaming, or sharing. The list is only partial and does not include all cases.</u>
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>
                            Content threatening to disclose personal or personal identification information (PII) including your home address, undisclosed email address, private phone number, bank
                            statement, social security number or passport number
                        </p>
                    </li>
                    <li>
                        <p>Content threatening to hack or extort other people&apos;s accounts</p>
                    </li>
                    <li>
                        <p>Content that incites or encourages others to hack or disclose a particular person&apos;s account, personal information, or personal identification information (PII)</p>
                    </li>
                    <li>
                        <p>
                            Content that discloses someone&apos;s account, personal information, or personal identification information so that others can misuse, troll (teasing to incite anger on the
                            Internet) or tease them
                        </p>
                    </li>
                </ul>

                <p className='title-3'>▶ Adult naked body exposure and sexual activity</p>
                <p>CELEBe strives to be a secure platform.</p>
                <p>Exposure, pornography, or sexually explicit content is prohibited within CELEBe.</p>
                <p>
                    It also does not allow content that describes or supports sexual behavior without consent, shares sexual images that do not agree, or describes or supports sexual exploitation of
                    adults.
                </p>
                <p>&nbsp;</p>
                <p>
                    <u>Sexual exploitation</u>
                </p>
                <p>
                    Sexual exploitation is defined as exploiting or attempting to exploit one&apos;s credibility, status, or weaknesses of others for sexual purposes, and includes the act of
                    benefiting economically, socially, or politically from sexual exploitation of others.
                </p>
                <p>
                    <u>Therefore, the following content is prohibited from publishing, uploading, streaming, or sharing. The list is only partial and does not include all cases.</u>
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>Content that describes, requests/suggests, encourages, normalizes, or glorifies sexual acts or contacts without consent, including rape and sexual violence</p>
                    </li>
                    <li>
                        <p>
                            Content that describes, requests/suggestions, encourages, routine or glorifies the sharing of an unconsented sexual image, including an unconsented sexual image, produced,
                            or shared sexual image
                        </p>
                    </li>
                    <li>
                        <p>Content that describes, encourages, normalizes, or glorifies sexual violence</p>
                    </li>
                    <li>
                        <p>
                            Content that describes, encourages, or glorifies sexual requests/suggestions, including suggestions and requests from sex partners, sexual chat or images, sexual services,
                            premium sexual content, or sex cams (a service that shows the sexual relationship in real time)
                        </p>
                    </li>
                </ul>
                <p>&nbsp;</p>
                <p>
                    <u>Exposure and sexual behavior involving adults</u>
                </p>
                <p>Exposure to the naked body and sexual behavior include openly exposing the breast, genitals, anus, or hip, or mimicking, suggesting, or showing sexual behavior.</p>
                <p>CELEBe does not allow for naked exposure or depiction of sexual activity, including digitally created or manipulated content.</p>
                <p>We keep in mind content that may be offensive or considered culturally inappropriate in certain regions, and content that may not be appropriate for all ages.</p>
                <p>
                    <u>Therefore, the following content is prohibited from publishing, uploading, streaming, or sharing. The list is only partial and does not include all cases.</u>
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>Content that explicitly or implicitly describes sexual behavior, including embedded and non-inserted sexual intercourse, oral intercourse, or sexual kisses</p>
                    </li>
                    <li>
                        <p>Content that describes sexual excitement or sexual stimulation</p>
                    </li>
                    <li>
                        <p>Content describing sexual fetish</p>
                    </li>
                    <li>
                        <p>Content describing exposed human genitals, female nipples or annealing, pubic or hip</p>
                    </li>
                    <li>
                        <p>Content that includes sexually explicit language for sexual satisfaction</p>
                    </li>
                </ul>

                <p className='title-3'>▶ Safety of minors</p>
                <p>CELEBe does not allow activities to abuse, exploit, or endanger or harm minors within the CELEBe.</p>
                <p>
                    Any content depicting abuse, exploitation or naked exposure to minors, including media created or manipulated in animation and digitally, will be considered a policy violation on
                    our platform and will be deleted as soon as it is found.
                </p>
                <p>If necessary, the content may be reported to the National Center for the Prevention of Missing and Abused Children (NCMEC) and/or other relevant law enforcement authorities.</p>
                <p>CELEBe defines minors as under 18.</p>
                <p>&nbsp;</p>
                <p>Users must meet the minimum age requirements of CELEBe (under 13 years of age) specified in the Terms of Service.</p>
                <p>If an account owner does not meet age requirements, account deletion may proceed.</p>
                <p>&nbsp;</p>
                <p>
                    <u>Sexual exploitation of minors</u>
                </p>
                <p>
                    Sexual exploitation of minors is defined as exploitation of their credibility or status for sexual purposes and includes economic, social, sexual, or political benefits from
                    exploitation of minors.
                </p>
                <p>
                    Child Sexual Abuse Material (CSAM) is defined as a visual representation of sexually explicit nakedness or behavior captured by predatory adults and their peers or minors
                    themselves.
                </p>
                <p>CELEBe takes action on content or accounts regarding sexual exchanges and access between adults and minors or minors with a large age gap.</p>
                <p>
                    <u>Therefore, the following content is prohibited from publishing, uploading, streaming, or sharing. The list is only partial and does not include all cases.</u>
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>Offer to share, re-share, trade, or sell CSAM, and content that attracts users off the platform to obtain or distribute CSAM</p>
                    </li>
                    <li>
                        <p>Content that is sexually related to minors or otherwise sexually objectifies minors (e.g., through product features such as duets)</p>
                    </li>
                    <li>
                        <p>
                            Content that describes, requests/suggests, beautifies, or recommends images of sexual abuse, including exposure to minors&apos; naked bodies, sexual objectification, or
                            sexual conduct with minors
                        </p>
                    </li>
                    <li>
                        <p>Content that describes, encourages, normalizes, or glorifies sexual violence in children or minors</p>
                    </li>
                    <li>
                        <p>Content in which victims of abuse who are minors may be damaged or abused again by a third party reenacting or sharing sexual violence or confessions</p>
                    </li>
                </ul>
                <p>&nbsp;</p>
                <p>
                    <u>Act of grooming</u>
                </p>
                <p>
                    The act of grooming is the act of an adult establishing an emotional relationship with a minor in order to gain the trust of the minor in order to make sexual contact, sexual
                    abuse, prostitution, or other exploitation later or continuously.
                </p>
                <p>
                    These activities include flattery, contact requests within and outside CELEBe, personal information requests, requests/suggestions for sexual abuse materials for minors, sexual
                    requests/suggestions or comments and gifts.
                </p>
                <p>
                    <u>Therefore, the following content is prohibited from publishing, uploading, streaming, or sharing. The list is only partial and does not include all cases.</u>
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>An approach to grooming</p>
                    </li>
                    <li>
                        <p>Content that describes, encourages, routineizes, or glorifies grooming behavior</p>
                    </li>
                    <li>
                        <p>Content that requests/suggests sexual contact between minors and adults in the real world or between minors with a large age gap</p>
                    </li>
                    <li>
                        <p>Contents that show or suggest naked images to minors</p>
                    </li>
                    <li>
                        <p>Any content that requests/suggests nude images or sexual contact through intimidation or other coercive means</p>
                    </li>
                </ul>
                <p>&nbsp;</p>
                <p>
                    <u>Sexual behavior involving minors</u>
                </p>
                <p>
                    Exposure to naked bodies and sexual activity involving minors is content that openly exposes the breast, genitals, anus or hip, or imitates, alludes, or shows sexual behavior
                    involving minors.
                </p>
                <p>CELEBe does not allow content that describes nakedness or sexual activity, including digitally created or manipulated content.</p>
                <p>
                    <u>Therefore, the following content is prohibited from publishing, uploading, streaming, or sharing. The list is only partial and does not include all cases.</u>
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>Content that describes or implies sexual activities of minors, including inserted or uninserted sexual intercourse, oral intercourse, and sensational kissing</p>
                    </li>
                    <li>
                        <p>Content describing sexual excitement or sexual stimulation involving minors</p>
                    </li>
                    <li>
                        <p>Content depicting sexual fetishes involving minors</p>
                    </li>
                    <li>
                        <p>Content describing a minor&apos;s genitals, nipples or pedicles, pubis or hip</p>
                    </li>
                    <li>
                        <p>Content that describes or describes minors in sexually explicit language</p>
                    </li>
                    <li>
                        <p>Contents depicting minors with sexually explicit lyrics</p>
                    </li>
                    <li>
                        <p>
                            Content that includes sexually explicit dances by minors, such as twerking, chest shaking, pelvic pushing, or touching one&apos;s or another&apos;s groin or breast caress
                        </p>
                    </li>
                    <li>
                        <p>Content depicting a minor taking off his clothes</p>
                    </li>
                    <li>
                        <p>Content that describes a minor wearing very little clothing in a place that is inappropriate for the situation</p>
                    </li>
                    <li>
                        <p>Sexual comments, emojis, text, or other graphic content to hide or imply a minor&apos;s nakedness or sexual activity</p>
                    </li>
                </ul>
                <p>&nbsp;</p>
                <p>
                    <u>Harmful activities by minors</u>
                </p>
                <p>
                    Hazardous activities of minors include possession and consumption of substances prohibited by minors, misuse of legal drugs, participation in illegal activities, and participation
                    in challenges and challenges that threaten the well-being of minors.
                </p>
                <p>Such content may be deleted.</p>
                <p>
                    <u>Therefore, the following content is prohibited from publishing, uploading, streaming, or sharing. The list is only partial and does not include all cases.</u>
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>Content that implies, describes, emulates, or encourages the possession or consumption of alcoholic beverages, cigarettes and drugs by minors</p>
                    </li>
                    <li>
                        <p>Content that tells minors how to purchase, sell, or trade alcohol, tobacco, or regulated drugs</p>
                    </li>
                    <li>
                        <p>Content that describes or encourages activities that threaten youth well-being, including physical challenges, challenges, and stunts</p>
                    </li>
                </ul>
                <p>&nbsp;</p>
                <p>
                    <u>Physical and psychological damage to minors</u>
                </p>
                <p>Behaviors at risk of physical or psychological damage to minors include physical abuse, neglect, endangerment, and psychological degradation.</p>
                <p>Such content may be deleted.</p>
                <p>
                    <u>Therefore, the following content is prohibited from publishing, uploading, streaming, or sharing. The list is only partial and does not include all cases.</u>
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>Content that describes or encourages physical abuse, neglect, endangerment, and psychological degradation of minors</p>
                    </li>
                    <li>
                        <p>Content that describes or encourages how to survive extreme situations without warning of the danger of imitation</p>
                    </li>
                </ul>
                <p>&nbsp;</p>
                <p>
                    <u>Crimes against children</u>
                </p>
                <p>CELEBe does not allow users convicted of crimes against children to open accounts on our platform.</p>
                <p>
                    These crimes include sexual violence, molestation, murder, physical abuse or neglect, kidnapping, kidnapping, trafficking, exploitation of minors for prostitution, online sexual
                    abuse of minors, travel and tourism, attempts to obtain and distribute child sexual abuse materials (CSAM), CSAM production, possession and distribution.
                </p>
                <p>These users&apos; accounts will be deleted as soon as they are found.</p>
                <p>If your information identifies itself as a paedophile or a minor sex offender, you will consider that information to be true and delete your account.</p>

                <p className='title-3'>▶ Integrity and Authenticity&nbsp;</p>
                <p>
                    CELEBe does not allow activities that could compromise the integrity of the platform and the authenticity of users. You can delete content or accounts that cause damage or violate
                    intellectual property rights through spam, fake interactions, impersonations, or false information.
                </p>
                <p>&nbsp;</p>
                <p>
                    <u>False information</u>
                </p>
                <p>
                    False information is defined as inaccurate or incorrect information. We encourage conversations in the CELEBe community that respect each other on important topics, and do not
                    allow false information that harms individuals, communities, or the general public, regardless of their intentions.
                </p>
                <p>
                    <u>Therefore, the following content is prohibited from publishing, uploading, streaming, or sharing. The list is only partial and does not include all cases.</u>
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>False information that incites hatred or prejudice</p>
                    </li>
                    <li>
                        <p>False information related to a chaotic emergency</p>
                    </li>
                    <li>
                        <p>Misleading medical information that can harm your physical health</p>
                    </li>
                    <li>
                        <p>Content that mislead community members about the election or civic engagement process</p>
                    </li>
                    <li>
                        <p>Conspiracy theory content that urges aggression or violent action against a particular protection target or denies violent or tragic events in the past</p>
                    </li>
                    <li>
                        <p>
                            Digital forgery (media synthesis or manipulation) content that mislead users and harms the object of the video or other people or society as a whole by distorting the truth
                            about the incident
                        </p>
                    </li>
                </ul>
                <p>
                    Do not systematically engage in untrue activities such as creating accounts to influence and manipulate public opinion while providing misinformation to individuals, our community,
                    or the public about the identity, location, or purpose of your account.
                </p>
                <p>&nbsp;</p>
                <p>
                    <u>Spam</u>
                </p>
                <p>CELEBe does not allow content that is excessively frequently published, repeated, or obvious, and contains one or more of the following.</p>
                <p>To maintain a secure and positive community, we are limiting the number of video feeds that can be uploaded from your account for 24 hours.</p>
                <p>If you see a &apos;Daily Upload Limit Reached&apos; message when trying to upload a video feed, it is recommended that you try to upload it again in approximately 24 hours.</p>
                <p>
                    <u>
                        Therefore, the following content is prohibited from publishing, uploading, streaming, or sharing. The list is only partial and does not include all cases. The list is only
                        partial and does not include all cases.
                    </u>
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>Content that promises to show viewers something, but attracts them to external sites without showing it</p>
                    </li>
                    <li>
                        <p>Repeated publishing of the same content to one or more accounts</p>
                    </li>
                    <li>
                        <p>Content that drives clicks, views, or traffic to CELEBe external sites while promising to generate revenue quickly for viewers</p>
                    </li>
                    <li>
                        <p>Content that attracts viewers to sites that distribute harmful software, collect personal information, or other sites that have a negative impact</p>
                    </li>
                    <li>
                        <p>Computer-generated content without regard to quality or viewer experience</p>
                    </li>
                    <li>
                        <p>Content that promises to provide free cash, products, software, or gaming benefits to viewers who install, download, or otherwise perform software</p>
                    </li>
                </ul>
                <p>&nbsp;</p>
                <p>
                    <u>Incentive spam</u>
                </p>
                <p>CELEBe does not allow content that sells engagement metrics such as views, likes, and comments.</p>
                <p>
                    This type of spam includes content that only aims to increase subscriber counts, views, or other metrics. For example, &apos;follow-for-follow&apos; content that suggests following
                    another creator&apos;s account only under the condition that they follow yours would be included. It encompasses all content and actions that inflate popularity on the platform.
                    CELEBe prohibits all attempts to manipulate interaction metrics.
                </p>
                <p>
                    <u>Therefore, the following types of content are prohibited from being posted, uploaded, streamed, or shared. The list is not exhaustive and does not cover all cases:</u>
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>Videos that induce viewers to follow</p>
                    </li>
                    <li>
                        <p>&apos;Follow-for-follow&apos; videos</p>
                    </li>
                    <li>
                        <p>Videos that offer to give accounts to the 100,000th follower without any other content</p>
                    </li>
                    <li>
                        <p>Methods for artificially increasing views, likes, followers, shares, or comments</p>
                    </li>
                    <li>
                        <p>Buying or attempting to buy views, likes, followers, shares, or comments</p>
                    </li>
                    <li>
                        <p>Promotion of artificial traffic generation services</p>
                    </li>
                    <li>
                        <p>Operation of multiple anonymous or false CELEBe accounts for commercial spam distribution</p>
                    </li>
                </ul>
                <p>&nbsp;</p>
                <p>
                    <u>Comments spam</u>
                </p>
                <p>
                    CELEBe does not allow comments created solely for the purpose of collecting viewer&apos;s personal information or luring them to external sites with false information or engaging
                    in the prohibited actions described above. This type of spam also includes content whose sole purpose is to increase subscriber count, views, or other metrics. For example,
                    &quot;sub for sub&quot; content, where a viewer is proposed to subscribe to another creator&apos;s account as a condition for subscribing to the commenter&apos;s account, falls
                    under this category.
                </p>
                <p>
                    <u>Therefore, the following types of content are prohibited from being posted, uploaded, streamed, or shared. The list is not exhaustive and does not include all cases:</u>
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>Surveys or comments related to giveaways promoting multi-level marketing</p>
                    </li>
                    <li>
                        <p>Comments containing recommended links with a &quot;pay-per-click&quot; model</p>
                    </li>
                    <li>
                        <p>Comments falsely claiming to offer the entire video content, including movies, TV shows, and concerts</p>
                    </li>
                    <li>
                        <p>Posts containing links to harmful software or phishing sites, such as &quot;Get game money! [xyz phishing site].com.&rdquo;</p>
                    </li>
                    <li>
                        <p>Comments with links to counterfeit stores</p>
                    </li>
                    <li>
                        <p>Comments unrelated to the content of the posted video, such as &apos;Check out my account/video!&apos;</p>
                    </li>
                    <li>
                        <p>Repeatedly post the same comment with my account link</p>
                    </li>
                    <li>
                        <p>Repeatedly post comments with the same content or no obvious target</p>
                    </li>
                </ul>
                <p>&nbsp;</p>
                <p>
                    <u>Misleading thumbnails</u>
                </p>
                <p>CELEBe does not allow content that deceives users into misunderstanding the content&apos;s actual message by using misleading thumbnails, descriptions, or titles.</p>
                <p>
                    <u>Therefore, the following types of content are prohibited from being posted, uploaded, streamed, or shared. The list is not exhaustive and does not include all cases:</u>
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>Use celebrity photos unrelated to content for thumbnails</p>
                    </li>
                    <li>
                        <p>Content that uses thumbnails and descriptions to make users misunderstand the content of the content for something else</p>
                    </li>
                </ul>
                <p>&nbsp;</p>
                <p>
                    <u>Impersonation</u>
                </p>
                <p>CELEBe does not allow accounts that impersonate individuals or organizations.</p>
                <p>
                    After receiving a report of impersonation, if the impersonation is confirmed, the user will be asked to modify their profile or their account will be temporarily suspended or
                    banned.
                </p>
                <p>
                    For parody, commentary, or fan accounts, users are allowed to disclose that they are fans, commentators, or parodies in their profile and username, and state that their account is
                    unrelated to the targeted subject.
                </p>
                <p>
                    <u>Therefore, the following actions are prohibited:</u>
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>Misrepresenting oneself as another person or organization</p>
                    </li>
                </ul>

                <p className='title-3'>▶ Personal Information</p>
                <p>This is a text discussing the procedures for reporting personal information infringements and the factors considered when evaluating such reports.,</p>
                <p>The factors that are taken into account when assessing whether an individual can be uniquely identified are listed:</p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>Images or voice</p>
                    </li>
                    <li>
                        <p>Name</p>
                    </li>
                    <li>
                        <p>Financial information</p>
                    </li>
                    <li>
                        <p>Contact information</p>
                    </li>
                    <li>
                        <p>Other personal identifying information</p>
                    </li>
                </ul>
                <p>
                    It is not allowed to use a person&apos;s name or account name for the purpose of identity theft on CELEBe, and the platform exercises the rights of trademark owners. Content is not
                    allowed if the source of advertised products or services may be confused.
                </p>
                <p>
                    <u>Do not post content to CELEBe if any of the content described below applies.</u>
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>
                            Accounts that mimic another account by copying its profile, background, or overall design and atmosphere to make it look similar to another account. If the intention to
                            mimic another account is clear, it is considered account name theft, even if the two accounts are not identical.
                        </p>
                    </li>
                    <li>
                        <p>Content intended to make it appear as if it was posted by someone else.</p>
                    </li>
                </ul>
                <p>
                    <u>Therefore, the following content is prohibited from publishing, uploading, streaming, or sharing. The list is only partial and does not include all cases.</u>
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>
                            There is only a slight difference in the degree to which one space is inserted in the name of another account or the number 0 is used instead of the alphabetic O, but the
                            name and image are the same as other accounts
                        </p>
                    </li>
                    <li>
                        <p>Content that uses someone&apos;s real name, user name, image, brand, logo, or other personal information to pose as a person</p>
                    </li>
                    <li>
                        <p>Account description that matches exactly the other accounts</p>
                    </li>
                    <li>
                        <p>After setting up an account using the name and image of a particular person, the person most likely publishes content to this account</p>
                    </li>
                    <li>
                        <p>Set up an account using the name and image of a particular person and post comments to another account under the guise of that person</p>
                    </li>
                    <li>
                        <p>An account that claims to be a &apos;fan account&apos; but actually re-uploads the content of that account under the guise of another person&apos;s account</p>
                    </li>
                    <li>
                        <p>Account impersonating an existing news account</p>
                    </li>
                </ul>
                <p>&nbsp;</p>
                <p>
                    <u>Personal information or personal identification (PII)</u>
                </p>
                <p>CELEBe does not allow content that exposes personal information or personal identification (E.g. social security information).</p>
                <p>Content describing personal information or personal identification (PII) may be deleted.</p>
                <p>
                    <u>Therefore, the following content is prohibited from publishing, uploading, streaming, or sharing. The list is only partial and does not include all cases.</u>
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>Content including personal information or personal identification (PII)</p>
                    </li>
                </ul>

                <p className='title-3'>▶ Violation of intellectual property rights</p>
                <p>CELEBe recommends that all users create and share original content.</p>
                <p>Content that violates other people&apos;s intellectual property rights is prohibited on our platform, and related content will be deleted as soon as it is found.</p>
                <p>
                    <u>Therefore, the following content is prohibited from publishing, uploading, streaming, or sharing. The list is only partial and does not include all cases.</u>
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>Audiovisual works such as TV programs, movies, online videos, etc</p>
                    </li>
                    <li>
                        <p>Music and music works</p>
                    </li>
                    <li>
                        <p>Lectures, articles, books, musical works, etc</p>
                    </li>
                    <li>
                        <p>Visual works such as paintings, posters, advertisements, etc</p>
                    </li>
                    <li>
                        <p>Video games and computer software</p>
                    </li>
                    <li>
                        <p>Plays, musical, etc</p>
                    </li>
                    <li>
                        <p>Content that violates or infringes on other people&rsquo;s copyrights, trademarks, or other intellectual property rights</p>
                    </li>
                </ul>

                <p className='title-3'>▶ Platform Security</p>
                <p>Our policy prohibits all of the following activities that may compromise the security and reliability of CELEBe services.</p>
                <p>
                    <u>Therefore, the following actions are prohibited.</u>
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                    <li>
                        <p>Do not attempt to hack into CELEBe websites, apps, or associated networks or bypass measures to restrict user access.</p>
                    </li>
                    <li>
                        <p>Do not distribute files that contain viruses, Trojan horses (malicious code), worms, logical bombs, and other malicious or harmful content.</p>
                    </li>
                    <li>
                        <p>Do not modify, transform, translate, reverse design, dismantle, or reverse-compile CELEBe-based derivatives, including files, tables, or documents.</p>
                    </li>
                    <li>
                        <p>Do not attempt to play source code, algorithms, schemes, or techniques embedded in CELEBe.</p>
                    </li>
                    <li>
                        <p>Do not use automated scripts to collect information from CELEBe</p>
                    </li>
                </ul>
                <p>&nbsp;</p>
                <p>As a member of the active global platform CELEBe, we appreciate your cooperation in making CELEBe a welcome space for all users.</p>
                <p>If you find any content or accounts that you believe are in violation of the CELEBe guidelines, please let CELEBe know to review them and take appropriate action.</p>
            </div>
        </div>
    );
}
