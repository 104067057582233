import React, { useEffect, useState } from "react"
import { Routes, Route, useLocation } from "react-router-dom"
import Home from "../../pages/Home"
import About from "../../pages/About"
import WTE from "../../pages/WTE"
import FAQ from "../../pages/FAQ"
import Notice from "../../pages/Notice"
import Footer from "../Footer"
import Top from "../Top"
import PrivacyPolicy from "../../pages/PrivacyPolicy"
import TermsService from "../../pages/TermsService"
import CommunityGuideline from "../../pages/CommunityGuideline"
import PointGuide from "../../pages/PointGuide"
import Step1 from "../../pages/PointGuide/Step1"
import Step2 from "../../pages/PointGuide/Step2"
import Step3 from "../../pages/PointGuide/Step3"
import Step4 from "../../pages/PointGuide/Step4"
import Test from "../../pages/Test"
import Huobi1 from "../../pages/PointGuide/Step2/Huobi1"
import Meta1 from "../../pages/PointGuide/Step2/Meta1"
import Huobi2 from "../../pages/PointGuide/Step2/Huobi2"
import Huobi3 from "../../pages/PointGuide/Step2/Huobi3"
import Meta2 from "../../pages/PointGuide/Step2/Meta2"
import Meta3 from "../../pages/PointGuide/Step2/Meta3"
import AdvertisingInformation from "../../pages/AdvertisingInformation"
import PointGuideMain from "../../pages/PointGuideMain"
import Test2 from "../../pages/TestKakao"

export default function CelebeRoutes() {
    let location = useLocation()
    const [theme, setTheme] = useState("white")
    const displayNoneArray = [
        "/policy",
        "/terms",
        "/community-guideline",
        "/advertisement",
        "/point-guide",
        "/point-guide/0",
        "/point-guide/1",
        "/point-guide/2",
        "/point-guide/3",
        "/point-guide/4",
        "/point-guide/2/huobi/1",
        "/point-guide/2/huobi/2",
        "/point-guide/2/huobi/3",
        "/point-guide/2/meta/1",
        "/point-guide/2/meta/2",
        "/point-guide/2/meta/3",
    ]

    useEffect(() => {
        if (location && location.pathname === "/wte") {
            document.body.className = "black"
        } else if (location && displayNoneArray.includes(location.pathname)) {
            document.body.className = "none"
        } else {
            document.body.className = ""
        }
        window.scrollTo(0, 0)

        const uri = new URL(window.location)
        const theme = uri.searchParams.get("theme")
        if (theme && theme === "dark") {
            document.body.style.backgroundColor = "#161616"
            setTheme("dark")
        } else {
            setTheme("white")
        }
    }, [location])

    return (
        <div>
            {location && displayNoneArray.includes(location.pathname) ? null : <Top location={location} />}
            <div className={`body-wrap ${theme === "dark" ? "dark" : "white"}`}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    {/* <Route path="/about" element={<About />} /> */}
                    {/* <Route path="/wte" element={<WTE />} /> */}
                    {/* <Route path="/faq" element={<FAQ />} /> */}
                    <Route path="/notice" element={<Notice />} />
                    <Route path="/policy" element={<PrivacyPolicy />} />
                    <Route path="/terms" element={<TermsService />} />
                    <Route path="/advertisement" element={<AdvertisingInformation />} />
                    <Route path="/community-guideline" element={<CommunityGuideline />} />
                    <Route path="/point-guide" element={<PointGuideMain />} />
                    <Route path="/point-guide/0" element={<PointGuide />} />
                    <Route path="/point-guide/1" element={<Step1 />} />
                    <Route path="/point-guide/2" element={<Step2 />} />
                    <Route path="/point-guide/3" element={<Step3 />} />
                    <Route path="/point-guide/4" element={<Step4 />} />
                    <Route path="/point-guide/2/huobi/1" element={<Huobi1 />} />
                    <Route path="/point-guide/2/huobi/2" element={<Huobi2 />} />
                    <Route path="/point-guide/2/huobi/3" element={<Huobi3 />} />
                    <Route path="/point-guide/2/meta/1" element={<Meta1 />} />
                    <Route path="/point-guide/2/meta/2" element={<Meta2 />} />
                    <Route path="/point-guide/2/meta/3" element={<Meta3 />} />
                    {/* <Route path="/callback" element={<About />} /> */}
                    {/* <Route path="/test" element={<Test />} /> */}
                    {/* <Route path="/test2" element={<Test2 />} /> */}
                </Routes>
            </div>
            {location && displayNoneArray.includes(location.pathname) ? null : <Footer location={location} />}
        </div>
    )
}
