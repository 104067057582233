import React, { useEffect, useState } from 'react';
import CommunityGuidelineKo from './CommunityGuidelineKo';
import CommunityGuidelineEn from './CommunityGuidelineEn';
import './styles.scss';

export default function CommunityGuideline() {
    const [lang, setLang] = useState('ko');
    const [theme, setTheme] = useState('dark');
    useEffect(() => {
        const uri = new URL(window.location);
        const lang = uri.searchParams.get('lang');
        if (lang) setLang(lang);
        const theme = uri.searchParams.get('theme');
        if (theme && theme === 'dark') {
            setTheme('dark');
        } else {
            setTheme('white');
        }
    }, []);
    return <div className='guideline-container'>{lang === 'ko' ? <CommunityGuidelineKo theme={theme} /> : <CommunityGuidelineEn theme={theme} />}</div>;
}
