import { useEffect, useState } from "react"

export default function useGetPolicyDate({ lang }) {
    const [optionDates, setOptionDates] = useState([""])

    useEffect(() => {
        switch (lang) {
            case "ko":
                setOptionDates(["221103", "231113", "231211", "240725"])
                break
            case "th":
                break
            case "en":
            default:
                setOptionDates(["221103", "231113", "231211", "240725"])
        }
    }, [lang])

    const dateNumbers = optionDates.map((option) => Number(option)).filter(Boolean)

    const latestDate = String(Math.max(...dateNumbers))

    return { optionDates, latestDate }
}
