import { useEffect, useState } from "react";

import step4 from "../../../assets/point-guide/step4_KOR.png";
import PHstep4 from "../../../assets/point-guide/ph/step4_PH.png";
import INstep4 from "../../../assets/point-guide/in/step4_IN.png";
import INHINDIstep4 from "../../../assets/point-guide/in/step4_IN_HINDI.png";
import THstep4 from "../../../assets/point-guide/th/step4_TH.png";
import VNstep4 from "../../../assets/point-guide/vn/step4_VN.png";
import IDstep4 from "../../../assets/point-guide/id/step4_ID.png";

export default function Step4() {
    const [country, setCountry] = useState("ko");
    const [lang, setLang] = useState("ko");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const uri = new URL(window.location);

        const langValue = uri.searchParams.get("lang");
        if (langValue) setLang(langValue);

        const countryValue = uri.searchParams.get("country");
        if (countryValue) setCountry(countryValue);

        setLoading(true);
    }, []);

    const getImageSrcByCountryAndLang = (country, lang) => {
        if (country === "in" && lang === "hi") {
            return INHINDIstep4;
        }

        const countryImageMap = {
            ko: step4,
            ph: PHstep4,
            in: INstep4,
            th: THstep4,
            vn: VNstep4,
            id: IDstep4,
        };

        return countryImageMap[country] || step4;
    };

    const imageSrc = getImageSrcByCountryAndLang(country, lang);

    return loading ? (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <img src={imageSrc} style={{ width: "100%", maxWidth: "800px", height: "auto", marginBottom: "-0.5px" }} alt={""} />
        </div>
    ) : (
        <div></div>
    );
}
