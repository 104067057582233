import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import swapImage from "../../assets/point-guide/swap-image.png";

export default function PointGuideMain() {
    const navigate = useNavigate();

    const [countryArray, setCountryArray] = useState([
        {
            name: "🇰🇷 Korea",
            url: "/point-guide/0?country=ko&lang=ko",
        },
        {
            name: "🇹🇭 Thailand",
            url: "/point-guide/0?country=th&lang=th",
        },
        {
            name: "🇮🇩 Indonesia",
            url: "/point-guide/0?country=id&lang=id",
        },
        {
            name: "🇻🇳 Vietnam",
            url: "/point-guide/0?country=vn&lang=vn",
        },
        {
            name: "🇵🇭 Philippines",
            url: "/point-guide/0?country=ph&lang=ph",
        },
        {
            name: "🇮🇳 India (eng)",
            url: "/point-guide/0?country=in&lang=en",
        },
        {
            name: "🇮🇳 India (hindi)",
            url: "/point-guide/0?country=in&lang=hi",
        },
    ]);

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "#735EF1", maxWidth: "800px", height: "100vh", margin: "0 auto", paddingTop: "20px" }}>
            <img src={swapImage} alt={""} style={{ width: 150, height: 150 }} />
            {countryArray.map((item, index) => (
                <div
                    key={index}
                    style={{
                        width: "90%",
                        margin: "10px auto",
                        borderRadius: "8px",
                        backgroundColor: "#fff",
                        boxShadow: "0px 5px 5px 0px rgba(0,0,0,0.2)",
                        fontSize: "20px",
                        padding: "10px 5px",
                        textAlign: "center",
                    }}
                    onClick={() => navigate(item.url)}
                >
                    <span style={{ fontFamily: "Pretendard", cursor: "pointer" }}>{item.name}</span>
                </div>
            ))}
        </div>
    );
}
