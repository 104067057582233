import React from "react";

export default function KoPolicy231016({ theme }) {
  return (
    <div className={`policy ${theme === "dark" ? "dark" : ""}`}>
      <p className="title-1">개인정보 처리방침</p>
      <p className="">최종 업데이트 일자: 2023년 10월 16일</p>
      <p>발효일: 2021년 7월 2일</p>
      <p>셀러비(이하 “본 플랫폼”)에 방문하신 것을 환영합니다.</p>
      <p>
        본 플랫폼은 CELEBE KOREA CO., LTD (이하 “셀러비” 혹은 “당사”)가 제공하고
        관리합니다.
      </p>
      <p>
        당사는 귀하의 개인정보를 보호하고 존중하기 위해 최선의 노력을 다하고
        있습니다. 본 개인정보 처리방침(이하 “본 정책”)은 당사가 귀하로부터
        수집하거나 귀하가 당사에게 제공하는 개인정보에 관한 당사의 처리 방식을
        설명합니다. 본 정책에 동의하지 않는 경우, 귀하는 본 플랫폼을 이용하지
        않아야 합니다.
      </p>
      <p>
        당사가 귀하의 개인정보를 처리하는 방식에 대해 질문이 있으신 경우{" "}
        <b
          onClick={() => {
            window.location.href = "celebe://deeplink/qna";
          }}
        >
          셀러비앱 Q&A
        </b>
        을 통해 연락하시기 바랍니다.
      </p>
      <p className="title-2">요약</p>
      <p className="title-3">당사가 어떠한 개인정보를 수집하는지</p>
      <p>
        당사는 귀하가 계정을 생성하고 본 플랫폼에 콘텐츠를 업로드할 때 당사에게
        제공하는 정보를 수집하고 처리합니다. 전술한 정보에는 귀하의 본 플랫폼
        이용과 관련된 기술 및 행태정보가 포함됩니다. 아울러, 당사는 귀하가
        계정을 생성하지 않은 상태에서 애플리케이션(이하 “앱”)을 다운로드하고 본
        플랫폼과 상호작용을 하는 경우에도 쿠키를 통하여 귀하에 대한 비식별
        정보를 수집합니다.
      </p>
      <p className="title-3">귀하의 개인정보를 어떻게 이용하는지</p>
      <p>
        당사는 본 플랫폼을 귀하에게 제공하고, 나아가 본 플랫폼을 개선하고
        관리하기 위해 귀하의 정보를 활용합니다. 당사는 무엇보다도 귀하에게
        피드를 추천을 하고, 본 플랫폼을 개선하고 개발하며, 귀하의 안전을
        보장하기 위한 목적으로 귀하의 정보를 이용합니다. 적절한 경우, 당사는
        귀하에게 맞춤형 광고를 집행하고 본 플랫폼을 홍보하기 위해 귀하의
        개인정보를 이용합니다.
      </p>
      <p className="title-3">당사가 누구와 귀하의 정보를 공유하는지</p>
      <p>
        당사는 본 플랫폼을 제공함에 있어 도움을 주는 제3자, 즉 클라우드 서비스
        제공사와 같은 제3자와 귀하의 정보를 공유합니다. 또한, 당사의 사업
        파트너, 셀러비와 동일한 기업집단에 속하는 회사들, 콘텐츠 심의 서비스
        제공사, 측정 서비스 제공사, 광고주 및 분석 서비스 제공사와 귀하의 정보를
        공유합니다. 단 광고주에게 귀하를 식별할 수 있는 정보는 제공하지
        않습니다. 법률에 따라 요구되는 경우, 당사는 법적으로 구속력 있는
        법원명령에 따라, 사법기관, 규제당국 및 제3자와 귀하의 정보를 공유합니다.
      </p>
      <p className="title-3">당사가 귀하의 정보를 보유하는 기간</p>
      <p>
        당사는 서비스 제공을 위하여 필요한 기간 동안 귀하의 정보를 보유합니다.
        귀하에게 서비스를 제공하기 위하여 귀하의 정보를 보유할 필요가 없어지면,
        당사는 해당 정보를 보관할 적법한 사업목적이 있거나 당사에게 해당 정보를
        보유할 법적 의무가 있는 기간 동안에 한하여 귀하의 정보를 보유합니다.
        또한, 당사는 귀하의 정보가 법적 청구의 성립, 행사 또는 방어를 위해
        필요하거나 필요할 것으로 판단되는 경우 귀하의 정보를 보유합니다.
      </p>
      <p className="title-3">
        당사가 본 정책의 변경사항을 귀하에게 고지하는 방법
      </p>
      <p>
        일반적으로 당사는 본 플랫폼 상에서의 공지를 통하여 본 정책의 주요
        변경사항을 모든 이용자에게 통지합니다. 단, 귀하는 본 정책을 정기적으로
        검토하여 변경사항을 확인해야 합니다. 당사는 본 정책의 효력발생일을
        반영한 “최근 개정” 일자 또한 본 정책의 상단에 업데이트합니다. 귀하는 본
        플랫폼에 접속하거나 본 플랫폼을 이용함으로써 귀하가 본 정책을 숙지하였고
        귀하의 개인정보와 관련된 귀하의 권리 및 당사가 귀하의 개인정보를
        수집•이용 및 처리하는 방식을 이해함을 인정합니다.
      </p>
      <p className="title-2">1. 당사가 이용하는 개인정보의 유형</p>
      <p>당사가 수집하고 이용하는 귀하에 대한 정보는 다음과 같습니다.</p>
      <p className="title-3">귀하의 프로필 정보.</p>
      <p>
        귀하는 본 플랫폼에 가입하는 시점의 정보를 당사에게 제공하며, 이용자명,
        비밀번호, (해당하는 경우에만)생년월일, 이메일 주소, 귀하가 이용자
        프로필에서 공개하는 정보 및 귀하의 사진 또는 프로필 비디오 등이 이에
        해당합니다.
      </p>
      <p className="title-3">이용자 콘텐츠 및 행태 정보.</p>
      <p>
        당사는 귀하가 본 플랫폼에서 생성한 콘텐츠를 처리하며, 처리 대상
        콘텐츠에는 귀하가 설정한 선호사항(언어 선택 등), 귀하가 업로드한 사진,
        비디오 및 귀하의 댓글이 포함됩니다(이하 “이용자 콘텐츠”). 당사는 귀하가
        참여하는 설문, 챌린지, 대회를 통해 정보를 수집합니다. 아울러, 당사는
        귀하의 본 플랫폼 이용과 관련된 정보, 즉 귀하가 본 플랫폼에 참여하는
        방식에 대한 정보를 수집하며, 당사가 귀하에게 보여주는 콘텐츠와 귀하가
        상호작용하는 방식, 귀하가 보는 광고 및 비디오, 귀하가 겪는 문제, 귀하가
        좋아한 콘텐츠, 귀하가 저장한 콘텐츠 및 귀하가 팔로잉하는 이용자 등이
        이에 포함됩니다. 또한, 당사는 귀하에게 맞춤형 콘텐츠를 제공하기 위하여
        귀하의 관심사, 성별 등 귀하의 선호를 유추합니다. 당사는 귀하의 콘텐츠를
        다른 이용자에게 홍보하고 귀하의 프로필이 추가 협업 기회를 제공하는지
        탐색하기 위하여 귀하의 팔로워 관련 정보 및 업로드한 콘텐츠에 대해 귀하가
        받은 ‘좋아요’와 반응에 대한 정보를 처리합니다. 적절한 경우, 당사는
        귀하에게 맞춤형 광고를 제공하고 새로운 서비스 및 기회를 안내하기 위하여
        전술한 정보를 이용합니다.
      </p>
      <p className="title-3">제3자로부터 제공받는 정보.</p>
      <p>
        귀하는 제3자로 하여금, 또는 다른 플랫폼을 통하여, 특정 정보를 당사와
        공유하도록 선택할 수 있고, 귀하가 본 플랫폼을 이용함으로써 당사가
        자동적으로 제3자로부터 정보를 수집할 수 있습니다. 당사는 아래와 같이
        제3자로부터 제공받는 정보에 관하여 상세히 규정하고 있습니다.
      </p>
      <p className="title-3">사업 파트너</p>
      <p>
        귀하가 귀하의 소셜네트워크 계정 정보를 이용하여 본 플랫폼을 이용한다고
        등록하는 경우, 귀하는 귀하의 이용자명 및 공개 프로필을 당사에게 제공하게
        되거나 귀하의 소셜네트워크가 동 정보를 당사에게 제공하는 것을 허용하게
        됩니다. 또한, 당사는 귀하의 앱 ID, 액세스 토큰 및 참조 URL과 같은 특정
        정보를 귀하의 소셜네트워크와 공유합니다.
      </p>
      <p className="title-3">광고주 및 광고 네트워크</p>
      <p>
        당사는 귀하에게 본 플랫폼을 무료로 제공하기 위하여 수시로 당사의
        플랫폼에 맞춤형 광고를 게시할 수 있습니다. 당사는 귀하의 관심사를 최대한
        유추하여 보다 연관성이 높은 광고를 제공하기 위해 귀하로부터 수집한
        정보와 더불어 귀하와 본 플랫폼 및 다른 제3자의 사이트 간 상호작용에 관한
        정보를 이용합니다. 당사는 전술한 정보를 통해 귀하가 방문한 웹사이트,
        귀하가 다운로드한 앱, 귀하의 구입내역 등을 파악하여 귀하의 향후 관심사를
        예측하고, 당사의 플랫폼에서의 광고가 얼마나 효과적인지를 평가합니다.
        당사는 쿠키 및 당사 앱 상의 유사한 기술을 이용하여 당사의 플랫폼에서
        광고를 하고 귀하가 방문하는 사이트를 운영하는 제3자로부터 수령한 유사한
        정보를 이용하여 전술한 정보를 수집합니다.
      </p>
      <p className="title-3">귀하와 관련하여 수집하는 기술정보.</p>
      <p>
        당사는 귀하가 계정을 생성하지 않고 앱을 이용하는 경우를 포함하여 귀하가
        본 플랫폼을 이용할 때에 귀하로부터 특정 정보를 자동으로 수집합니다. 수집
        대상 정보에는 귀하의 IP주소, 브라우징 히스토리(즉, 귀하가 본 플랫폼에서
        본 콘텐츠), 이동통신사, 시간대 설정, 광고용 식별자 및 귀하가 이용중인 앱
        버전 등이 포함됩니다. 또한, 귀하의 기기 모델, 기기 운영체제, 네트워크
        유형, 기기 ID, 스크린 해상도, MCC 및 운영체계와 같이 귀하가 본 플랫폼에
        접속하기 위해 사용 중인 기기에 대한 정보 또한 포함됩니다. 귀하가 복수의
        기기를 사용하여 로그인 하는 경우, 당사는 복수의 기기상에서의 귀하의
        활동을 식별하기 위해 귀하의 프로필 정보를 이용할 수 있습니다.
      </p>
      <p className="title-3">위치정보.</p>
      <p>
        당사는 위치정보의 보호 및 이용 등에 관한 법률에 따라 다음과 같이
        개인위치정보를 처리합니다. 귀하에게 최적화된 맞춤형 콘텐츠나 광고를
        제공하기 위해 귀하의 동의를 받아 GPS기반 위치정보를 수집할 수 있습니다.
        수집된 귀하의 위치정보는 귀하에게 적절한 셀러비 경험을 제공합니다.
        귀하가 단말의 설정에서 위치정보의 송신을 허가하지 않는 경우에 위치정보는
        송신되지 않습니다. 다만 회사는 고객이 위치정보 송신을 허가하고 있지 않은
        경우에 있어서도 IP주소 등의 네트워크 정보에서 고객의 대략적 위치를
        추정하는 경우가 있을 수 있습니다. 이용자의 사전 동의 없이 개인위치정보를
        제3자에게 제공하지 않습니다.
      </p>
      <p className="title-3">다른 이용자 찾기 및 친구 초대.</p>
      <p>
        귀하는 ‘친구 찾기’ 기능을 이용하여 본 플랫폼에서 다른 이용자를 찾을지
        여부를 선택할 수 있습니다. ‘친구 찾기’ 기능을 통해 귀하는 귀하의 휴대폰
        연락처나 SNS 친구 목록에 있는 친구들 중 본 플랫폼을 이용하고 있는 친구를
        찾아 팔로잉할 수 있습니다. 또한, 귀하는 본 기능을 이용하여 연락처상
        친구를 본 플랫폼에 가입하도록 초대할 수 있습니다. 관할지역에 따라,
        당사는 귀하가 보유한 연락처 정보를 이용하여 귀하로부터 초대를 받은
        사람에게 귀하의 휴대폰 연락처 목록 내에서 귀하의 셀러비 프로필을
        열람하도록 초대하는 SMS, 이메일, 혹은 제3자 SNS 메시지를 보낼 수 있는
        옵션을 제공합니다.
      </p>
      <p className="title-3">포인트 구매.</p>
      <p>
        귀하가 앱 내 포인트 구매 기능을 제공하는 특정 관할지역에 거주하는 경우,
        당사의 가상아이템정책(Virtual Items Policy)을 숙지하여 주시기 바랍니다.
        귀하의 구매는 귀하의 Apple iTunes 계정 또는 Google Play 계정을 통해
        이루어집니다. 해당 거래와 관련하여, 당사는 귀하로부터 금융정보나
        결제정보를 수집하지 않습니다. 해당 정보의 취급에 대해서는 각 앱스토어의
        약관 및 통지를 검토하시기 바랍니다. 이를 통해 당사는 귀하의 계정에
        정확한 금액의 포인트를 입금할 수 있고, 귀하의 구매내역, 구매 시점 및
        사용금액을 기록합니다.
      </p>
      <p className="title-3">쿠키</p>
      <p>
        당사, 당사의 벤더 및 서비스 제공사는 쿠키 및 그 외 유사한 기술(예: 웹
        비콘(web beacons), 플래시 쿠키(flash cookies) 등)(이하 “쿠키”)를
        이용하여 정보를 자동으로 수집하고, 귀하가 클릭한 웹페이지 및 귀하가 본
        플랫폼을 이용하는 방식을 측정하고 분석하며, 귀하의 본 플랫폼 이용 경험을
        개선하고, 당사의 서비스를 개선하고, 본 플랫폼 및 귀하의 각 기기상에서
        귀하에게 맞춤형 광고를 제공합니다. 쿠키는 본 플랫폼이 특정 기능을 제공할
        수 있도록 하는 작은 파일이며, 귀하의 기기에 설치되어 있습니다. 웹 비콘은
        이미지 내에 삽입된 매우 작은 이미지나 데이터로서 “픽셀 태그(pixel
        tag)”나 “클리어(clear) GIF”라고도 하며, 쿠키, 페이지를 본 시간 및 날짜,
        픽셀 태그가 설치된 페이지의 설명 및 귀하의 컴퓨터나 기기로부터 수집된
        이와 유사한 정보를 인식합니다. 귀하는 본 플랫폼을 이용함으로써 쿠키
        이용에 동의하게 됩니다.
      </p>
      <p>
        또한, 당사는 당사의 사업 파트너, 광고 네트워크 및 기타 광고 벤더 및
        서비스 제공사(분석 벤더 및 서비스 제공사 포함)가 쿠키를 통해 귀하의
        온라인 활동에 대한 정보를 수집하는 것을 허용합니다. 이와 같은 제3자는
        당사의 플랫폼 및 온라인상에서 귀하의 관심사, 선호 및 특징에 맞추어
        광고를 게재하기 위해 이러한 정보를 이용할 수 있습니다. 당사는 전술한
        제3자의 개인정보 처리에 대해 책임이 없으며, 본 정책에서는 제3자의
        정보처리 실무를 다루지 않습니다.
      </p>
      <p>
        귀하는 귀하의 브라우저 설정을 조정하여 쿠키를 거부하거나 비활성화할 수
        있습니다. 각 브라우저가 상이하기 때문에, 귀하의 브라우저가 제공하는
        지시사항이나 설명을 참고하시기 바랍니다. 특정 유형의 쿠키를 거부하거나
        비활성화하려면 추가 조치가 필요할 수 있습니다. 예를 들어, 브라우저와
        모바일 앱이 기능하는 방식에 차이가 있으므로 브라우저상의 맞춤광고에
        이용되는 쿠키를 차단하거나 모바일 앱상 맞춤광고를 차단하기 위해서는
        상이한 조치를 취해야 할 수 있으며, 이러한 경우 귀하의 기기설정이나
        모바일 앱 허용 권한을 조정하여 조치를 취할 수 있습니다. 또한, 귀하의
        차단 선택은 귀하가 차단 시 사용하는 특정 브라우저나 기기에 한하여
        적용되므로, 각 브라우저나 기기에 대해 별도의 차단 조치가 필요할 수
        있습니다. 쿠키를 거부하거나 비활성화하거나 삭제하는 경우, 본 플랫폼의
        일부 기능이 귀하에게 제공되지 않을 수 있습니다.
      </p>
      <p className="title-2">2. 귀하의 개인정보를 이용하는 방식</p>
      <p>
        당사는 당사가 귀하에 대해 수집하는 정보를 다음과 같은 방식으로
        이용합니다.
      </p>
      <p>당사 서비스의 변경 사항을 귀하에게 통보하기 위하여</p>
      <p>귀하에게 이용자 지원을 제공하기 위하여</p>
      <p>
        귀하가 제공받는 콘텐츠를 개인화하고, 귀하의 관심사에 해당하는 맞춤형
        콘텐츠를 귀하에게 제공하기 위하여
      </p>
      <p>
        귀하가 다른 이용자들과 이용자 콘텐츠를 공유하고 상호작용할 수 있도록
        하기 위하여
      </p>
      <p>
        귀하가 메신저 서비스를 이용하기로 선택한 경우, 당사의 메신저 서비스를
        활성화하기 위하여
      </p>
      <p>귀하가 가상 아이템 프로그램에 참여할 수 있도록 하기 위하여</p>
      <p>귀하와 의사소통하기 위하여</p>
      <p>
        본 플랫폼의 남용, 본 플랫폼상의 사기행위 및 불법행위를 감지하기 위하여
      </p>
      <p>
        당사의 커뮤니티 가이드라인 (Community Guidelines)을 위반하는 이용자
        콘텐츠, 메시지 및 관련 메타데이터, 그리고 그 외 부적절한 콘텐츠를
        검토하여 귀하의 안전 및 보안을 보호하기 위하여
      </p>
      <p>
        콘텐츠가 귀하 및 귀하의 기기에 가장 효과적인 방식으로 제공되도록
        조치하기 위하여
      </p>
      <p>
        본 플랫폼을 개선, 홍보 및 개발하고 본 플랫폼상의 인기 토픽, 해시태그 및
        캠페인을 홍보하기 위하여
      </p>
      <p>
        안정성 및 보안을 보장하기 위해 데이터 분석을 실행하고 본 플랫폼에 대한
        테스트를 진행하기 위하여
      </p>
      <p>귀하가 본 플랫폼의 상호작용 기능을 이용할 수 있도록 허용하기 위하여</p>
      <p>
        다른 이용자들이 “다른 친구 찾기” 기능이나 휴대폰 연락처를 통해 귀하를
        찾을 수 있게 하는 등 귀하가 본 플랫폼에서 타인과 교류할 수 있도록 하기
        위하여
      </p>
      <p>
        (법률상 요구되는 경우) 귀하가 본 플랫폼을 이용할 수 있는 적정 연령인지
        확인하기 위하여
      </p>
      <p>귀하에게 맞춤형 광고를 제공하기 위하여</p>
      <p>
        (귀하의 관할지역에서 위치기반 서비스가 제공되는 경우) 귀하에게 위치기반
        서비스를 제공하기 위하여
      </p>
      <p>당사의 약관 및 정책을 집행하기 위하여</p>
      <p>장애처리를 포함하여 본 플랫폼을 관리하기 위하여</p>
      <p className="title-2">3. 귀하의 개인정보를 공유하는 방식</p>
      <p>
        당사는 다음과 같은 제3자와 귀하의 정보를 공유하나, 공유의 범위는 귀하가
        거주하는 관할지역에 따라 달라질 수 있습니다.
      </p>
      <p className="title-3">사업 파트너</p>
      <p>
        귀하가 귀하의 소셜네트워크 계정 정보를 이용하여 본 플랫폼에 등록하는
        경우, 귀하는 귀하의 이용자명 및 공개된 프로필을 당사에게 제공하거나
        귀하의 소셜네트워크가 정보를 당사에게 제공하는 것을 허용하게 됩니다.
        또한, 당사는 귀하의 앱 ID, 액세스 토큰 및 참조 URL(referring URL)과 같은
        특정 정보를 귀하의 소셜네트워크와 공유합니다.
      </p>
      <p>
        귀하가 타 소셜미디어 플랫폼에 콘텐츠를 공유하기로 선택하는 경우, 영상,
        이용자명 및 이에 수반되는 글자가 해당 플랫폼에 공유되며, 인스턴트
        메신저를 통해 공유하는 경우에는 콘텐츠로 연결되는 링크가 공유됩니다.
      </p>
      <p className="title-3">지급결제서비스 제공사</p>
      <p>
        귀하가 포인트 구매를 선택하는 경우, 필요에 따라 당사는 귀하의 동의를
        받아 거래를 용이하게 하기 위하여 지급결제서비스 제공사와 귀하의 정보를
        공유합니다. 당사는 귀하를 식별하고, 귀하가 지급을 함과 동시에 정확한
        금액의 포인트을 귀하의 계정에 입금하기 위해 거래 ID를 공유합니다.
      </p>
      <p className="title-3">서비스 제공사</p>
      <p>
        당사는 클라우드 서비스 제공사 및 콘텐츠 심의 서비스 제공사 등 본
        플랫폼이 안전하고 즐거운 장소가 되도록 당사의 사업을 지원하는 서비스
        제공사에게 정보 및 콘텐츠를 제공합니다.
      </p>
      <p className="title-3">분석 서비스 제공사</p>
      <p>
        당사는 본 플랫폼의 최적화 및 개선을 돕는 분석 서비스 제공사를 활용수
        있습니다. 제3자인 분석 서비스 제공사는 맞춤형 광고에 관한 지원도
        당사에게 제공할 수 있습니다.
      </p>
      <p className="title-3">광고주 및 광고 네트워크</p>
      <p>
        당사는 광고를 보거나 클릭한 본 플랫폼 이용자의 수와 그 유형을 보여주기
        위해 광고주 및 제3자인 측정 서비스 제공사와 정보를 공유합니다. 단,
        귀하를 식별할 수 있는 정보는 공유하지 않습니다. 당사는 측정 서비스
        제공사와 귀하의 기기 ID를 공유하여 귀하의 본 플랫폼상 활동을 다른
        웹사이트에서의 귀하의 활동과 연결하고, 그 후 귀하의 관심사에 해당할 수
        있는 광고를 귀하에게 보여주기 위하여 전술한 정보를 이용합니다.
      </p>
      <p className="title-3">셀러비코리아 기업집단</p>
      <p>
        당사는 본 플랫폼의 개선 및 최적화를 포함하여 불법 이용을 방지하고
        이용자를 지원하며 본 플랫폼의 최적화, 개선, 기타 제공하기 위하여
        셀러비코리아 기업집단 내 다른 구성원, 자회사 또는 계열사와 귀하의 정보를
        공유할 수 있습니다.
      </p>
      <p className="title-3">법률의 집행</p>
      <p>
        당사는 법적으로 요구되거나 다음과 같은 목적을 위하여 합리적으로 요구되는
        경우 귀하의 정보를 사법기관, 공공기관 또는 기타 기관과 공유합니다:
      </p>
      <p className="title-3">법적 의무, 절차 또는 요청을 준수하기 위하여</p>
      <p>
        위반 가능성에 대한 조사를 포함한 당사의 서비스약관 및 기타 계약, 정책 및
        기준의 집행을 위하여 보안, 사기 또는 기술적 문제의 감지, 방지 또는
        처리를 위하여 법률에 따라 요구되거나 허용되는 바에 따라 당사, 당사의
        이용자, 제3자 및 공공의 권리, 재산 내지 안전을 보호하기 위하여(사기 방지
        및 신용위험 감소를 위하여 다른 회사 및 기관과 정보를 교환하는 것 포함)
      </p>
      <p className="title-3">공개 프로필</p>
      <p>
        귀하의 프로필이 공개된 상태라면 귀하의 콘텐츠가 본 플랫폼상의 누구에게나
        보일 수 있고, 나아가 귀하의 친구나 팔로워 뿐만 아니라 검색 엔진, 콘텐츠
        통합관리자(content aggregator) 및 뉴스 사이트가 귀하의 콘텐츠에
        접속하거나 귀하의 콘텐츠를 공유할 수 있음을 참고하여 주시기 바랍니다.
        귀하는 각 영상을 업로드 할 때 해당 영상을 볼 수 있는 권한이 있는 사람을
        변경할 수 있으며, 귀하는 설정에서 ‘개인계정’의 설정을 변경하여 프로필
        공개에 관한 기본 설정을 비공개로 변경할 수 있습니다.
      </p>
      <p className="title-3">매각 또는 합병</p>
      <p>
        당사는 법률에서 규정하는 바에 따라 귀하의 동의를 받거나 귀하에게 통지를
        한 후, 아래와 같이 귀하의 정보를 제3자에게 공개할 수 있습니다.
      </p>
      <p>
        (청산, 파산 또는 기타 이유를 불문하고) 당사가 여하한 사업 또는 자산을
        매각하거나 매입하는 경우, 당사는 해당 사업 또는 자산의 잠재적 매수인이나
        매도인에게 귀하의 정보를 공개합니다. 당사가 매각, 매입, 합병되거나 다른
        회사 또는 사업에 의해 인수되거나 다른 회사 또는 사업과 파트너 관계가
        되는 경우, 혹은 당사의 자산 전부 또는 일부를 매각하는 경우, 이용자
        정보가 해당 거래에 기하여 이전되는 자산에 포함될 수 있습니다.
      </p>
      <p className="title-3">귀하의 권리</p>
      <p>
        귀하는 셀러비에 가입함에 따라 귀하의 프로필 정보 대부분에 접속하고 이를
        편집할 수 있습니다. 귀하는 귀하가 업로드한 이용자 콘텐츠를 삭제할 수
        있습니다. 또한, 당사는 귀하가 조정할 수 있는 여러 가지 설정 툴을
        제공하며, 귀하는 귀하의 영상을 볼 수 있는 자, 귀하에게 메시지를 보낼 수
        있는 자, 귀하의 영상에 댓글을 남길 수 있는 자 등을 통제할 수 있습니다.
        원하는 경우, 귀하는 설정에서 귀하의 계정을 전부 삭제할 수 있습니다. 툴
        사용법에 대해 질문이 있거나 귀하의 거주 국가에서 귀하가 가지는 권리에
        대해 알고 싶으신 경우, 셀러비 앱 Q&A를 통해 연락하시기 바랍니다.
      </p>
      <p className="title-2">5. 개인정보에 대한 보안</p>
      <p>
        당사는 귀하의 정보가 본 정책에 따라 안전하게 처리되도록 조치를 취합니다.
        다만, 유감스럽게도 인터넷을 통한 정보 전송은 보안이 완전히 보장되지
        않습니다. 당사는 암호화 등의 방법으로 귀하의 개인정보를 보호할 것이나,
        본 플랫폼을 통해 전송되는 귀하의 정보에 대한 보안을 보장할 수 없습니다.
        정보 전송의 위험에 따른 책임은 귀하가 부담합니다.
      </p>
      <p>
        당사는 다양한 위험의 가능성, 귀하 및 다른 이용자의 권리 및 자유의
        심각성과 위험성에 상응하는 수준의 보안을 갖추기 위해 적절한 기술적,
        관리적 조치를 마련하고 있습니다. 당사는 기술적, 관리적 조치를 유지하며,
        해당 조치를 수시로 보완하여 당사 시스템의 전반적인 보안을 개선할
        것입니다.
      </p>
      <p>
        당사의 플랫폼은 당사 파트너의 네트워크, 광고주 및 관계사의 웹사이트로
        연결되는 링크 또는 동 웹사이트로부터의 링크를 포함합니다. 귀하가 해당
        웹사이트로 연결되는 링크를 따라가는 경우 해당 웹사이트가 자체 개인정보
        처리방침을 보유하고 있으며 당사는 해당 정책에 대하여 책임을 지지
        않는다는 점을 참고하여 주시기 바랍니다. 해당 웹사이트에 정보를
        제공하기에 앞서 해당 웹사이트의 정책을 확인하시기 바랍니다.
      </p>
      <p className="title-2">6. 개인정보 보유기간</p>
      <p>
        당사는 귀하에게 서비스를 제공하기 위하여 2년간 귀하의 정보를 보유합니다.
        귀하에게 서비스를 제공하기 위한 목적으로 귀하의 정보를 보유할 필요가
        없어지면, 당사는 해당 정보를 보유할 적법한 사업목적이 존재하는 기간에
        한하여 귀하의 정보를 보유합니다. 단, 당사의 법적 의무의 이행 또는 당사의
        법적 청구의 성립, 행사 또는 방어를 위하여 당사가 전술한 기간을 초과하여
        귀하의 정보를 보유할 수 있는 경우가 있을 수 있습니다.
      </p>
      <p className="title-2">7. 아동 정보</p>
      <p>
        셀러비는 만 14세 미만의 아동을 대상으로 하지 않습니다. 현지 규제로
        인하여 만 14세보다 더 높은 연령 제한이 적용되는 경우도 있으므로,
        귀하에게 적용되는 현지 개인정보 처리방침에서 자세한 정보를 확인하시기
        바랍니다. 당사가 기준 연령 미만의 아동에 대한 개인정보를 보유하거나
        수집했다고 의심되는 경우 셀러비 앱 Q&A 를 통해 연락하시기 바랍니다.
      </p>
      <p className="title-2">8. 이용자 불만</p>
      <p>
        당사가 귀하의 개인정보를 처리하는 방식에 대해 불만을 제기하고자 하는
        경우,{" "}
        <b
          onClick={() => {
            window.location.href = "celebe://deeplink/qna";
          }}
        >
          셀러비 앱 문의하기
        </b>
        를 통해 먼저 당사에 연락하시면 귀하의 요청을 최대한 신속하게 처리할 수
        있도록 노력하겠습니다. 전술한 사항은 귀하가 관련 개인정보 보호
        규제기관에 민원을 제기할 수 있는 귀하의 권리를 침해하지 않습니다.
      </p>
      <p className="title-2">9. 변경</p>
      <p>
        일반적으로 당사는 본 플랫폼을 통해 공지를 하는 방식으로 본 정책의 주요
        변경사항을 모든 이용자에게 통지합니다. 단, 귀하는 변경사항을 확인하기
        위해 본 정책을 정기적으로 확인해야 합니다. 당사는 본 정책의 효력발생일을
        반영한 “최근 개정”일자 또한 본 정책의 상단에 업데이트합니다. 귀하가
        정책의 개정 일자 이후에 본 플랫폼에 계속 접속하거나 이를 이용하는 것은
        귀하가 개정된 정책에 동의하였음을 의미합니다. 귀하가 개정된 정책에
        동의하지 않는다면 본 플랫폼에 대한 접속 및 이용을 중단해야 합니다.
        개인정보 처리방침의 이전 버전을 확인하시려면 여기를 클릭하시기 바랍니다.
      </p>
      <p className="title-2">10. 연락처</p>
      <p>
        본 정책에 대한 질의 사항, 의견 및 요청은{" "}
        <b
          onClick={() => {
            window.location.href = "celebe://deeplink/qna";
          }}
        >
          셀러비 앱 문의하기
        </b>{" "}
        를 통해 연락하시기 바랍니다.
      </p>
      <p className="title-3">특정 관할지역에 적용되는 추가 약관</p>
      <p>
        귀하가 본건 서비스에 접속하거나 본건 서비스를 이용하는 관할지역에
        적용되는 특정 관할지역 추가약관(이하 “추가약관”)의 조항과 본 정책의 본문
        조항이 충돌하는 경우, 해당 관할지역의 추가약관이 우선 적용됩니다.
      </p>
      <p className="title-3">대한민국.</p>
      <p>
        귀하가 한국에서 당사의 서비스를 이용하고 있는 경우, 하기 추가 약관이
        적용됩니다. 추가 조항과 본 정책의 본문 조항이 충돌하는 경우, 추가 약관이
        우선 적용됩니다.
      </p>
      <p className="title-3">귀하의 개인정보를 공유하는 방식.</p>
      <p>
        본 정책의 본문에 부연하여, 당사가 당사의 광고 및 마케팅 캠페인의
        일환으로 귀하가 귀하의 선택에 따라 당사의 플랫폼에서 홍보 목적으로
        게시하는 이용자 생성 콘텐츠 및 영상 콘텐츠 등의 정보를 이용하는 경우,
        귀하의 동의에 따라 해당 정보에 포함된 귀하의 개인정보가 해당 광고 또는
        마케팅 콘텐츠를 제공받는 자에게 공개될 수 있습니다.
      </p>
      <p className="title-3">정보 보유.</p>
      <p>
        당사는 귀하가 동의한 수집 목적이 달성되었거나, 귀하가 동의한 이용기간
        혹은 본 정책에 명시된 이용기간이 만료된 개인정보를 파기합니다. 단,
        당사는 해당하는 경우 다음을 포함하나 이에 국한되지 않는 법정 기간 동안
        귀하의 개인정보를 계속 보유합니다.
      </p>
      <p className="title-3">전자상거래 등에서의 소비자보호에 관한 법률:</p>
      <p>
        귀하의 주문취소 기록, 구매 아이템에 대한 지불 기록, 당사의 상품/서비스
        제공 기록: 5년
      </p>
      <p>소비자 불만 또는 분쟁 처리 기록: 3년</p>
      <p>광고 및 표시 관련 기록: 6개월</p>
      <p className="title-3">통신비밀보호법:</p>
      <p>귀하의 당사 웹사이트 방문 기록: 3개월</p>
      <p className="title-3">개인정보의 파기.</p>
      <p>
        당사는 관련 부서에서 복구할 수 없는 방식으로 귀하의 개인정보를
        파기합니다.
      </p>
      <p className="title-3">귀하의 권리</p>
      <p className="title-4">정보에 대한 권리.</p>
      <p>
        귀하는 당사가 보유한 귀하의 개인정보에 대한 열람, 부정확한 정보에 대한
        정정, 삭제 및 처리중단을 요청할 권리를 보유합니다. 귀하는 셀러비 앱 Q&A
        로 연락하여 귀하의 권리를 행사할 수 있습니다.
      </p>
      <p className="title-3">정보 보안.</p>
      <p>
        당사는 당사가 보유한 정보에 대한 무단 접근 또는 무단 변경, 무단 공개
        또는 파기 등으로부터 셀러비와 이용자를 보호하기 위해 최선의 노력을
        다하고 있습니다. 이를 위하여 당사는 기술적, 관리적, 물리적 보호 조치를
        시행 중이며, 정보 보호를 위한 내부관리계획 수립, 필요한 인력에 한하여
        접근권한 부여, 개인정보 처리시설에 대한 접근 통제 등을 포함하는
        기술적•관리적•물리적 조치를 시행하고 있습니다.
      </p>
      <p className="title-3">정책의 변경.</p>
      <p>
        당사는 본 정책을 변경하는 경우 개정된 정책의 효력발생일로부터 최소 7일
        이전(개정이 본 정책에 따른 귀하의 권리나 의무에 상당한 영향을 미치는
        경우 30일 이전)에 당사의 웹사이트 또는 서비스 페이지를 통해 귀하에게
        변경을 고지합니다.
      </p>
      <p className="title-3">아동의 정보.</p>
      <p>셀러비는 만 14세 미만 아동을 대상으로 하지 않습니다.</p>
      <p className="title-3">개인정보의 위탁 및/또는 국외이전.</p>
      <p>
        당사는 경우에 따라 귀하의 동의를 받거나 귀하에게 통지하여 귀하의 정보를
        당사의 계열사, 클라우드 서비스 제공사, IT 서비스 제공사 및 데이터 센터에
        위탁하며, 수탁사 중 일부는 국외에 소재합니다(목록은 여기 참조). 귀하의
        정보를 수령하고 처리하는 주체들은 국내외 규정을 준수하여 개인정보를 이용
        및 저장하고 개인정보 보호를 위해 가능한 모든 물리적, 기술적 조치를 취할
        것을 약속합니다. 귀하에게 본건 서비스를 제공함에 있어 개인정보의 이전이
        불필요한 경우, 귀하는{" "}
        <b
          onClick={() => {
            window.location.href = "celebe://deeplink/qna";
          }}
        >
          셀러비 앱 문의하기
        </b>
        로 연락하여 개인정보 이전을 거부할 수 있습니다.
      </p>
      <p>
        기타 문의 :{" "}
        <b
          onClick={() => {
            window.location.href = "celebe://deeplink/qna";
          }}
        >
          셀러비 앱 문의하기
        </b>{" "}
        를 이용해 문의를 남겨주세요.
      </p>
    </div>
  );
}
