import React from "react"
import { Link } from "react-router-dom"
import "./styles.scss"
import google from "../../assets/drawable-xxhdpi/googleplay.png"
import apple from "../../assets/drawable-xxhdpi/appstore.png"
// import jisoo from '../../assets/CELEBe_30s.mp4'
// import jisoo_v from '../../assets/CELEBe_30s_v.mp4'
import jisoo from "../../assets/celebe.mp4"
import jisoo_v from "../../assets/celebe-v.mp4"
import arrow from "../../assets/drawable-xxhdpi/arrow.png"
import wte from "../../assets/drawable-xxhdpi/home_wte.png"
import life from "../../assets/drawable-xxhdpi/home_life.png"
import enjoy from "../../assets/drawable-xxhdpi/home_enjoy.png"
import creator from "../../assets/drawable-xxhdpi/home_creator.png"

export default function Home() {
    return (
        <div className="home-wrap">
            <div className="video-wrap">
                <video muted={true} autoPlay={true} loop={true} className="mobile">
                    <source src={jisoo_v} type="video/mp4" />
                </video>
                <video muted={true} autoPlay={true} loop={true} className="pc">
                    <source src={jisoo} type="video/mp4" />
                </video>
                <div className="info-wrap">
                    <div className="shadow" />
                    <h1>세상에 없던 즐거움</h1>
                    <div>
                        <a href="https://abr.ge/koig5p" target="_blank" rel="noreferrer">
                            <img src={google} alt="" />
                        </a>
                        <a href="https://abr.ge/koig5p" target="_blank" rel="noreferrer">
                            <img src={apple} alt="" />
                        </a>
                    </div>
                </div>
            </div>
            {/* <div className="wte-wrap">
                <div className="left-wrap">
                    <img src={wte} alt="" />
                </div>
                <div className="right-wrap">
                    <h1>Watch to Earn</h1>
                    <p>보는 즐거움 X 쌓이는 포인트<br />포인트를 모아 fanC COIN으로!<br />셀러비에서 만나는 새로운 경험</p>
                    <Link to="/wte"><div><span>더 알아보기</span><img src={arrow} alt="" /></div></Link>
                </div>
            </div>
            <div className="content-wrap">
                <div className="row">
                    <div className="left">
                        <h1>처음 만나는 숏폼 라이프<br />CELEBe</h1>
                        <p>오늘의 일상을 공유하고,<br />숏폼을 즐겨보세요<br />셀러비에서는 당신의 시간이 수익이 됩니다</p>

                    </div>
                    <div className="right">
                        <div className="square">
                            <img src={life} alt="" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="left">
                        <div className="square">
                            <img src={enjoy} alt="" />
                        </div>
                    </div>
                    <div className="right">
                        <h1>셀러비 코인을 만나다<br />CELEBe X fanC COIN</h1>
                        <p>일상이 투자가 되는 놀라운 경험!<br />다양한 활동으로 쌓인 포인트는<br />팬시코인으로 교환할 수 있습니다</p>
                    </div>
                </div>
                <div className="row">
                    <div className="left">
                        <h1>누구나 셀럽이 되는 코리안 숏폼<br />CELEBe</h1>
                        <p>셀러비에서는 누구나 크리에이터가 될 수 있습니다<br />만드는 재미와 수익을 얻는 즐거움을,<br />셀러비와 함께 달라지는 일상을 시작해 보세요</p>
                    </div>
                    <div className="right">
                        <div className="square">
                            <img src={creator} alt="" />
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}
