import React from "react";

export default function EnTerm231211({ theme }) {
    return (
        <div className={`terms-en ${theme === "dark" ? "dark" : ""}`}>
            <p>Terms of Service</p>
            <p>Last updated: 11/12/2023</p>
            <ol type="1">
                <li>
                    <strong>Your Relationship With Us</strong>
                </li>
            </ol>
            <p>
                Welcome to CELEBE (the “Platform”), which is provided by CELEBE KOREA CO., LTD. in the United States (collectively such entities will be referred to as “CELEBE”, “we” or “us”). You are
                reading the terms of service (the “Terms”), which govern the relationship and serve as an agreement between you and us and set forth the terms and conditions by which you may access
                and use the Platform and our related websites, services, applications, products and content (collectively, the “Services”). Access to certain Services or features of the Services (such
                as, by way of example and not limitation, the ability to submit or share User Content (defined below)) may be subject to age restrictions and not available to all users of the
                Services. Our Services are provided for private, non-commercial use. For purposes of these Terms, “you” and “your” means you as the user of the Services.
            </p>
            <p>The Terms form a legally binding agreement between you and us. Please take the time to read them carefully.</p>
            <ol start="2" type="1">
                <li>
                    <strong>Accepting the Terms</strong>
                </li>
            </ol>
            <p>
                By accessing or using our Services, you confirm that you can form a binding contract with CELEBE, that you accept these Terms and that you agree to comply with them. Your access to and
                use of our Services is also subject to our Privacy Policy and Community Guidelines, the terms of which can be found directly on the Platform, or where the Platform is made available
                for download, on your mobile device’s applicable app store, and are incorporated herein by reference. By using the Services, you consent to the terms of the Privacy Policy.
            </p>
            <p>
                If you are accessing or using the Services on behalf of a business or entity, then (a) “you” and “your” includes you and that business or entity, (b) you represent and warrant that you
                are an authorized representative of the business or entity with the authority to bind the entity to these Terms, and that you agree to these Terms on the entity’s behalf, and (c) your
                business or entity is legally and financially responsible for your access or use of the Services as well as for the access or use of your account by others affiliated with your entity,
                including any employees, agents or contractors.
            </p>
            <p>
                You can accept the Terms by accessing or using our Services. You understand and agree that we will treat your access or use of the Services as acceptance of the Terms from that point
                onwards. You should print off or save a local copy of the Terms for your records.
            </p>
            <ol start="3" type="1">
                <li>
                    <strong>Changes to the Terms</strong>
                </li>
            </ol>
            <p>
                We amend these Terms from time to time, for instance when we update the functionality of our Services, when we combine multiple apps or services operated by us or our affiliates into a
                single combined service or app, or when there are regulatory changes. We will use commercially reasonable efforts to generally notify all users of any material changes to these Terms,
                such as through a notice on our Platform, however, you should look at the Terms regularly to check for such changes. We will also update the “Last Updated” date at the top of these
                Terms, which reflect the effective date of such Terms. Your continued access or use of the Services after the date of the new Terms constitutes your acceptance of the new Terms. If you
                do not agree to the new Terms, you must stop accessing or using the Services..
            </p>
            <ol start="4" type="1">
                <li>
                    <strong>Your Account with Us</strong>
                </li>
            </ol>
            <p>
                To access or use some of our Services, you must create an account with us. When you create this account, you must provide accurate and up-to-date information. It is important that you
                maintain and promptly update your details and any other information you provide to us, to keep such information current and complete. It is important that you keep your account
                password confidential and that you do not disclose it to any third party. If you know or suspect that any third party knows your password or has accessed your account, you must notify
                us immediately at: <a href="mailto:info@celebe.io">info@celebe.io</a> You agree that you are solely responsible (to us and to others) for the activity that occurs under your account.
            </p>
            <p>
                We reserve the right to disable your user account at any time, including if you have failed to comply with any of the provisions of these Terms, or if activities occur on your account
                which, in our sole discretion, would or might cause damage to or impair the Services or infringe or violate any third party rights, or violate any applicable laws or regulations. If
                you no longer want to use our Services again, and would like your account deleted, contact us at: <a href="mailto:info@celebe.io">info@celebe.io</a>. We will provide you with further
                assistance and guide you through the process. Once you choose to delete your account, you will not be able to reactivate your account or retrieve any of the content or information you
                have added..
            </p>
            <ol start="5" type="1">
                <li>
                    <strong>Your Access to and Use of Our Services</strong>
                </li>
            </ol>
            <p>Your access to and use of the Services is subject to these Terms and all applicable laws and regulations..</p>
            <p>You may not:</p>
            <ul>
                <li>Access or use the Services if you are not fully able and legally competent to agree to these Terms or are authorized to use the Services by your parent or legal guardian.</li>
                <li>
                    Make unauthorised copies, modify, adapt, translate, reverse engineer, disassemble, decompile or create any derivative works of the Services or any content included therein,
                    including any files, tables or documentation (or any portion thereof) or determine or attempt to determine any source code, algorithms, methods or techniques embodied by the
                    Services or any derivative works thereof.
                </li>
                <li>Distribute, license, transfer, or sell, in whole or in part, any of the Services or any derivative works thereof.</li>
                <li>Market, rent or lease the Services for a fee or charge, or use the Services to advertise or perform any commercial solicitation.</li>
                <li>
                    Use the Services, without our express written consent, for any commercial or unauthorized purpose, including communicating or facilitating any commercial advertisement or
                    solicitation or spamming.
                </li>
                <li>
                    Interfere with or attempt to interfere with the proper working of the Services, disrupt our website or any. networks connected to the Services, or bypass any measures we may use to
                    prevent or restrict access to the Services.
                </li>
                <li>
                    Incorporate the Services or any portion thereof into any other program or product. In such case, we reserve the right to refuse service, terminate accounts or limit access to the
                    Services in our sole discretion.
                </li>
                <li>Use automated scripts to collect information from or otherwise interact with the Services.</li>
                <li>
                    Impersonate any person or entity, or falsely state or otherwise misrepresent you or your affiliation with any person or entity, including giving the impression that any content you
                    upload, post, transmit, distribute or otherwise make available emanates from the Services.
                </li>
                <li>
                    Intimidate or harass another, or promote sexually explicit material, violence or discrimination based on race, sex, religion, nationality, disability, sexual orientation or age.
                </li>
                <li>Use or attempt to use another’s account, service or system without authorisation from CELEBe, or create a false identity on the Services.</li>
                <li>
                    Use the Services in a manner that may create a conflict of interest or undermine the purposes of the Services, such as trading reviews with other users or writing or soliciting
                    fake reviews.
                </li>
                <li>
                    Use the Services to upload, transmit, distribute, store or otherwise make available in any way
                    <ul>
                        <li>Files that contain viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful.</li>
                        <li>
                            Any unsolicited or unauthorised advertising, solicitations, promotional materials, “junk mail,” “spam,” “chain letters,” “pyramid schemes,” or any other prohibited form of
                            solicitation.
                        </li>
                        <li>
                            Any private information of any third party, including addresses, phone numbers, email addresses, number and feature in the personal identity document (e.g., National
                            Insurance numbers, passport numbers) or credit card numbers.
                        </li>
                        <li>Any material which does or may infringe any copyright, trademark or other intellectual property or privacy rights of any other person.</li>
                        <li>Any material which is defamatory of any person, obscene, offensive, pornographic, hateful or inflammatory.</li>
                        <li>Any material that would constitute, encourage or provide instructions for a criminal offence, dangerous activities or self-harm.</li>
                        <li>
                            Any material that is deliberately designed to provoke or antagonise people, especially trolling and bullying, or is intended to harass, harm, hurt, scare, distress,
                            embarrass or upset people.
                        </li>
                        <li>Any material that contains a threat of any kind, including threats of physical violence.</li>
                        <li>Any material that is racist or discriminatory, including discrimination on the basis of someone’s race, religion, age, gender, disability or sexuality.</li>
                        <li>Any answers, responses, comments, opinions, analysis or recommendations that you are not properly licensed or otherwise qualified to provide.</li>
                        <li>
                            Material that, in the sole judgment of CELEBe, is objectionable or which restricts or inhibits any other person from using the Services, or which may expose CELEBe, the
                            Services or its users to any harm or liability of any type.
                        </li>
                    </ul>
                </li>
            </ul>
            <p>
                In addition to the above, your access to and use of the Services must, at all times, be compliant with our Community Guidelines. We reserve the right, at any time and without prior
                notice, to remove or disable access to content at our discretion for any reason or no reason. Some of the reasons we may remove or disable access to content may include finding the
                content objectionable, in violation of these Terms or our Community Policy, or otherwise harmful to the Services or our users. Our automated systems analyze your content (including
                emails) to provide you personally relevant product features, such as customized search results, tailored advertising, and spam and malware detection. This analysis occurs as the
                content is sent, received, and when it is stored.
            </p>
            <ol start="6" type="1">
                <li>
                    <strong>Intellectual Property Rights</strong>
                </li>
            </ol>
            <p>We respect intellectual property rights and ask you to do the same. As a condition of your access to and use of the Services, you agree to the terms of the Copyright Policy.</p>
            <ol start="7" type="1">
                <li>
                    <strong>Content</strong>
                </li>
            </ol>
            <p>
                <strong>a. CELEBE Content</strong>
            </p>
            <p>
                As between you and CELEBE, all content, software, images, text, graphics, illustrations, logos, patents, trademarks, service marks, copyrights, photographs, audio, videos, music on and
                “look and feel” of the Services, and all intellectual property rights related thereto (the “CELEBE Content”), are either owned or licensed by CELEBE, it being understood that you or
                your licensors will own any User Content (as defined below) you upload or transmit through the Services. Use of the CELEBE Content or materials on the Services for any purpose not
                expressly permitted by these Terms is strictly prohibited. Such content may not be downloaded, copied, reproduced, distributed, transmitted, broadcast, displayed, sold, licensed or
                otherwise exploited for any purpose whatsoever without our or, where applicable, our licensors’ prior written consent. We and our licensors reserve all rights not expressly granted in
                and to their content.
            </p>
            <p>
                You acknowledge and agree that we may generate revenues, increase goodwill or otherwise increase our value from your use of the Services, including, by way of example and not
                limitation, through the sale of advertising, sponsorships, promotions, usage data and Gifts (defined below), and except as specifically permitted by us in these Terms or in another
                agreement you enter into with us, you will have no right to share in any such revenue, goodwill or value whatsoever. You further acknowledge that, except as specifically permitted by
                us in these Terms or in another agreement you enter into with us, you (i) have no right to receive any income or other consideration from any User Content (defined below) or your use
                of any musical works, sound recordings or audiovisual clips made available to you on or through the Services, including in any User Content created by you, and (ii) are prohibited from
                exercising any rights to monetize or obtain consideration from any User Content within the Services or on any third party service ( e.g. , you cannot claim User Content that has been
                uploaded to a social media platform such as YouTube for monetization).
            </p>
            <p>
                Subject to the terms and conditions of the Terms, you are hereby granted a non-exclusive, limited, non-transferable, non-sublicensable, revocable, worldwide license to access and use
                the Services, including to download the Platform on a permitted device, and to access the CELEBE Content solely for your personal, non-commercial use through your use of the Services
                and solely in compliance with these Terms. CELEBE reserves all rights not expressly granted herein in the Services and the CELEBE Content. You acknowledge and agree that CELEBE may
                terminate this license at any time for any reason or no reason. NO RIGHTS ARE LICENSED WITH RESPECT TO SOUND RECORDINGS AND THE MUSICAL WORKS EMBODIED THEREIN THAT ARE MADE AVAILABLE
                FROM OR THROUGH THE SERVICE.
            </p>
            <p>
                You acknowledge and agree that when you view content provided by others on the Services, you are doing so at your own risk. The content on our Services is provided for general
                information only. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on
                the basis of the content on our Services. We make no representations, warranties or guarantees, whether express or implied, that any CELEBE Content (including User Content) is
                accurate, complete or up to date. Where our Services contain links to other sites and resources provided by third parties, these links are provided for your information only. We have
                no control over the contents of those sites or resources. Such links should not be interpreted as approval by us of those linked websites or information you may obtain from them. You
                acknowledge that we have no obligation to pre-screen, monitor, review, or edit any content posted by you and other users on the Services (including User Content).
            </p>
            <p>
                <strong>b. User-Generated Content</strong>
            </p>
            <p>
                Users of the Services may be permitted to upload, post or transmit (such as via a stream) or otherwise make available content through the Services including, without limitation, any
                text, photographs, user videos, sound recordings and the musical works embodied therein, including videos that incorporate locally stored sound recordings from your personal music
                library and ambient noise (“User Content”). Users of the Services may also extract all or any portion of User Content created by another user to produce additional User Content,
                including collaborative User Content with other users, that combine and intersperse User Content generated by more than one user. Users of the Services may also overlay music,
                graphics, stickers, Virtual Items (as defined and further explained Virtual Items Policy) and other elements provided by CELEBE (“CELEBE Elements”) onto this User Content and transmit
                this User Content through the Services. The information and materials in the User Content, including User Content that includes CELEBE Elements, have not been verified or approved by
                us. The views expressed by other users on the Services (including through use of the virtual gifts) do not represent our views or values.
            </p>
            <p>
                Whenever you access or use a feature that allows you to upload or transmit User Content through the Services (including via certain third party social media platforms such as
                Instagram, Facebook, YouTube, Twitter), or to make contact with other users of the Services, you must comply with the standards set out at “Your Access to and Use of Our Services”
                above. You may also choose to upload or transmit your User Content, including User Content that includes CELEBE Elements, on sites or platforms hosted by third parties. If you decide
                to do this, you must comply with their content guidelines as well as with the standards set out at “Your Access to and Use of Our Services” above. As noted above, these features may
                not be available to all users of the Services, and we have no liability to you for limiting your right to certain features of the Services. You warrant that any such contribution does
                comply with those standards, and you will be liable to us and indemnify us for any breach of that warranty. This means you will be responsible for any loss or damage we suffer as a
                result of your breach of warranty.
            </p>
            <p>
                Any User Content will be considered non-confidential and non-proprietary. You must not post any User Content on or through the Services or transmit to us any User Content that you
                consider to be confidential or proprietary. When you submit User Content through the Services, you agree and represent that you own that User Content, or you have received all
                necessary permissions, clearances from, or are authorised by, the owner of any part of the content to submit it to the Services, to transmit it from the Services to other third party
                platforms, and/or adopt any third party content.
            </p>
            <p>
                If you only own the rights in and to a sound recording, but not to the underlying musical works embodied in such sound recordings, then you must not post such sound recordings to the
                Services unless you have all permissions, clearances from, or are authorised by, the owner of any part of the content to submit it to the Services
            </p>
            <p>
                You or the owner of your User Content still own the copyright in User Content sent to us, but by submitting User Content via the Services, you hereby grant us an unconditional
                irrevocable, non-exclusive, royalty-free, fully transferable, perpetual worldwide licence to use, modify, adapt, reproduce, make derivative works of, publish and/or transmit, and/or
                distribute and to authorise other users of the Services and other third-parties to view, access, use, download, modify, adapt, reproduce, make derivative works of, publish and/or
                transmit your User Content in any format and on any platform, either now known or hereinafter invented.
            </p>
            <p>
                You further grant us a royalty-free license to use your user name, image, voice, and likeness to identify you as the source of any of your User Content; provided, however, that your
                ability to provide an image, voice, and likeness may be subject to limitations due to age restrictions.
            </p>
            <p>
                For the avoidance of doubt, the rights granted in the preceding paragraphs of this Section include, but are not limited to, the right to reproduce sound recordings (and make mechanical
                reproductions of the musical works embodied in such sound recordings), and publicly perform and communicate to the public sound recordings (and the musical works embodied therein), all
                on a royalty-free basis. This means that you are granting us the right to use your User Content without the obligation to pay royalties to any third party, including, but not limited
                to, a sound recording copyright owner (e.g., a record label), a musical work copyright owner (e.g., a music publisher), a performing rights organization (e.g., ASCAP, BMI, SESAC, etc.)
                (a “PRO”), a sound recording PRO (e.g., SoundExchange), any unions or guilds, and engineers, producers or other royalty participants involved in the creation of User Content.
            </p>
            <p>
                <strong>c. Specific Rules for Musical Works and for Recording Artists</strong>
            </p>
            <p>
                If you are a composer or author of a musical work and are affiliated with a PRO, then you must notify your PRO of the royalty-free license you grant through these Terms in your User
                Content to us. You are solely responsible for ensuring your compliance with the relevant PRO’s reporting obligations. If you have assigned your rights to a music publisher, then you
                must obtain the consent of such music publisher to grant the royalty-free license(s) set forth in these Terms in your User Content or have such music publisher enter into these Terms
                with us. Just because you authored a musical work (e.g., wrote a song) does not mean you have the right to grant us the licenses in these Terms. If you are a recording artist under
                contract with a record label, then you are solely responsible for ensuring that your use of the Services is in compliance with any contractual obligations you may have to your record
                label, including if you create any new recordings through the Services that may be claimed by your label.
            </p>
            <p>
                <strong>d. Through-To-The-Audience Rights</strong>
            </p>
            <p>
                All of the rights you grant in your User Content in these Terms are provided on a through-to-the-audience basis, meaning the owners or operators of third party services will not have
                any separate liability to you or any other third party for User Content posted or used on such third party service via the Services.
            </p>
            <p>
                <strong>e. Waiver of Rights to User Content</strong>
            </p>
            <p>
                By posting User Content to or through the Services, you waive any rights to prior inspection or approval of any marketing or promotional materials related to such User Content. You
                also waive any and all rights of privacy, publicity, or any other rights of a similar nature in connection with your User Content, or any portion thereof. To the extent any moral
                rights are not transferable or assignable, you hereby waive and agree never to assert any and all moral rights, or to support, maintain or permit any action based on any moral rights
                that you may have in or with respect to any User Content you Post to or through the Services. We also have the right to disclose your identity to any third party who is claiming that
                any User Content posted or uploaded by you to our Services constitutes a violation of their intellectual property rights, or of their right to privacy.
            </p>
            <p>
                We, or authorised third parties, reserve the right to cut, crop, edit or refuse to publish, your content at our or their sole discretion. We have the right to remove, disallow, block
                or delete any posting you make on our Services if, in our opinion, your post does not comply with the content standards set out at “Your Access to and Use of Our Services” above. In
                addition, we have the right – but not the obligation – in our sole discretion to remove, disallow, block or delete any User Content (i) that we consider to violate these Terms, or (ii)
                in response to complaints from other users or third parties, with or without notice and without any liability to you. As a result, we recommend that you save copies of any User Content
                that you post to the Services on your personal device(s) in the event that you want to ensure that you have permanent access to copies of such User Content. We do not guarantee the
                accuracy, integrity, appropriateness or quality of any User Content, and under no circumstances will we be liable in any way for any User Content. You control whether your User Content
                is made publicly available on the Services to all other users of the Services or only available to people you approve. To restrict access to your User Content, you should select the
                privacy setting available within the Platform.
            </p>
            <p>
                We accept no liability in respect of any content submitted by users and published by us or by authorised third parties. If you wish to file a complaint about information or materials
                uploaded by other users, contact us at: <a href="mailto:info@celebe.io">info@celebe.io</a>
            </p>
            <p>
                CELEBE takes reasonable measures to expeditiously remove from our Services any infringing material that we become aware of. It is CELEBE’s policy, in appropriate circumstances and at
                its discretion, to disable or terminate the accounts of users of the Services who repeatedly infringe copyrights or intellectual property rights of others.
            </p>
            <p>
                While our own staff is continually working to develop and evaluate our own product ideas and features, we pride ourselves on paying close attention to the interests, feedback,
                comments, and suggestions we receive from the user community. If you choose to contribute by sending us or our employees any ideas for products, services, features, modifications,
                enhancements, content, refinements, technologies, content offerings (such as audio, visual, games, or other types of content), promotions, strategies, or product/feature names, or any
                related documentation, artwork, computer code, diagrams, or other materials (collectively “Feedback”), then regardless of what your accompanying communication may say, the following
                terms will apply, so that future misunderstandings can be avoided. Accordingly, by sending Feedback to us, you agree that: CELEBE has no obligation to review, consider, or implement
                your Feedback, or to return to you all or part of any Feedback for any reason;
            </p>
            <p>
                Feedback is provided on a non-confidential basis, and we are not under any obligation to keep any Feedback you send confidential or to refrain from using or disclosing it in any way;
                and You irrevocably grant us perpetual and unlimited permission to reproduce, distribute, create derivative works of, modify, publicly perform (including on a through-to-the-audience
                basis), communicate to the public, make available, publicly display, and otherwise use and exploit the Feedback and derivatives thereof for any purpose and without restriction, free of
                charge and without attribution of any kind, including by making, using, selling, offering for sale, importing, and promoting commercial products and services that incorporate or embody
                Feedback, whether in whole or in part, and whether as provided or as modified.
            </p>
            <ol start="8" type="1">
                <li>
                    <strong>Points</strong>
                </li>
            </ol>
            <p>
                <strong>a. Earn points</strong>
            </p>
            <p>
                CELEBe Points (hereinafter referred to as “Points”) refer to points earned by users in connection with marketing activities such as accumulation services or events of our company and
                affiliates, or points purchased through us. Our company calculates and grants points in the instances below.
            </p>
            <ol type="i">
                <li>
                    <p>When watching videos in the following/recommended feed</p>
                </li>
                <li>
                    <p>When my video is watched by another user</p>
                </li>
                <li>
                    <p>When completing the attendance check</p>
                </li>
                <li>
                    <p>When participating in our company and partner events in the ‘Benefits’ tab</p>
                </li>
                <li>
                    <p>If you install and sign up through a referral link, or if a user you recommended signs up.</p>
                </li>
                <li>
                    <p>Other</p>
                </li>
            </ol>
            <p>Point accumulation methods and standards may change without notice according to the internal policies of the company and its affiliates.</p>
            <p>
                <strong>b. Redeeming points</strong>
            </p>
            <p>
                Users can exchange accumulated points for FanC Points using FanC Wallet, and a 15% fee is charged for one exchange. After exchanging for FanC Points, you can exchange them for various
                points and coins affiliated with FanC Wallet.
            </p>
            <p>
                The details of partnerships within FanC Wallet may change depending on the status of negotiations with other companies. The exchange price of affiliate points is calculated by
                considering the supply amount from the affiliate, payment commission, operating costs, etc.
            </p>
            <p>Currently, in order to exchange CELEBe Points for FanC Points, the remaining usable points must be at least 3,000P, and exchange is only possible if this condition is met.</p>
            <p>
                If a minor user under the age of 14 uses points, he/she is deemed to have completed membership registration with the consent of his/her legal representative at the time of initial
                membership registration and has also obtained the legal representative’s consent for the use of points.
            </p>
            <p>
                Users may not transfer points to others or use them for rental or collateral purposes. However, exceptions are made in cases where procedures such as “gifting points” recognized by the
                company or its affiliates are followed. Points that are transferred or gifted to other users pursuant to “Gift Points” cannot be re-transferred to other users, and there may be
                restrictions on the expiration date of point use and transfer amount/number of times in accordance with our policy.
            </p>
            <p>
                <strong>c. Point correction, cancellation and cancellation</strong>
            </p>
            <p>
                If there is an error in the accumulated points, the user must apply for correction to the company within 60 days from the time the error occurred, and the company must complete the
                correction process within a maximum of 90 months from the date of the user’s correction request. However, if necessary, the user must present objective data to prove point accumulation
                errors.
            </p>
            <p>
                After 24 months, the points earned for free will expire in the following manner. The point first accumulated 24 months ago will be the first to expire. The expiration period shall be
                on the last day of such month. This process will be repeated on a monthly basis. Points charged for a fee have no expiration date or expiration date. If the points held by the user
                expire when the scheduled expiration date arrives, we will send information related to point expiration via push/text message, etc. at least 15 days in advance. However, for points
                provided through specific events, the point expiration date and expiration date will be separately notified during the event, and expiration notification push/text messages may be
                excluded. In addition, we take steps to allow users to check points scheduled to expire in a given month through our service. The expiration date of points provided additionally to
                users through marketing activities such as events by our company or our affiliates, rather than the points accumulated through the user’s purchases, may be separately booked in
                accordance with the policies of our company or our affiliates and this will be announced separately.
            </p>
            <p>fanC Points exchanged by the user can only be refunded within 3 days from the date of exchange and will be restored within 7 days from the date of application for refund.</p>
            <p>
                <strong>d. Membership withdrawal and point processing</strong>
            </p>
            <p>
                If a user who wishes to withdraw his/her membership wants to use the remaining points, he/she must use the available points accumulated before the membership withdrawal date in
                accordance with the provisions of these Terms and Conditions.
            </p>
            <p>Points not used before withdrawal will automatically expire after a certain grace period.</p>
            <p>
                <strong>9. Indemnity</strong>
            </p>
            <p>
                You agree to defend, indemnify, and hold harmless CELEBE, its parents, subsidiaries, and affiliates, and each of their respective officers, directors, employees, agents and advisors
                from any and all claims, liabilities, costs, and expenses, including, but not limited to, attorneys’ fees and expenses, arising out of a breach by you or any user of your account of
                these Terms or arising out of a breach of your obligations, representation and warranties under these Terms.
            </p>
            <ol start="10" type="1">
                <li>
                    <strong>Exclusion of Warranties</strong>
                </li>
            </ol>
            <p>Nothing in these terms shall affect any statutory rights that you cannot contractually agree to alter or waive and are legally always entitled to as a consumer.</p>
            <p>The services are provided “as is” and we make no warranty or representation to you with respect to them. in particular we do not represent or warrant to you that:</p>
            <ul>
                <li>your use of the services will meet your requirements;</li>
                <li>your use of the services will be uninterrupted, timely, secure or free from error;</li>
                <li>any information obtained by you as a result of your use of the services will be accurate or reliable; and</li>
                <li>defects in the operation or functionality of any software provided to you as part of the services will be corrected.</li>
            </ul>
            <p>
                No conditions, warranties or other terms (including any implied terms as to satisfactory quality, fitness for purpose or conformance with description) apply to the services except to
                the extent that they are expressly set out in the terms. We may change, suspend, withdraw or restrict the availability of all or any part of our platform for business and operational
                reasons at any time without notice.
            </p>
            <ol start="11" type="1">
                <li>
                    <strong>Limitation of Liability</strong>
                </li>
            </ol>
            <p>
                Nothing in these terms shall exclude or limit our liability for losses which may not be lawfully excluded or limited by applicable law. This includes liability for death or personal
                injury caused by our negligence or the negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation.
            </p>
            <p>Subject to the paragraph above, we shall not be liable to you for:</p>
            <ul>
                <li>
                    <ol type="i">
                        <li>any loss of profit (whether incurred directly or indirectly);</li>
                    </ol>
                </li>
                <li>
                    <ol start="2" type="i">
                        <li>any loss of goodwill;</li>
                    </ol>
                </li>
                <li>
                    <ol start="3" type="i">
                        <li>any loss of opportunity;</li>
                    </ol>
                </li>
                <li>
                    <ol start="4" type="i">
                        <li>any loss of data suffered by you; or</li>
                    </ol>
                </li>
                <li>
                    <ol start="22" type="a">
                        <li>any indirect or consequential losses which may be incurred by you. any other loss will be limited to the amount paid by you to CELEBE within the last 12 months.</li>
                    </ol>
                </li>
            </ul>
            <p>Any loss or damage which may be incurred by you as a result of:</p>
            <ul>
                <li>
                    any reliance placed by you on the completeness, accuracy or existence of any advertising, or as a result of any relationship or transaction between you and any advertiser or
                    sponsor whose advertising appears on the service;
                </li>
                <li>any changes which we may make to the services, or for any permanent or temporary cessation in the provision of the services (or any features within the services);</li>
                <li>the deletion of, corruption of, or failure to store, any content and other communications data maintained or transmitted by or through your use of the services;</li>
                <li>your failure to provide us with accurate account information; or</li>
                <li>your failure to keep your password or account details secure and confidential.</li>
            </ul>
            <p>
                Please note that we only provide our platform for domestic and private use. You agree not to use our platform for any commercial or business purposes, and we have no liability to you
                for any loss of profit, loss of business, loss of goodwill or business reputation, business interruption, or loss of business opportunity.
            </p>
            <p>
                If defective digital content that we have supplied damages a device or digital content belonging to you and this is caused by our failure to use reasonable care and skill, we will
                either repair the damage or pay you compensation. However, we will not be liable for damage that you could have avoided by following our advice to apply an update offered to you free
                of charge or for damage that was caused by you failing to correctly follow installation instructions or to have in place the minimum system requirements advised by us.
            </p>
            <p>These limitations on our liability to you shall apply whether or not we have been advised of or should have been aware of the possibility of any such losses arising.</p>
            <p>
                You are responsible for any mobile charges that may apply to your use of our service, including text-messaging and data charges. If you’re unsure what those charges may be, you should
                ask your service provider before using the service.
            </p>
            <p>
                To the fullest extent permitted by law, any dispute you have with any third party arising out of your use of the services, including, by way of example and not limitation, any carrier,
                copyright owner or other user, is directly between you and such third party, and you irrevocably release us and our affiliates from any and all claims, demands and damages (actual and
                consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes.
            </p>
            <p>
                <strong>12. Other Terms</strong>
            </p>
            <p>
                <strong>a. Open Source</strong>
            </p>
            <p>The Platform contains certain open source software. Each item of open source software is subject to its own applicable license terms, which can be found at Open Source Policy.</p>
            <p>
                <strong>b. Entire Agreement</strong>
            </p>
            <p>
                These Terms constitute the whole legal agreement between you and CELEBE and govern your use of the Services and completely replace any prior agreements between you and CELEBE in
                relation to the Services.
            </p>
            <p>
                <strong>c. Links</strong>
            </p>
            <p>
                You may link to our home page, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it. You must not establish a link in such a
                way as to suggest any form of association, approval or endorsement on our part where none exists. You must not establish a link to our Services in any website that is not owned by you.
                The website in which you are linking must comply in all respects with the content standards set out at “Your Access to and Use of Our Services” above. We reserve the right to withdraw
                linking permission without notice.
            </p>
            <p>
                <strong>d. Age limit</strong>
            </p>
            <p>
                This service is intended for persons aged 14 years or older only (including additional restrictions set forth in the terms and conditions of the jurisdiction). By using this service,
                you confirm that you are at or above the relevant base age. If we find out that a person under the above standard age is using this service, we will terminate the account of the user
                concerned.
            </p>
            <p>
                <strong>e. No Waiver</strong>
            </p>
            <p>
                Agreement, it shall specify the date of application and the reason for modification, and notify the initialization page of the website from 7 days before the date of application.
                However, in the event of significant or adverse changes to these Terms and Conditions, the Company shall provide prior notice to the User with reasonable efforts, and the amended Terms
                and Conditions shall take effect at least 30 days later. However, changes to new functions of services that improve your convenience or changes due to legal reasons may be implemented
                immediately.
            </p>
            <p>
                By announcing or notifying you of the changed terms and conditions as above, you agree to the terms and conditions if you do not express your intention within the above period, but you
                have not explicitly expressed your refusal.
            </p>
            <p>
                <strong>12. Other Terms</strong>
            </p>
            <p>
                <strong>a. Open Source</strong>
            </p>
            <p>The Platform contains certain open source software. Each item of open source software is subject to its own applicable license terms, which can be found at Open Source Policy.</p>
            <p>
                <strong>b. Entire Agreement</strong>
            </p>
            <p>
                These Terms constitute the whole legal agreement between you and CELEBE and govern your use of the Services and completely replace any prior agreements between you and CELEBE in
                relation to the Services.
            </p>
            <p>
                <strong>c. Links</strong>
            </p>
            <p>
                You may link to our home page, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it. You must not establish a link in such a
                way as to suggest any form of association, approval or endorsement on our part where none exists. You must not establish a link to our Services in any website that is not owned by you.
                The website in which you are linking must comply in all respects with the content standards set out at “Your Access to and Use of Our Services” above. We reserve the right to withdraw
                linking permission without notice.
            </p>
            <p>
                <strong>d. Age Limit</strong>
            </p>
            <p>
                This service is intended for persons aged 14 years or older only (including additional restrictions set forth in the terms and conditions of the jurisdiction). By using this service,
                you confirm that you are at or above the relevant base age. If we find out that a person under the above standard age is using this service, we will terminate the account of the user
                concerned.
            </p>
            <p>
                <strong>e. No Waiver</strong>
            </p>
            <p>Our failure to insist upon or enforce any provision of these Terms shall not be construed as a waiver of any provision or right.</p>
            <p>
                <strong>f. Security</strong>
            </p>
            <p>
                We do not guarantee that our Services will be secure or free from bugs or viruses. You are responsible for configuring your information technology, computer programmes and platform to
                access our Services. You should use your own virus protection software.
            </p>
            <p>
                <strong>g. Severability</strong>
            </p>
            <p>
                If any court of law, having jurisdiction to decide on this matter, rules that any provision of these Terms is invalid, then that provision will be removed from the Terms without
                affecting the rest of the Terms, and the remaining provisions of the Terms will continue to be valid and enforceable.
            </p>
            <p>
                <strong>h. Other Inquiries</strong>
            </p>
            <p>
                Contact us at <a href="mailto:info@celebe.io">info@celebe.io</a>
            </p>
            <p>
                <strong>Additional Terms</strong>
            </p>
            <p>
                <strong>App Stores</strong>
            </p>
            <p>To the extent permitted by applicable law, the following supplemental terms shall apply when accessing the Platform through specific devices:</p>
            <p>
                <strong>Notice regarding Apple</strong>
            </p>
            <p>By downloading the Platform from a device made by Apple, Inc. (“Apple”) or from Apple’s App Store, you specifically acknowledge and agree that:</p>
            <ul>
                <li>These Terms between CELEBE and you; Apple is not a party to these Terms.</li>
                <li>
                    The license granted to you hereunder is limited to a personal, limited, non-exclusive, non-transferable right to install the Platform on the Apple device(s) authorised by Apple
                    that you own or control for personal, non-commercial use, subject to the Usage Rules set forth in Apple’s App Store Terms of Services.
                </li>
                <li>Apple is not responsible for the Platform or the content thereof and has no obligation whatsoever to furnish any maintenance or support services with respect to the Platform.</li>
                <li>
                    In the event of any failure of the Platform to conform to any applicable warranty, you may notify Apple, and Apple will refund the purchase price for the Platform, if any, to you.
                    To the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to the Platform.
                </li>
                <li>
                    Apple is not responsible for addressing any claims by you or a third party relating to the Platform or your possession or use of the Platform, including without limitation (a)
                    product liability claims; (b) any claim that the Platform fails to conform to any applicable legal or regulatory requirement; and (c) claims arising under consumer protection or
                    similar legislation.
                </li>
                <li>
                    In the event of any third party claim that the Platform or your possession and use of the Platform infringes such third party’s intellectual property rights, Apple is not
                    responsible for the investigation, defence, settlement or discharge of such intellectual property infringement claim.
                </li>
                <li>
                    You represent and warrant that (a) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist
                    supporting” country; and (b) you are not listed on any U.S. Government list of prohibited or restricted parties.
                </li>
                <li>
                    Apple and its subsidiaries are third party beneficiaries of these Terms and upon your acceptance of the terms and conditions of these Terms, Apple will have the right (and will be
                    deemed to have accepted the right) to enforce these Terms against you as a third party beneficiary hereof.
                </li>
                <li>CELEBE expressly authorises use of the Platform by multiple users through the Family Sharing or any similar functionality provided by Apple.</li>
            </ul>
            <p>
                <strong>Windows Phone Store</strong>
            </p>
            <p>By downloading the Platform from the Windows Phone Store (or its successors) operated by Microsoft, Inc. or its affiliates, you specifically acknowledge and agree that:</p>
            <ul>
                <li>
                    You may install and use one copy of the Platform on up to five (5) Windows Phone enabled devices that are affiliated with the Microsoft account you use to access the Windows Phone
                    Store. Beyond that, we reserve the right to apply additional conditions or charge additional fees.
                </li>
                <li>
                    You acknowledge that Microsoft Corporation, your phone manufacturer and network operator have no obligation whatsoever to furnish any maintenance and support services with respect
                    to the Platform.
                </li>
            </ul>
            <p>
                <strong>Google Play</strong>
            </p>
            <p>By downloading the Platform from Google Play (or its successors) operated by Google, Inc. or one of its affiliates (“Google”), you specifically acknowledge and agree that:</p>
            <ul>
                <li>
                    to the extent of any conflict between (a) the Google Play Terms of Services and the Google Play Business and Program Policies or such other terms which Google designates as default
                    end user license terms for Google Play (all of which together are referred to as the “Google Play Terms”), and (b) the other terms and conditions in these Terms, the Google Play
                    Terms shall apply with respect to your use of the Platform that you download from Google Play, and
                </li>
                <li>
                    you hereby acknowledge that Google does not have any responsibility or liability related to compliance or non-compliance by CELEBE or you (or any other user) under these Terms or
                    the Google Play Terms.
                </li>
            </ul>
            <p>
                <strong>Additional Terms and Conditions – Provisions under jurisdiction</strong>
            </p>
            <p>
                <strong>Republic of Korea</strong>
            </p>
            <p>If you use our services in Korea, the following additional terms and conditions apply.</p>
            <p>
                <strong>Applicable Laws and Jurisdictions</strong>
            </p>
            <p>
                This Agreement, matters under this Agreement, and the enactment of this Agreement shall be governed by the laws of the Republic of Korea. You and we both agree that the courts of the
                Republic of Korea have exclusive jurisdiction.
            </p>
            <p>
                <strong>Limitation of Responsibility</strong>
            </p>
            <p>The restrictions on liability in Articles 4 and 10 shall not apply to any loss or damage caused to you by our intention or negligence.</p>
            <p>
                <strong>Parental and Parental Consent</strong>
            </p>
            <p>
                If you are between the ages of 14 and under 19, we guarantee that you have received consent from your parent or guardian to use the service or to subscribe to an account for the
                service.
            </p>
            <p>
                <strong>Change in Terms and Conditions</strong>
            </p>
            <p>
                The following shall apply in preference to the provisions of Article 3. We may change these terms and conditions to the extent that they do not violate the relevant laws and
                regulations.
            </p>
            <p>
                If the Company changes this Agreement, it shall specify the date of application and the reason for modification, and notify the initialization page of the website from 7 days before
                the date of application. However, in the event of significant or adverse changes to these Terms and Conditions, the Company shall provide prior notice to the User with reasonable
                efforts, and the amended Terms and Conditions shall take effect at least 30 days later. However, changes to new functions of services that improve your convenience or changes due to
                legal reasons may be implemented immediately.
            </p>
            <p>
                By announcing or notifying you of the changed terms and conditions as above, you agree to the terms and conditions if you do not express your intention within the above period, but you
                have not explicitly expressed your refusal.
            </p>
            <p>
                <strong>Use of this Service</strong>
            </p>
            <p>The following shall apply in preference to the provisions of Article 5.</p>
            <p>
                Unless the content is offensive, inappropriate, violated, or otherwise deemed harmful to the service and service users, we will not delete the user’s content without your consent or
                legal grounds or block access to it.
            </p>
            <p>
                <strong>User-Generated Content</strong>
            </p>
            <p>The following shall apply in preference to the provisions of Article 7 B.</p>
            <p>
                To the extent permitted by law, we may use, modify, adapt, adapt, duplicate, secondary, and otherwise use, modify, publish, and/or transmit user content in accordance with our license
                to provide this service to you and other users.
            </p>
            <p>
                We do not disclose your identity to any third party unless specifically permitted by law or agreed to by you. If it is deemed that your user content does not comply with the criteria
                set forth in the regulation of “Access and Use of this Service”, we or any authorized third party may cut or edit the user content and refuse to publish it.
            </p>
            <p>
                <strong>Pre-Notification of Restrictions on Service Use, etc.</strong>
            </p>
            <p>The following shall apply in preference to the provisions of Articles 5, 6, 7, and 9.</p>
            <p>
                We will promptly notify you of any changes that will be made against you (including all delays or restrictions on the use of this service, termination and deletion of accounts,
                removal, disapproval and blocking of content).
            </p>
            <p>
                However, if notice is prohibited for legal reasons or reasonably determined to cause harm to users, third parties, us and its affiliates (e.g. if notification violates an order of law
                or regulatory authority, if investigation is obstructed, if this service is compromised).
            </p>
            <p>
                <strong>Consent to the Terms and Conditions</strong>
            </p>
            <p>The following shall apply in preference to the provisions of Article 2.</p>
            <p>
                If you agree to this Agreement, your consent to the Privacy Policy will not be on the agenda, and you must agree to the Privacy Policy separately from your consent to this Agreement.
            </p>
        </div>
    );
}
