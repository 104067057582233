import React from "react";

export default function KoTerm231211({ theme }) {
    return (
        <div className={`terms-ko ${theme === "dark" ? "dark" : ""}`}>
            <p>최종 업데이트 일자: 2023년 12월 11일</p>
            <p>발효일: 2023년 11월 13일</p>
            <p>일반 규정 모든 유저</p>
            <ol type="1">
                <li>
                    <p>
                        <strong>당사와의 관계</strong>
                    </p>
                    <p>CELEBE KOREA CO., LTD 또는 그 계열사 (이하 통칭하여 “셀러비” 또는 “당사”)가 제공하는 셀러비 (이하 “본 플랫폼”)에 오신 것을 환영합니다.</p>
                    <p>
                        본 약관은 당사 관련 웹사이트, 서비스, 어플리케이션, 제품 및 콘텐츠(이하 통칭하여 “본 서비스”) 이용을 위하여 귀하와 당사의 관계를 규정하는 서비스약관(이하 “본 약관”)입니다.
                        당사의 본 서비스는 사적이고 비상업적인 목적을 위하여 제공됩니다. 본 약관에서 “귀하”란 귀하와 기타 본 서비스 이용자들을 지칭합니다.
                    </p>
                    <p>본 약관은 귀하와 당사 사이의 법적 효력이 있는 계약입니다. 이하 내용은 충분한 시간을 가지고 주의 깊게 읽어주십시오.</p>
                </li>
                <li>
                    <p>
                        <strong>약관의 동의</strong>
                    </p>
                    <p>
                        본 약관은 귀하가 본 약관에 동의하고 관련 서비스 이용 요청을 전송하여 당사가 해당 요청을 수락하면 효력이 발생합니다. 또한 당사 서비스에 접근하고 이를 이용하는 경우, 당사
                        커뮤니티 가이드라인 및 커뮤니티 정책을 준수하여야 하며, 이는 본 플랫폼에서 직접 확인하시거나 본 플랫폼을 다운로드 할 수 있는 곳, 모바일 기기의 관련 앱 스토어에서 확인하실 수
                        있으며, 본 약관에도 참조로 포함되어 있습니다.
                    </p>
                    <p>
                        하기 각 관할권의 이용자들에게 해당하는 추가 약관은 귀하가 해당 약관에 동의하여 관련 서비스 이용 요청을 전송하여 당사가 해당 요청을 수락하면 효력이 발생합니다. 귀하가 본
                        서비스에 접속 또는 이용하는 권할 지역과 관련된 추가 약관 관할 지역에 따른 조항과 본 약관의 나머지 규정이 상충하는 경우, 해당 관할 지역의 추가 약관 관할 지역에 따른 조항이
                        우선합니다. 본 약관에 동의하지 않는다면, 당사의 본 서비스에 접속 또는 이를 이용하여서는 안 됩니다. 본 서비스를 이용하는 경우, 귀하는 개인정보처리방침의 규정에 동의하는
                        것입니다.
                    </p>
                    <p>
                        귀하가 회사 또는 단체를 위하여 본 서비스에 접속 또는 이를 이용하고 있다면, (a) 이하에서 “귀하”는 해당 회사 또는 단체를 포함하고 (b) 귀하는 본 약관에 구속력을 부여할 수 있는
                        권한을 가지고 있는 자로서, 회사 또는 단체의 수권된 대표자이며, 해당 단체를 대표하여 본 약관에 동의함을 표명하고 보증하며 (c) 귀하의 회사 또는 단체는 법적, 재정적으로 본
                        서비스의 접속 및 이용을 비롯하여 귀하와 직원, 대리인, 계약자 등 귀하와 관련된 사람들이 귀하의 계정에 접속하거나 이를 사용하는 것에 대하여 책임이 있습니다.
                    </p>
                    <p>귀하는 귀하의 향후의 서비스 접속 및 이용이 약관의 동의로 간주된다는 점에 대하여 이해하며 동의합니다.</p>
                    <p>귀하는 보관을 위하여 본 약관의 복사본을 출력하거나 저장하여야 합니다.</p>
                </li>
                <li>
                    <p>
                        <strong>약관의 변경</strong>
                    </p>
                    <p>
                        당사는 본 서비스의 기능을 업데이트하는 경우, 당사 혹은 계열회사가 운영하는 여러 앱이나 서비스를 한 개의 종합 서비스나 앱으로 통합하는 경우, 규제가 변경되는 경우 등에 수시로
                        변경합니다. 당사는 본 약관이 중대하게 변경되는 경우 상업적으로 합리적인 노력을 다하여 본 플랫폼 상의 공지사항 등을 통하여 이러한 변경을 모든 이용자에게 일반적으로 통지할
                        것입니다. 다만, 귀하는 변경 여부를 확인하기 위하여 본 약관을 주기적으로 보아야 합니다. 귀하가 약관의 변경 이후에 계속하여 본 서비스에 접속하거나 이를 이용하는 것은 변경된
                        약관에 대하여 동의하는 것입니다. 당사의 새로운 약관에 동의하지 않는다면, 본 서비스 접속 또는 이용을 중지하시기 바랍니다.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>계정</strong>
                    </p>
                    <p>
                        본 서비스 중 일부에 접속하거나 이를 이용하기 위하여서는 계정을 생성하여야 합니다. 귀하는 계정 생성 시 정확한 최신의 정보를 제공하여야 합니다. 귀하의 정보를 최신 정보로 완전하게
                        유지하기 위하여서는, 당사에게 제공한 귀하의 상세정보 및 기타 정보를 관리하고 신속하게 업데이트하는 것이 중요합니다.
                    </p>
                    <p>
                        귀하는 계정 비밀번호를 비밀로 유지하고 이를 제3자에게 공개하여서는 안 됩니다. 제3자가 귀하의 비밀번호를 알고 있거나, 귀하의 계정에 접속한 사실을 알거나 의심하는 경우라면, 즉시
                        셀러비앱 Q&amp;A를 통해 당사에 알려야 합니다.
                    </p>
                    <p>
                        귀하는 자신의 계정을 통하여 이루어지는 활동에 관하여 (당사와 다른 이용자들에 대하여) 전적으로 책임을 진다는 점에 동의합니다 (단, 셀러비의 고의 또는 중과실로 인하여 야기되는
                        경우는 제외합니다).
                    </p>
                    <p>
                        당사는 귀하가 본 약관을 준수하지 않았다고 판단할 합리적인 사유가 있는 경우 또는 귀하의 계정에서의 활동이 본 서비스에 대한 피해 또는 손상을 발생시키거나, 여하한 제3자의 권리를
                        침해하였거나, 여하한 관련 법령 또는 규제에 위반하였거나 그러할 가능성이 있다고 판단되는 경우, 귀하의 계정을 사용할 수 없도록 할 권리가 있습니다.
                    </p>
                    <p>
                        귀하가 더 이상 본 서비스를 이용하지 않고 계정 삭제를 원하는 경우, 당사가 이를 처리하여 드릴 수 있습니다. 셀러비앱 Q&amp;A를 통해 당사에 연락하시면, 귀하를 도와 드리고 관련
                        절차를 안내하여 드리겠습니다. 일단 계정을 삭제하면 귀하는 계정을 재 활성화할 수 없으며 귀하가 추가한 콘텐츠 혹은 정보를 불러올 수 없습니다.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>본 서비스의 접속 및 이용</strong>
                    </p>
                    <p>
                        귀하는 본 약관과 관련 법령 및 규제를 준수하여 본 서비스에 접속하고 이를 이용합니다. 귀하는 다음과 같은 행위를 하여서는 안 됩니다.본 약관의 동의가 가능하지 않거나 이에 동의할
                        법적 능력이 없음에도 서비스에 접속하거나 이를 이용하는 것
                    </p>
                    <ul>
                        <li>
                            파일, 표, 문서 (또는 그 일부) 등 서비스에 기반한 2차적 저작물을 무단으로 복사, 수정, 각색, 번역, 역설계, 해체, 디컴파일 또는 생성하거나, 본 플랫폼이나 2차적 저작물을
                            구현하는 소스코드, 알고리즘, 수단 또는 기술을 알아내거나 추출을 시도하는 것;
                        </li>
                        <li>본 서비스나 그 2차적 저작물의 전부 또는 일부를 배포, 라이선스, 전송 또는 판매하는 것</li>
                        <li>본 서비스를 유료로 판매, 대여, 또는 리스하거나 본 플랫폼을 광고 또는 상업적 홍보를 위하여 이용하는 것</li>
                        <li>상업적 광고나 홍보 또는 스팸의 전달 혹은 촉진 등 당사의 명시적 서면 동의 없이 상업적 목적이나 무단으로 서비스를 이용하는 것</li>
                        <li>
                            본 서비스의 정상적인 작동을 방해하거나 방해를 시도하는 것, 당사의 웹사이트나 본 서비스에 연결된 여하한 네트워크를 방해하는 것, 또는 본 서비스에의 접근을 막거나 제한하는
                            당사의 모든 조치를 우회하는 것
                        </li>
                        <li>
                            본 플랫폼이나 그 일부를 다른 프로그램이나 제품에 통합시키는 것. 이 경우, 당사는 재량에 의하여 본 서비스 제공을 거부하거나, 계정을 삭제하거나, 서비스에 대한 접근을 제한할 수
                            있는 권한이 있습니다.
                        </li>
                        <li>본 서비스로부터 정보를 수집하기 위하여, 또는 서비스와 상호작용하기 위하여 자동화된 스크립트를 이용하는 것</li>
                        <li>
                            다른 사람이나 단체를 사칭하거나, 귀하가 업로드, 게시, 전송, 배포하거나 이용 가능하도록 한 콘텐츠가 본 서비스로부터 유래되었다는 인상을 주는 등 귀하에 관하여, 또는 다른
                            사람이나 단체와 귀하의 관계에 관하여 거짓을 명시하거나 잘못 표시하는 경우
                        </li>
                        <li>타인을 협박하거나 괴롭히거나, 외설적인 자료, 폭력성 또는 성별, 종교, 국적, 장애, 성적 취향이나 나이에 기반한 차별을 홍보하는 것</li>
                        <li>셀러비의 승인 없이 타인의 계정, 서비스 또는 시스템을 이용하거나 이용을 시도하는 것, 또는 본 서비스 상에서 거짓 신원을 만드는 것</li>
                        <li>다른 사용자들과 리뷰를 거래하거나, 거짓 리뷰를 작성, 요구하는 등 본 서비스를 통하여 이익의 충돌을 발생시키거나 본 서비스의 목적을 잠식하는 방법으로 이용하는 것</li>
                        <li>
                            이하에 규정된 대상을 업로드, 전송, 배포, 저장 기타 이용 가능하게 하기 위하여 본 서비스를 이용하는 것
                            <ul>
                                <li>바이러스, 트로이 목마, 웜, 논리 폭탄, 기타 악성 또는 기술적으로 유해한 것을 포함하는 파일</li>
                                <li>요청되지 않았거나 승인 받지 않은 광고, 권유, 광고성 자료, “정크 메일”, “스팸 메일”, “체인 레터”, “다단계” 또는 기타 허용되지 않는 권유</li>
                                <li>제3자의 주소, 전화번호, 이메일 주소, 개인 식별 문서(국가 보험 번호, 여권 번호 등)의 번호나 특징 또는 신용카드 번호 등 개인정보</li>
                                <li>타인의 저작권, 상표권, 또는 기타 지식재산권이나 프라이버시권을 침해하거나 침해할 수 있는 콘텐츠</li>
                                <li>타인의 명예를 훼손하거나, 음란하거나, 모욕적이거나, 포르노물이거나 혐오스럽거나 선동적인 자료;</li>
                                <li>형사상의 범죄, 위험한 행위 또는 자해행위를 구성, 장려, 또는 지도하는 자료</li>
                                <li>
                                    의도적으로, 특히 트롤링 및 괴롭힘 등으로 사람들을 자극하거나 적대감을 일으키기 위하여, 또는 괴롭히거나, 위해를 가하거나, 상처를 주거나, 무섭게 하거나, 고통스럽게
                                    하거나, 난처하게 하거나 불편하게 하기 위하여 제작한 자료
                                </li>
                                <li>물리적 폭력을 내용으로 하는 협박 등 기타 협박을 포함하는 자료</li>
                                <li>인종, 종교, 나이, 성별, 장애, 또는 성적 취향에 따른 차별 등 인종주의적이거나 차별적인 모든 자료</li>
                                <li>필요한 정식 라이선스를 받지 않았거나 자격 없이 제공되는 답변, 대응, 코멘트, 의견, 분석 또는 권유</li>
                                <li>
                                    셀러비의 단독 판단에 따라 불쾌하고 부적절한 것, 타인의 본 서비스 이용을 제한하거나 금지하는 것, 또는 셀러비의 본 서비스 또는 그 이용자를 여하한 유형의 손해나 책임에
                                    노출시키는 자료
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p>위 내용 이외에도 귀하의 본 서비스 접속 및 이용은 언제든지 커뮤니티 가이드라인에 부합하여야 합니다.</p>
                    <p>
                        당사는 사전 공지 없이 언제든지 당사의 합리적 재량으로 필요하다고 판단하는 경우 콘텐츠를 삭제하거나 콘텐츠로의 접근을 차단할 수 있습니다. 콘텐츠의 삭제 및 접근 차단의 이유에는
                        콘텐츠가 부적절하거나, 본 약관 또는 커뮤니티 가이드라인을 위반하였거나, 기타 본 서비스 및 본 서비스의 이용자들에게 유해하다고 판단되는 경우 등이 있을 수 있습니다. 당사의
                        자동화된 시스템은 맞춤화된 검색 결과, 맞춤화된 광고와 같이 귀하에게 개인적으로 관련된 제품 특성을 제공하거나, 스팸 또는 악성코드 감지를 위하여 귀하의 콘텐츠(이메일을
                        포함합니다)를 분석합니다. 이러한 분석은 콘텐츠가 송신, 수신 및 저장될 때에 이루어집니다.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>지식재산권</strong>
                    </p>
                    <p>
                        당사는 지식재산권을 존중하며, 귀하도 마찬가지로 이를 존중하여 주실 것을 요청합니다. 귀하는 본 서비스에 접속하고 이를 이용하는 조건으로, 어떠한 지식재산권도 침해하지 않는
                        방식으로 본 서비스에 접속하고 이를 이용하는 것에 동의합니다. 당사는 사전공지를 하거나 하지 않고, 언제나, 당사의 합리적 재량으로, 저작권 기타 지식재산권을 침해하거나 침해한다는
                        주장이 있는 계정에 대한 접근을 막는 조치 및/또는 삭제 조치를 할 권리가 있습니다.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>콘텐츠</strong>
                    </p>
                    <ol type="a">
                        <li>
                            <p>
                                <strong>셀러비 콘텐츠</strong>
                            </p>
                            <p>
                                귀하와 셀러비 사이에서, 본 서비스의 모든 콘텐츠, 소프트웨어, 이미지, 문서, 그래픽, 일러스트레이션, 로고, 특허, 상표, 서비스표, 저작권, 사진, 음원, 비디오, 서비스의 음악
                                및 “보고 느끼는 것(look and feel)”, 그리고 그와 관련된 모든 지식 재산권(이하 “셀러비 콘텐츠”)은 셀러비의 소유이거나 셀러비에게 라이선스가 있으며, 귀하가 본 서비스를
                                통해 업로드하거나 전송하는 이용자 콘텐츠(이하에 정의됩니다)는 귀하나 귀하의 라이선서의 소유입니다. 본 약관에서 명시적으로 허용된 것 이외의 목적으로 셀러비 콘텐츠나 본
                                서비스의 자료를 이용하는 것은 엄격하게 금지됩니다. 위 콘텐츠는 어떠한 목적 하에서도 다운로드, 복사, 재생산, 배포, 전송, 방송, 게시, 판매, 라이선스 또는 기타의 방식으로
                                당사의, 또는 특정한 경우 당사의 라이선서의 사전 서면 동의가 없는 한 이용될 수 없습니다. 당사 및 당사의 라이선서는 위 콘텐츠에 대하여 명시적으로 부여되지 않은 모든
                                권리를 보유합니다.
                            </p>
                            <p>
                                귀하는 당사가 (광고, 스폰서, 홍보, 이용 정보 및 기프트(이하에 정의됩니다) 등을 통하는 것을 포함하되 이에 한정되지 아니하는 방법으로) 귀하의 본 서비스 이용으로부터
                                수익을 창출하거나 영업권을 강화하거나 기타 당사의 가치를 증대할 수 있으며, 본 약관 또는 당사와 체결한 다른 계약에서 당사가 구체적으로 승인한 경우를 제외하고는 여하한
                                수익, 영업권 또는 가치 등을 당사와 공유할 권리가 없음을 인정하고 이에 동의합니다. 나아가 귀하는 본 약관 또는 당사와 체결한 다른 계약에서 당사가 구체적으로 승인한 경우를
                                제외하고는 (i) 이용자 콘텐츠(이하에 정의됩니다)를 통하여 또는 귀하가 제작한 이용자 콘텐츠를 포함하여 본 서비스에서 또는 이를 통하여 귀하가 이용할 수 있는 음악 작업,
                                사운드 레코딩 또는 시청각 클립을 귀하가 사용함으로써 발생하는 여하한 이익이나 기타 대가를 받을 권리가 없으며 (ii) 본 서비스 또는 제3자 서비스상의 이용자 콘텐츠를
                                현금화하거나 대가를 받을 권리의 행사가 금지된다는 것(예를 들어, 귀하는 유튜브 등 소셜미디어 플랫폼에 업로드된 이용자 콘텐츠에 대하여 현금화를 요구할 수 없습니다)을
                                인정합니다.
                            </p>
                            <p>
                                본 약관의 규정에 따라, 귀하는 허용된 기기에 본 플랫폼을 다운로드하고 본 서비스를 통하여 본 약관에 따라 귀하의 개인적이고 비상업적인 용도로만 셀러비 콘텐츠를 이용하는
                                등, 본 서비스 접속 및 이용에 관하여 비독점적이고, 제한적이고, 양도할 수 없고, 서브라이선스를 허여할 수 없으며, 취소 가능한 전세계에서의 라이선스를 보유합니다. 셀러비는
                                본 서비스와 셀러비 콘텐츠에 대하여 본 약관에서 명시적으로 표현되지 않은 모든 권리를 보유합니다. 귀하는 셀러비가 위 라이선스를 합리적 재량에 따라 필요하다고 판단하는
                                경우 종료할 수 있음을 인지하고 동의합니다.
                            </p>
                            <p>본 서비스로부터 또는 본 서비스를 통하여 이용 가능한 사운드 레코딩 및 이에 포함된 음악 작업과 관련하여서는, 어떠한 권리에도 라이선스가 허여되지 않습니다.</p>
                            <p>
                                귀하는 본 서비스를 이용하는 다른 이용자에 의하여 제공된 콘텐츠를 보는 것이 귀하의 리스크 하에서의 행위임을 인지하고 이에 동의합니다. 본 서비스의 콘텐츠는 일반적 정보의
                                제공만을 위한 것입니다. 본 서비스는 귀하가 의존할 수 있는 조언을 제공하기 위하여 의도된 것이 아닙니다. 귀하는 본 서비스의 콘텐츠에 따른 행동을 하거나 하지 않기 이전에
                                전문적인 조언을 받아야 합니다.
                            </p>
                            <p>
                                당사는 셀러비 콘텐츠(이용자 콘텐츠도 포함합니다)가 정확하거나, 완전하거나 최신의 것임을 명시적 또는 묵시적으로 진술하거나 보증하지 않습니다. 본 서비스가 다른 사이트 및
                                제3자가 제공하는 자료에 대한 링크를 포함하고 있는 경우, 이러한 링크는 귀하의 정보를 위하여 제공되는 것입니다. 당사는 위 사이트나 자료에 대하여 관리 권한이 없습니다.
                                위와 같은 링크는 링크된 웹사이트나 정보를 수집하여도 된다는 당사의 승인으로 해석되어서는 안 됩니다. 귀하는 당사가 귀하 및 본 서비스의 다른 이용자가 게시한 콘텐츠(이용자
                                콘텐츠를 포함합니다)를 사전적으로 검열하거나, 감시, 검토, 또는 수정할 의무가 없음을 인지합니다.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>이용자 제작 콘텐츠</strong>
                            </p>
                            <p>
                                본 서비스 이용자는 개인 음악 라이브러리에서 로컬에 저장된 사운드 레코딩과 주변 소음을 결합하는 동영상을 포함하여 모든 텍스트, 사진, 이용자 동영상, 사운드 레코딩, 이에
                                포함된 음악 작업 등을 포함하나 이에 한정되지 않는 본 서비스를 통하여 이용 가능한 콘텐츠(이하 “이용자 콘텐츠”)를 업로드, 게시 또는 전송(예: 스트림을 통하여 등)하는 것이
                                허용됩니다. 또한 본 서비스 이용자는 한 명 이상의 이용자가 제작한 이용자 콘텐츠를 통합하고 배치하여 다른 이용자들과의 공동 이용자 콘텐츠 등 부가적인 이용자 콘텐츠를
                                제작하기 위하여 다른 이용자가 제작한 이용자 콘텐츠의 전부 혹은 일부를 추출할 수도 있습니다. 본 서비스의 이용자는 셀러비가 제공하는 음악, 그래픽, 스티커, 가상
                                아이템(“추가 약관-가상 아이템 정책”에서 정의하고 설명합니다) 및 기타 요소들(이하 “셀러비 요소”)을 위 이용자 콘텐츠에 입힐 수 있고, 본 서비스를 통하여 위 이용자 콘텐츠를
                                전송할 수 있습니다. 셀러비자료가 포함된 이용자 컨텐츠 등 모든 이용자 컨텐츠의 정보와 자료들은 당사에 의하여 증명되거나 승인되지 않았습니다. 본 서비스(가상 기프트의 사용
                                포함)에 게시되는 다른 이용자들의 자료는 당사의 의견 또는 가치를 대표하는 것이 아닙니다.
                            </p>
                            <p>
                                귀하가 본 서비스를 통하여(인스타그램, 페이스북, 유튜브, 트위터 등 제3자 소셜미디어 플랫폼을 통하는 것을 포함합니다) 이용자 콘텐츠를 업로드하거나 전송하는 기능, 또는 본
                                서비스의 다른 이용자들과 접촉하는 기능에 접속하거나 이를 이용하는 경우, 위 “본 서비스의 접속 및 이용” 규정에 명시된 기준을 준수하여야 합니다. 귀하는 셀러비 요소를
                                포함하는 이용자 콘텐츠 등 귀하의 이용자 콘텐츠를 제3자의 사이트나 플랫폼에 업로드하거나 전송하는 것을 선택할 수 있습니다. 이 때, 귀하는 제3자의 콘텐츠 가이드라인과 위
                                “본 서비스의 접속 및 이용” 규정의 기준을 준수하여야 합니다.
                            </p>
                            <p>
                                귀하는 이러한 모든 기여가 위 기준에 준수함을 보증하고, 위 보증 사항의 위반 시에 책임을 부담하며 그 손해를 배상하여야 합니다. 이는 귀하의 보증 사항 위반에 의하여 발생한
                                당사의 모든 손해에 대하여 귀하에게 책임이 있다는 것을 의미합니다.
                            </p>
                            <p>
                                모든 이용자 콘텐츠는 비밀이 아니며, 비전유적인 것으로 간주됩니다. 귀하가 비밀이거나 전유적이라고 생각하는 이용자 콘텐츠는 본 서비스에 또는 이를 통하여 게시하거나 당사에
                                전송하여서는 안 됩니다. 본 서비스를 통하여 이용자 콘텐츠를 제출하는 경우, 귀하는 해당 이용자 콘텐츠의 소유자이거나, 콘텐츠의 모든 소유자로부터 필요한 모든 승인 및
                                허가를 받았거나 해당 소유자로부터 권한을 위임 받아 콘텐츠를 본 서비스에 제출하고 본 서비스에서 다른 제3자 플랫폼으로 전송 및/또는 제3자 콘텐츠를 채택하였음을 동의하고
                                표명합니다.
                            </p>
                            <p>
                                귀하가 사운드 레코딩에 대한 권리만 보유하고 해당 사운드 레코딩에 포함된 기본 음악에 대한 권리는 없는 경우, 귀하가 해당 콘텐츠의 소유자로부터 모든 승인 및 허가를
                                받았거나 해당 소유자로부터 권한을 위임 받아 콘텐츠를 서비스에 제출하는 경우를 제외하고 귀하는 해당 사운드 레코딩을 본 서비스에 게시하여서는 안 됩니다.
                            </p>
                            <p>
                                당사에게 전송한 이용자 콘텐츠의 저작권은 귀하 또는 귀하의 이용자 콘텐츠의 소유자에게 있으나, 귀하는 본 약관에 동의하여 본 서비스를 통하여 이용자 콘텐츠를 제출함으로써,
                                모든 형식의 모든 플랫폼에 있는 현재의 또는 이후에 제작될 귀하의 이용자 콘텐츠에 대하여 이용, 수정, 각색, 복제, 2차적 저작물 작성, 출판 및/또는 전송 및/또는 배포할 수
                                있도록 하는, 또한 본 서비스의 다른 이용자들과 기타 제3자가 위 콘텐츠를 검토, 접근, 이용, 다운로드, 수정, 각색, 복제, 2차적 저작물 작성, 출판 및/또는 전송할 수 있도록
                                하는 무조건적이고, 취소할 수 없고, 비독점적이고, 로열티가 없으며, 양도 가능하고 영구적인 전세계적 라이선스를 당사에게 제공한다는 점에 동의합니다.
                            </p>
                            <p>나아가 귀하는 당사에게 귀하를 이용자 콘텐츠 원작자로 식별할 수 있는 이용자 이름, 이미지, 음성 및 유사성을 이용하는 로열티가 없는 라이선스를 부여합니다.</p>
                            <p>
                                의심의 여지를 피하기 위하여, 본 조항의 직전 문단에서 설명 드린 권리는 사운드 레코딩을 복제(및 해당 사운드 레코딩에 포함된 음악 작업의 기계적 복제)할 권리, 공개 공연할
                                권리, 공개 사운드 레코딩(및 이에 포함된 음악 작업)에 소통할 권리를 포함하나 이에 한정되지 않으며, 모두 로열티가 없습니다. 이는 귀하가 사운드 레코딩 저작권 소유자(예:
                                음반 회사), 음악 작업물 저작권 소유자(예: 음반 출판사), 공연권을 보유한 기구(예: ASCAP, BMI, SESAC 등)(이하 “PRO”), 사운드 레코딩 PRO(예: SoundExchange), 이용자 콘텐츠
                                제작에 관련된 모든 조합 또는 협회, 엔지니어, 프로듀서 또는 기타 로열티 참여자를 포함하나 이에 한정되지 않는 모든 제3자에게 로열티를 지급할 의무 없이 귀하의 이용자
                                콘텐츠를 이용할 권리를 당사에게 허여한다는 것을 의미합니다.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>음악 작업 및 음반 예술가에 대한 특정 규칙</strong>
                            </p>
                            <p>
                                귀하가 PRO와 관련된 음악 작업의 작곡가 혹은 저작자인 경우, 귀하는 이용자 콘텐츠에서 본 약관을 통하여 귀하가 당사에 허여하는 로열티 없는 라이선스에 관하여 PRO에게
                                알려주어야 합니다. 귀하는 단독으로 해당 PRO에 대한 통지 의무를 준수할 책임을 집니다. 귀하가 음반 출판사에게 귀하의 권리를 양도한 경우, 귀하의 이용자 콘텐츠에 본 약관에
                                규정된 로열티 없는 라이선스를 허여하거나 해당 음반 출판사로 하여금 당사와 본 약관을 체결하는 것에 대하여 해당 음반 출판사의 동의를 구하여야 합니다. 귀하가 음악 작업(예:
                                작사)을 하였다는 것이 귀하가 본 약관의 라이선스를 당사에 허여할 권리를 가진다는 것을 의미하는 것은 아니기 때문입니다. 귀하가 음반 회사와 계약을 체결한 음반 예술가인
                                경우, 귀하는 본 서비스를 통하여 귀하의 음반 회사가 권리요청을 할 수 있는 새로운 음반을 제작하는 경우를 포함하여, 귀하가 음반 회사에 대하여 부담할 수 있는 계약상 의무에
                                부합하여 본 서비스를 이용하는 것에 대해 단독으로 책임을 집니다.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>관중에 대한 권리</strong>
                            </p>
                            <p>
                                귀하가 이용자 콘텐츠에 부여한 모든 권리는 본 약관에서 관중에 대한 권리를 기반으로 하며, 이는 제3자 서비스의 소유자 혹은 운영자는 본 서비스를 통하여 해당 제3자 서비스에
                                게시되거나 제3자 서비스에서 사용되는 이용자 콘텐츠에 대하여 귀하 혹은 기타 제3자에게 별도의 책임을 지지 않는다는 것을 의미합니다.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>이용자 콘텐츠에 대한 권리 포기</strong>
                            </p>
                            <p>
                                이용자 콘텐츠를 본 서비스에 또는 본 서비스를 통하여 게시함으로써, 귀하는 해당 이용자 콘텐츠와 관련된 마케팅 또는 홍보 자료에 대한 사전 검열 또는 승인에 대한 권리를
                                포기합니다. 또한 귀하는 귀하의 이용자 콘텐츠 또는 그 일부에 대한 개인정보 및 홍보 관련 권리 또는 기타 유사한 권리의 전부를 포기합니다. 도덕적 권리가 양도 불가능한
                                범위에서, 귀하는 본 서비스에 또는 본 서비스를 통하여 게시하는 여하한 이용자 콘텐츠에서 또는 이와 관련하여 모든 인격적 권리를 포기하고 귀하가 보유한 여하한 인격적 권리를
                                기반으로 하는 행동을 지원, 유지, 승인하거나 이를 주장하지 않는다는 것에 동의합니다.
                            </p>
                            <p>
                                당사는 귀하가 본 서비스에 게시하거나 업로드한 이용자 콘텐츠가 제3자의 지식재산권이나 프라이버시권을 침해하였다는 피해 당사자의 주장이 있는 경우, 귀하의 신원을 해당
                                당사자에게 공개할 권리가 있습니다.
                            </p>
                            <p>
                                당사, 또는 권한 있는 제3자는 재량에 따라 귀하의 콘텐츠를 잘라내거나 편집할 수 있으며, 이를 게시하는 것을 거부할 수 있습니다. 당사는 귀하의 본 서비스상의 포스팅이 위 “본
                                서비스의 접속 및 이용” 규정상의 콘텐츠 기준을 준수하지 않았다고 판단하는 경우 귀하의 여하한 콘텐츠를 제거, 불허, 차단, 삭제할 권리가 있습니다. 나아가 당사는 단독
                                재량으로 (i) 본 약관을 위반하였다고 판단되는 이용자 콘텐츠의 경우, 또는 (ii) 다른 이용자나 제3자의 민원에 대한 응대로, 통지 혹은 통지 없이 귀하에 대한 책임을 지지 않고
                                해당 콘텐츠를 제거, 불허, 차단 또는 삭제할 의무가 아닌 권리를 가집니다. 이에 따라, 당사는 귀하가 해당 이용자 콘텐츠에 영구적으로 접근하기를 원하는 경우, 본 서비스에
                                게시하는 이용자 콘텐츠의 사본을 귀하의 개인 기기에 저장할 것을 권장합니다. 당사는 여하한 이용자 콘텐츠의 정확성, 완전성, 적절성 또는 품질을 보장하지 않으며 이용자
                                콘텐츠에 대하여 어떠한 상황에서도 어떠한 방식으로든 책임을 지지 않습니다.
                            </p>
                            <p>
                                귀하는 본 서비스에서 다른 모든 이용자들이 귀하의 이용자 콘텐츠를 공개적으로 이용할 수 있는지, 아니면 귀하가 허용한 사람들만 이용할 수 있는지를 관리할 수 있습니다.
                                귀하의 이용자 콘텐츠에 대한 접근을 제한하기 위하여서는 본 플랫폼에서 사생활보호 설정을 선택해야 합니다.
                            </p>
                            <p>
                                당사는 이용자로부터 제출되어 당사 또는 권한 있는 제3자가 게시한 어떠한 콘텐츠에 대하여서도 책임을 지지 않습니다(단, 셀러비의 고의 또는 중과실로 인하여 야기된 경우는
                                제외합니다).
                            </p>
                            <p>다른 이용자에 의하여 업로드된 정보에 관하여 불만사항이 있는 경우, 고객센터의 문의하기를 통해 연락하시기 바랍니다.</p>
                            <p>
                                셀러비는 권리를 침해하는 자료에 대하여 인지하는 경우, 이를 본 서비스에서 신속히 제거하기 위하여 합리적인 조치를 취합니다. 타인의 저작권이나 지식재산권을 지속적으로
                                침해하는 본 서비스 이용자들의 계정을 적절한 상황에서 그 재량으로 비활성화하거나 해지하는 것은 셀러비의 정책입니다.
                            </p>
                            <p>
                                당사의 직원은 지속적으로 자체 제작 아이디어 및 기능을 개발∙평가하고 있으며, 당사는 이용자 커뮤니티로부터 받는 관심, 피드백, 의견 및 제안에 상당한 주목을 하는 것에
                                스스로 자부심을 가지고 있습니다. 귀하가 당사 또는 당사의 직원에게 제품, 서비스, 기능, 변경, 향상, 콘텐츠, 개선, 기술, 콘텐츠(청각, 시각, 게임 또는 기타 유형의 콘텐츠
                                등)의 제공, 홍보, 전략 또는 제품/기능 명칭 또는 관련 문서작업, 삽화, 컴퓨터 코드, 다이어그램 또는 기타 자료(이하 총칭하여 “피드백”)에 대한 아이디어를 송부하는 경우,
                                귀하의 소통 내용과 상관없이, 향후 오해의 소지를 피하기 위하여 아래 조항이 적용될 것입니다. 따라서 귀하는 당사에 피드백을 송부함으로써 다음 사항에 동의합니다.
                            </p>
                            <ol type="i">
                                <li>
                                    <p>셀러비는 귀하의 피드백을 검토, 고려 또는 시행할 의무가 없으며 어떠한 이유로든 피드백의 전부 또는 일부에 대하여 회신할 의무가 없습니다.</p>
                                </li>
                                <li>
                                    <p>
                                        피드백은 비밀로서 제공되지 아니하며, 당사는 귀하가 송부하는 피드백을 비밀로 유지하거나 어떠한 방법으로든 피드백을 이용하거나 공개하는 것을 자제할 의무가
                                        없습니다.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        귀하는 피드백 및 그 2차적 저작물과 관련하여, 여하한 목적으로, 제한 없이, 무료로, 그리고 전체 또는 일부인지, 제공된 그대로인지 또는 변경된 상태인지 여부를
                                        불문하고, 피드백에 포함 내지 구현된 상업적 저작물 및 서비스를 제작, 이용, 판매, 청약, 수입 및 홍보하는 등 여하한 유형으로 복제, 배포, 2차적 저작물 작성, 수정,
                                        공연(관중 기반을 포함합니다), 대중과의 소통, 가용화, 공개 전시, 그 밖의 이용 및 활용할 수 있는 계속적이고 무제한적인 승인을 당사에게 취소불능적으로 허여합니다.
                                    </p>
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                        <strong>포인트</strong>
                    </p>
                    <ol type="a">
                        <li>
                            <p>포인트 적립</p>
                            <p>셀러비포인트(이하 “포인트”)는 유저가 당사 및 제휴사의 적립 서비스 또는 이벤트 등 마케팅 활동 등과 관련하여 획득한 포인트 또는 당사를 통하여 구매한 포인트를 말합니다.</p>
                            <p>당사는 아래와 같은 방식으로 포인트를 산정, 부여합니다.</p>
                            <ol type="i">
                                <li>
                                    <p>팔로잉/추천 피드의 영상을 시청하는 경우</p>
                                </li>
                                <li>
                                    <p>나의 영상이 다른 유저에 의해 시청되는 경우</p>
                                </li>
                                <li>
                                    <p>출석체크를 완료하는 경우</p>
                                </li>
                                <li>
                                    <p>‘혜택’ 탭의 당사 및 제휴사 이벤트에 참여하는 경우</p>
                                </li>
                                <li>
                                    <p>추천인 링크를 통해 설치하여 회원가입을 한 경우 또는 내가 추천한 유저가 가입한 경우</p>
                                </li>
                                <li>
                                    <p>기타</p>
                                    <p>포인트 적립 방식 및 기준은 당사와 제휴사의 내부 방침에 따라 임의로 변경될 수 있습니다.</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>포인트 사용</p>
                            <p>
                                유저는 적립된 포인트를 팬시월렛을 이용하여 팬시 포인트로 교환할 수 있으며, 한 번의 교환시 15%의 수수료과 부과됩니다. 팬시포인트로의 교환 이후 팬시월렛 내 제휴된 다양한
                                포인트, 코인으로 교환이 가능합니다.
                            </p>
                            <p>
                                팬시월렛 내 제휴 내용은 다른 회사와의 협의상황에 따라 변경될 수 있습니다. 제휴 포인트의 교환 단가는 제휴처로부터의 공급금액, 지급 수수료, 운영 비용 등을 고려하여
                                산정됩니다.
                            </p>
                            <p>현재 셀러비포인트를 팬시포인트로 교환하기 위해서는 잔여 사용 가능 포인트가 최소 3,000P 이상이어야하며, 이 조건을 충족할 경우에만 교환이 가능합니다.</p>
                            <p>
                                만 14세 미만의 미성년자 유저가 포인트를 사용하는 경우, 최초 회원가입 시 법정대리인의 동의를 통해 회원가입을 완료한 것으로 포인트 사용에 대한 법정대리인 동의도 획득한
                                것으로 간주합니다.
                            </p>
                            <p>
                                유저는 포인트를 타인에게 양도하거나 대여 또는 담보의 목적으로 이용할 수 없습니다. 다만, 당사나 제휴사에서 인정하는 “포인트 선물하기” 등의 절차에 따른 경우는 예외로
                                합니다. “포인트 선물하기”에 따라 타 유저에게 양도 또는 증여 받는 포인트는 다시 타 유저에게 재양도는 불가하며 당사 정책에 따라 당해 포인트 사용 유효기간 및 양도 금액
                                한도/횟수의 제한이 있을 수 있습니다.
                            </p>
                        </li>
                        <li>
                            <p>포인트 정정, 취소 및 소멸</p>
                            <p>
                                적립된 포인트에 오류가 있을 경우 유저는 오류 발생 시점부터 60일 이내에 당사에 정정 신청을 하여야 하며, 당사는 유저의 정정 신청일로부터 최대 90개월 이내에 정정 절차를
                                마쳐야 합니다. 단, 유저는 필요한 경우 포인트 적립 오류를 증명할 수 있는 객관적인 자료를 제시하여야 합니다.
                            </p>
                            <p>
                                포인트는 최초 무상으로 적립된 시점으로부터 12개월이 지난 시점의 해당 월 말일에 먼저 적립된 포인트부터 월 단위로 자동 소멸됩니다. 유상으로 충전된 포인트는 유효기간 및
                                소별예정일이 없습니다. 당사는 유저가 보유한 포인트를 소멸 예정일이 도래하여 소멸시키는 경우 포인트 소멸과 관련된 내용을 최소 15일 전에 푸시/문자 메시지 등으로
                                발송합니다. 단, 특정 이벤트를 통해 제공된 포인트는 이벤트 진행시 포인트 유효기간 및 소멸일을 별도로 안내하고, 소멸 안내 푸시/문자 메시지 발송에서는 제외될 수 있습니다.
                                또한, 유저가 당사 서비스에서 해당 월 소멸 예정 포인트 확인이 가능하도록 조치합니다.
                            </p>
                            <p>
                                유저의 구매로 인해 적립되는 포인트가 아닌 당사나 제휴사가 이벤트 등의 마케팅 활동으로 유저에게 추가로 제공한 포인트의 유효기간은 당사나 제휴사의 정책에 따라 별도로
                                책적될 수 있으며 이는 별도로 공지합니다.
                            </p>
                            <p>유저가 교환한 팬시포인트는 교환일 기준 3일 이내로만 환불이 가능하며, 환불 신청일 기준 7일 이내로 복원 됩니다.</p>
                        </li>
                        <li>
                            <p>회원 탈퇴 및 포인트 처리</p>
                            <p>탈퇴를 하고자 하는 유저가 잔여 포인트를 사용하고자 할 경우 회원 탈퇴 유정일 전까지 적립된 가용 포인트를 본 약관이 정하는 바에 따라 사용해야 합니다.</p>
                            <p>탈퇴 전까지 사용하지 않은 포인트는 일정한 유예기간 이후 자동으로 소멸됩니다.</p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                        <strong>배상</strong>
                    </p>
                    <p>
                        귀하는 귀하 또는 귀하의 계정(귀하에게 접근권한이 있는 계정)의 이용자가 본 약관, 또는 본 약관에 따른 귀하의 의무, 진술 및 보증사항의 위반으로 인하여 발생한 변호사 수수료와
                        비용을 포함한 일체의 청구, 책임 및 비용 및 경비로부터 셀러비, 그 모회사, 자회사, 계열사 그리고 위 각 회사의 임원, 직원, 대리인 및 자문가를 방어하고 면책시키며 해를 입히지 않을
                        것에 동의합니다.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>보증 사항의 제외</strong>
                    </p>
                    <p>본 약관에서 규정하는 사항은 귀하가 계약을 통하여 변경 또는 포기할 수 없는, 소비자에게 반드시 부여되는 법정 권리에는 영향을 미치지 않습니다.</p>
                    <p>본 서비스는 “있는 그대로의 상태”로 제공되며, 당사는 본 서비스에 관하여 귀하에게 진술하거나 보장하지 않습니다. 특히 당사는 귀하에게 이하의 사항을 진술, 보장하지 않습니다.</p>
                    <p>귀하의 본 서비스 이용이 귀하의 요구사항을 충족할 것</p>
                    <p>귀하의 본 서비스 이용에 방해, 지연, 보안 문제나 에러가 없을 것</p>
                    <p>귀하가 본 서비스를 이용하여 획득하는 모든 정보가 정확하고 믿을 수 있을 것</p>
                    <p>귀하에게 본 서비스의 일부로서 제공되는 소프트웨어의 작동 또는 기능의 결함이 수정될 것</p>
                    <p>
                        조건, 보증 또는 기타 규정(만족스러운 품질, 특정 목적을 위한 적합성, 또는 설명된 사항의 준수와 관련한 모든 묵시적 약관을 포함합니다)은 본 약관에서 명시적으로 표시되지 않은 이상
                        본 서비스에 적용되지 않습니다. 당사는 사업상, 운영상의 이유로 언제든 공지 없이 본 플랫폼의 일부 또는 전부를 변경, 지연, 철수시키거나 그 이용을 제한할 수 있습니다.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>책임의 제한</strong>
                    </p>
                    <p>
                        본 약관은 면책되거나 제한하는 것이 법적으로 금지된 당사의 손해배상책임을 면책시키거나 제한하지 않습니다. 사기 또는 사기적 허위 표시에 의한 당사의, 또는 당사 직원, 대리인 또는
                        수급사업자들의 과실에 의한 사망과 상해에 대한 책임에 대하여서도 마찬가지입니다.
                    </p>
                    <p>위 내용을 전제로, 당사는 귀하에 대하여 이하의 책임을 지지 않습니다.</p>
                    <ol type="I">
                        <li>
                            모든 영업상 수익의 손실(직접적 또는 간접적으로 발생한 손실을 포함합니다), (II) 모든 영업권의 손실, (III) 모든 기회의 손실, (IV) 귀하가 입은 모든 데이터의 손실, 또는 (V)
                            귀하에 의하여 발생한 모든 간접적, 또는 결과적인 손실. 그 밖의 모든 손실에 대한 보상은 귀하가 셀러비에 대하여 최근 12개월동안 지불한 범위 내로 제한됩니다.
                        </li>
                    </ol>
                    <p>이하의 상황에서 귀하로 인하여 발생한 모든 손실 또는 피해</p>
                    <p>광고의 완전성, 정확성 또는 존재에 대한 귀하의 신뢰, 또는 귀하와 본 서비스에 표시되는 광고의 광고주 또는 스폰서와의 관계나 거래의 결과로 생기는 귀하의 신뢰</p>
                    <p>당사가 본 서비스에 대하여 행한 모든 변경, 또는 영구적이거나 일시적인 서비스(또는 서비스 내의 기능)의 제공 중단</p>
                    <p>귀하의 본 서비스 이용에 의하여, 또는 이를 통하여 보존되거나 전송되는 모든 콘텐츠와 기타 통신 정보의 삭제, 변질, 저장 실패</p>
                    <p>귀하가 당사에게 정확한 계정정보를 주지 못함</p>
                    <p>귀하가 귀하의 비밀번호나 계정 정보의 보안유지 및 비밀유지에 실패함</p>
                    <p>
                        당사는 본 플랫폼을 가정용의, 사적인 이용을 위하여만 제공한다는 점을 유의하십시오. 귀하는 본 플랫폼을 상업적으로 또는 사업 목적으로 사용하지 않을 것에 동의하며, 당사는 귀하의
                        어떠한 영업상 이익의 손실, 사업상 손실, 영업권 또는 사업평판의 손실, 업무방해 또는 사업 기회의 손실에 대해서도 책임을 지지 않습니다.
                    </p>
                    <p>
                        당사가 하자가 있는 디지털 콘텐츠를 귀하에게 제공하여 귀하의 기기나 디지털 콘텐츠에 피해가 발생하였고, 위 피해가 합리적인 관리와 기술적 조치의 부족으로 발생한 것이라면, 당사는
                        귀하에게 해당 피해를 복구하거나 보상할 것입니다. 그러나 귀하가 무료로 제공되는 업데이트를 행하라는 당사의 조언을 따랐더라면 입지 않았을 피해나, 귀하가 설치 설명서를 정확하게
                        따르지 않거나 당사가 제공하는 최소한의 시스템요구사항을 지키지 못하여 발생한 손해에 대해서는 책임지지 않습니다.
                    </p>
                    <p>위와 같은 면책 사항은 당사가 해당 손실이 발생할 수 있다는 점에 대하여 조언을 받은 바 있거나 그 가능성에 대하여 인지하고 있었어야 했는지 여부와 무관하게 적용됩니다.</p>
                    <p>
                        귀하는 당사의 서비스를 이용함에 있어 부과될 수 있는 문자메시지 요금과 데이터요금을 포함한 모든 휴대전화 요금에 대하여 책임이 있습니다. 어떠한 요금이 적용되는지에 관하여
                        확실하지 않은 경우에는 본 서비스를 이용하기 전에 귀하의 휴대전화 서비스 사업자에게 문의하여야 합니다.
                    </p>
                    <p>
                        법령이 허용하는 최대 범위에서, 귀하의 본 서비스 이용으로 발생하는 제3자와의 분쟁(예를 들어 여하한 통신사, 저작권 소유자 또는 다른 이용자 등과의 분쟁을 포함하되 이에 한정되니
                        아니함)은 귀하와 해당 제3자 사이에 직접 존재하는 것이며, 귀하는 해당 분쟁으로부터 또는 이와 관련하여 발생하는 알려졌거나 알려지지 않은 모든 종류와 성질의 청구, 요구 및
                        손해(실제 손해 및 결과적 손해)로부터 당사 및 당사의 계열회사를 취소불능적으로 면책합니다.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>기타</strong>
                    </p>
                    <p>
                        <strong>오픈 소스</strong>
                    </p>
                    <p>본 플랫폼은 특정 오픈 소스 소프트웨어를 포함 합니다. 각 오픈 소스 소프트웨어 아이템은 해당 라이선스 조항에 의해 규율되며 그 내용은 오픈소스 정책에서 확인하실 수 있습니다.</p>
                    <p>
                        <strong>완전 합의</strong>
                    </p>
                    <p>
                        본 약관(하기 추가 약관 포함)은 귀하와 셀러비 사이의 완전한 법적 합의로서, 귀하의 본 서비스 이용을 규율하며, 본 서비스에 관하여 귀하와 셀러비 사이에 있었던 모든 기존의 합의를
                        대체합니다.
                    </p>
                    <p>
                        <strong>링크</strong>
                    </p>
                    <p>
                        귀하는 정당하고 법적인 방법으로, 당사의 평판을 손상시키거나 그로 인하여 이익을 취하지 않는 방식으로 당사의 홈페이지를 링크할 수 있습니다. 귀하는 당사의 제휴, 승인 또는 보증이
                        없음에도 불구하고 이를 시사하기 위한 수단으로 링크를 만들 수 없습니다. 귀하는 귀하가 소유하지 않는 웹사이트에 당사의 플랫폼 링크를 만들어서는 안 됩니다. 귀하가 링크를 만드는
                        웹사이트는 위의 “본 서비스의 접근 및 이용” 규정의 콘텐츠 기준을 모두 준수하여야 합니다. 당사는 사전 공지 없이 링크에 대한 승인을 철회할 권리가 있습니다.
                    </p>
                    <p>
                        <strong>연령 제한</strong>
                    </p>
                    <p>
                        본 서비스는 만 14세 이상의 사람들만을 대상으로 합니다 (추가 약관 관할 지역에 따른 조항에 규정된 추가 제한 사항을 포함합니다). 귀하는 본 서비스를 이용함으로써 귀하가 관련 기준
                        연령 이상임을 확인합니다. 만약 당사가 위 기준 연령 미만의 자가 본 서비스를 이용하고 있다는 것을 알게 된 경우, 당사는 해당 이용자의 계정을 해지할 것입니다.
                    </p>
                    <p>
                        <strong>권리포기 금지</strong>
                    </p>
                    <p>당사가 본 약관의 조항을 이행하지 않거나 집행하지 않는 것은 해당 조항이나 권리를 포기하는 것으로 해석되지 않습니다.</p>
                    <p>
                        <strong>보안</strong>
                    </p>
                    <p>
                        당사는 본 서비스의 보안상태나 본 서비스가 버그나 바이러스부터 안전하다는 점에 대하여 보증하지 않습니다. 귀하는 본 서비스에 접속할 때에 귀하의 정보 기술, 컴퓨터 프로그램과
                        플랫폼의 환경설정을 할 책임이 있습니다. 귀하는 귀하의 바이러스 보호 소프트웨어를 이용하여야 합니다.
                    </p>
                    <p>
                        <strong>가분성</strong>
                    </p>
                    <p>
                        관련 사안에 관하여 관할을 가지는 법원이나 법률에 따라 본 약관의 특정 조항이 무효가 되는 경우, 해당 조항은 본 약관에서 삭제되고 이는 기타 다른 조항들에 영향을 미치지 않으며,
                        삭제된 조항 이외의 약관 조항들은 여전히 유효하고 집행 가능합니다.
                    </p>
                    <p>
                        <strong>기타 문의 사항</strong>
                    </p>
                    <p>고객센터의 문의하기를 통해 연락하시기 바랍니다.</p>
                </li>
            </ol>

            <p>
                <strong>추가 약관 -앱스토어</strong>
            </p>
            <p>관련 법령이 허용하는 범위 내에서, 특정 기기를 통하여 본 플랫폼에 접속하는 경우 이하의 추가 약관이 적용됩니다.</p>
            <p>
                <strong>애플 관련 통지</strong>
            </p>
            <p>귀하가 애플 주식회사(이하 “애플”)가 제조한 기기에서 본 플랫폼을 다운로드할 때에는 이하의 사항에 대하여 인지하며 동의합니다.</p>
            <p>본 약관은 셀러비와 귀하 사이에 적용되는 것입니다. 애플은 본 약관의 당사자가 아닙니다.</p>
            <p>
                이하에서 귀하에게 주어지는 라이선스는 애플 기기 중 애플로부터 승인 받은 것으로서 애플의 앱스토어 이용약관에 규정된 이용 규칙을 준수하여 개인적·비상업적 이용 목적으로 소유하거나
                관리하는 기기에 본 플랫폼을 설치할 수 있는 개인적, 제한적, 비독점적, 양도 불가능한 권리입니다.
            </p>
            <p>애플은 본 플랫폼이나 본 플랫폼의 콘텐츠에 대하여 책임을 지지 않으며, 본 플랫폼과 관련된 지원 서비스를 제공하거나 유지할 의무가 없습니다.</p>
            <p>
                본 플랫폼이 보증된 바에 부합하지 못하는 경우, 귀하는 애플에 이를 통지할 수 있으며, 애플은 본 플랫폼 구매가격이 있는 경우 구매가격 상당을 환불해줄 것입니다. 법령이 허용하는 최대 한도
                내에서, 애플은 본 플랫폼과 관련하여 어떠한 다른 보증 의무도 지지 않습니다.
            </p>
            <p>
                애플은 본 플랫폼이나 귀하의 본 플랫폼 소유 및 이용과 관련한 귀하나 제3자의 청구에 대하여 일체의 책임이 없습니다. 애플에게 책임이 없는 청구에는 (a) 제조물 책임 청구, (b) 본 플랫폼이
                관련 법령 또는 규제의 요구사항을 준수하지 못하였다는 주장, (c) 소비자보호법이나 기타 유사한 법률에 의거한 청구를 포함하되 이에 한정되지 아니합니다.
            </p>
            <p>
                제3자가 본 플랫폼, 또는 귀하의 본 플랫폼 소유 및 이용이 자신의 지식재산권을 침해한다고 주장하는 경우, 애플은 지식재산권 침해 주장을 조사, 방어, 해결 혹은 관련 채무를 변제할 책임이
                없습니다.
            </p>
            <p>
                귀하는 귀하가 (a) 대한민국 정부의 엠바고 정책이 적용되는 나라, 또는 대한민국 정부로부터 “테러 지원국”으로 지정된 나라에 있지 않다는 점, (b) 귀하가 대한민국 정부로부터 금지되거나 제한된
                단체에 가입되어 있지 않다는 점을 표명하고 보증합니다.
            </p>
            <p>
                애플과 애플의 자회사는 본 약관의 수익자이며, 귀하가 본 약관의 규정 및 조건에 대해 동의함으로써, 애플은 수익자로써 본 약관을 귀하에 대해 적용할 권리가 있습니다(또한 애플이 위 권리에
                동의한 것으로 인정됩니다).
            </p>
            <p>셀러비는 가족 공유 기능 또는 애플의 기타 유사한 기능을 통하여 여러 이용자가 본 플랫폼을 함께 사용하는 것을 명시적으로 승인합니다.</p>
            <p>
                <strong>윈도우 폰 스토어</strong>
            </p>
            <p>귀하는 마이크로소프트 주식회사 또는 그의 계열사가 운영하는 윈도우 폰 스토어(또는 그 승계인)에서 본 플랫폼을 다운로드 받음으로써 이하의 사항에 대하여 인지하며 동의합니다.</p>
            <p>귀하는 마이크로소프트 주식회사, 귀하의 휴대전화 제조사 및 네트워크 운영자에게 본 플랫폼과 관련된 지원 서비스를 제공하거나 유지할 의무가 없음을 인지합니다.</p>
            <p>
                <strong>구글 플레이</strong>
            </p>
            <p>귀하는 구글 주식회사 또는 그 계열사(이하 “구글”)가 운영하는 구글플레이(또는 그 승계인)에서 본 플랫폼을 다운로드 받음으로써, 이하의 사항에 대하여 인지하며 동의합니다.</p>
            <ol type="a">
                <li>
                    구글플레이 이용약관, 구글플레이 비즈니스 및 프로그램 정책 또는 구글플레이를 위한 기본 최종이용자 라이선스 약관 중 구글이 지정한 것(이하 총칭하여 “구글플레이 약관” 과 (b) 본 약관의
                    다른 조항 및 조건이 상충하는 경우, 귀하가 구글플레이로부터 다운로드 받은 본 플랫폼의 이용에 있어 구글플레이 약관이 우선합니다.
                </li>
            </ol>
            <p>귀하는 구글이 셀러비나 귀하(또는 여하한 다른 이용자)의 본 약관이나 구글플레이 약관의 준수 또는 위반과 관련하여 책임이 없음을 인지합니다.</p>
            <p>
                <strong>추가 약관 -관할 지역에 따른 조항</strong>
            </p>
            <p>
                <strong>대한민국</strong>
            </p>
            <p>귀하가 대한민국에서 당사의 서비스를 이용하는 경우, 이하의 추가 약관이 적용됩니다.</p>
            <p>
                <strong>적용 법률 및 관할</strong>
            </p>
            <p>본 약관, 본 약관에 따른 사안 및 본 약관의 제정은 대한민국 법률에 따릅니다. 귀하와 당사는 모두 대한민국 법원이 전속 관할을 갖는 것에 동의합니다.</p>
            <p>
                <strong>책임의 제한</strong>
            </p>
            <p>제4조 및 제10조의 책임의 제한 조항은 당사의 고의 또는 과실에 의하여 귀하에게 발생한 손실 및 피해에 관하여는 적용되지 않습니다.</p>
            <p>
                <strong>부모 및 보호자 동의</strong>
            </p>
            <p>귀하가 만 14세 이상 19세 미만인 경우, 귀하는 부모 또는 보호자로부터 서비스를 이용하거나 서비스에 계정을 가입하는 것에 대하여 동의를 받았음을 보증합니다.</p>
            <p>
                <strong>약관의 변경</strong>
            </p>
            <p>이하의 내용은 제3조의 규정에 우선하여 적용됩니다.</p>
            <p>당사는 관련 법령을 위배하지 않는 범위에서 본 약관을 변경할 수 있습니다.</p>
            <p>
                당사가 본 약관을 변경할 경우에는 적용일자 및 수정사유를 명시하여 현행약관과 함께 홈페이지의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 그러나 본 약관의 중대한
                변경 또는 이용자에게 불리한 변경이 있는 경우, 당사는 합리적 노력을 다하여 이용자에게 사전통지를 제공할 것이며, 변경된 약관은 최소한 30일 이후에 시행됩니다. 단, 귀하의 편의를 향상하는
                서비스의 새로운 기능에 관한 변경이나 법적인 사유로 인한 변경은 즉시 시행될 수 있습니다.
            </p>
            <p>
                위와 같이 변경된 약관을 공지 또는 통지하면서, 귀하에게 위 기간 내에 의사 표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 하거나 통지하였음에도 귀하가 명시적으로
                거부의 의사표시를 하지 아니한 경우 변경 약관에 동의한 것으로 봅니다.
            </p>
            <p>
                <strong>본 서비스의 이용</strong>
            </p>
            <p>이하의 내용은 제5조의 규정에 우선하여 적용됩니다.</p>
            <p>
                콘텐츠가 불쾌 또는 부적절하거나, 본 약관을 위반하였거나, 기타 서비스 및 서비스 이용자에게 유해하다고 생각되는 경우가 아닌 한, 귀하의 동의나 법적 근거 없이 이용자 콘텐츠를 삭제하거나
                해당 콘텐츠에의 접근을 막지 않을 것입니다.
            </p>
            <p>
                <strong>이용자 제작 콘텐츠</strong>
            </p>
            <p>이하의 내용은 제7조 B의 규정에 우선하여 적용됩니다.</p>
            <p>
                당사는 법률에서 허용되는 범위 내에서 귀하와 다른 이용자들에게 본 서비스를 제공하고 본 플랫폼 홍보 및 개선을 위한 목적으로 당사의 라이선스에 따라 이용자 콘텐츠를 이용, 수정, 각색, 복제,
                2차적 저작물 작성, 출판 및/또는 전송, 본 서비스의 다른 이용자들과 기타 제3자가 위 콘텐츠를 이용, 수정, 각색, 복제, 2차적 저작물 작성, 출판 및/또는 전송할 수 있도록 할 수 있습니다.
            </p>
            <p>당사는 법률상 특별히 허용되거나 귀하의 동의가 있는 경우가 아닌 한, 귀하의 신원을 어떠한 제3자에게도 공개하지 않습니다.</p>
            <p>
                귀하의 이용자 콘텐츠가 위 “본 서비스 접근 및 이용” 규정에 명시된 기준을 준수하지 않았다고 판단되는 경우, 당사 혹은 권한 있는 제3자는 해당 이용자 콘텐츠를 잘라내거나 편집할 수 있으며,
                이를 게시하는 것을 거부할 수 있습니다.
            </p>
            <p>
                <strong>서비스 이용제한 등에 관한 사전 통지</strong>
            </p>
            <p>이하의 내용은 제5조, 제6조, 제7조, 제9조의 규정에 우선하여 적용됩니다.</p>
            <p>
                당사는 귀하에게 불리한 변경을 가하는 경우(본 서비스에 대한 모든 지연 또는 이용의 제한, 계정의 해지 및 삭제, 콘텐츠의 제거, 불허, 차단을 모두 포함합니다), 해당 조치의 이유를 지체없이
                통지할 것입니다. 다만, 통지가 법적 이유로 금지되거나 이용자, 제3자, 당사 및 그 계열사에 위해를 야기할 수 있다고 합리적으로 판단되는 경우(예를 들어, 통지하는 것이 법령 또는 규제당국의
                명령을 위반하는 경우, 조사를 방해하는 경우, 본 서비스의 보안을 해하는 경우 등)에는 통지를 하지 않을 수 있습니다.
            </p>
            <p>
                <strong>약관의 동의</strong>
            </p>
            <p>이하의 내용은 제2조의 규정에 우선하여 적용됩니다.</p>
            <p>귀하가 본 약관에 동의하여도 개인정보처리방침 동의는 의제되지 않으며, 귀하는 본 약관에 대한 동의와는 별도로 개인정보처리방침에 동의하셔야 합니다.</p>
        </div>
    );
}
