import React from 'react';

export default function CommunityGuidelineKo({ theme }) {
    return (
        <div className={`guideline ${theme === 'dark' ? 'dark' : ''}`}>
            <p className='title-1'>커뮤니티 가이드라인</p>
            <br />
            <p>최종 업데이트 날짜 2023. 05</p>
            <p className='title-3'>서론</p>
            <p>CELEBe의 목적은 모든 유저들의 창의성을 발휘시키고 즐거움을 제공하는 것입니다.</p>
            <p>
                모든 유저가 자신의 개성을 살려 창의성을 드러내고 다른 유저에게 공유하며, 새로운 세계를 경험하고, 전 세계의 사람들과 소통할 수 있는 글로벌 플랫폼을 만들기 위해 노력하고 있습니다. &nbsp;
            </p>
            <p>CELEBe가 성장하면서, 서로를 지원하는 환경을 유지하기 위해 최선을 다 하고 있습니다.&nbsp;</p>
            <p>커뮤니티 가이드라인은 CELEBe 내에서의 규범과 행동 정책을 규정합니다.&nbsp;</p>
            <p>허용되는 콘텐츠와 허용되지 않는 콘텐츠에 대한 정책을 제공하여 CELEBe를 모두가 즐길 수 있는 공간으로 만들 것 입니다.</p>
            <p>&nbsp;</p>
            <p>CELEBe의 우선 순위는 안전, 다양성, 포용 및 진실성입니다.</p>
            <p>
                유저들이 안전한 환경 속에서 편안하게 각자의 개성을 존중하며 서로에게 영감을 주는 콘텐츠 제작을 위해 노력하고 있습니다.
                <br />
                <br />
            </p>
            <p>CELEBe 커뮤니티 가이드라인은 CELEBe 내에서의 모든 유저와 콘텐츠에 적용됩니다.&nbsp;</p>
            <p>자체 심사를 통하여 문제성 콘텐츠가 신고되기 전에 우선적으로 가이드라인을 기준으로 콘텐츠를 제공합니다.</p>
            <p>
                커뮤니티 가이드라인을 위반한 것으로 간주되는 콘텐츠나 계정을 발견할 경우 직접 앱 내 기능을 통해 신고 &nbsp;가능합니다.
                <br />
                <br />
            </p>
            <p>가이드라인을 위반하는 모든 콘텐츠(동영상, 오디오, 댓글 및 텍스트 등)는 삭제될 수 있으며, 콘텐츠에 대한 성과를 수익으로 지급하는 C2E (Create To Earn) 대상에서 제외될 수 있습니다.</p>
            <p>지속적이거나 심각한 콘텐츠 가이드라인 위반이 발견되는 계정 및 디바이스는 일시적으로 정지하거나 사용을 금지할 수 있습니다.&nbsp;</p>
            <p>해당 결정은 타 플랫폼 및 오프라인 정보를 고려하여 정해졌으며 필요에 따라 해당 계정은 관련 사법 당국에 보고될 수 있습니다.</p>

            <p>CELEBe의 알고리즘은 신뢰와 안전을 염두에 두고 설계되었습니다.&nbsp;</p>
            <p>스팸 및 검토 중인 동영상을 비롯, 일반 유저에게 불쾌감을 주거나 충격을 줄 수 있는 동영상 등 일부 콘텐츠에 대해서는 검색 결과를 바꾸거나 메인 미디어 내 노출 제한을 할 수 있습니다.</p>
            <p>가이드라인에 따라 삭제되어야 할 일부 콘텐츠가 누군가에게는 가치 있는 콘텐츠일 수도 있다는 점을 항상 인지하고 있습니다.&nbsp;</p>
            <p>
                따라서, 교육적, 다큐멘터리적, 과학적 또는 예술적인 콘텐츠, 풍자성 콘텐츠, 허구 기반의 콘텐츠, 반대 의견 제시 및 뉴스로서 가치가 있거나 사회적 중요성을 가지고 있는 주제에 대한 의견 표현
                등 특정 상황에 한해서는 예외를 허용합니다.
            </p>
            <p>&nbsp;</p>
            <p>유저들이 스스로 창의성을 지휘하며 즐거움을 느낄 수 있는 안전한 플랫폼으로 유지될 수 있도록 새로운 위험과 행동 양식에 맞춰 수시로 콘텐츠 가이드라인을 업데이트하고 있습니다.</p>
            <p className='title-3'>본문</p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>폭력적 극단주의</p>
                </li>
                <li>
                    <p>증오 행동</p>
                </li>
                <li>
                    <p>불법 활동 및 규제 대상 물품</p>
                </li>
                <li>
                    <p>폭력적이고 불쾌한 콘텐츠</p>
                </li>
                <li>
                    <p>자살, 자해 및 위험행위</p>
                </li>
                <li>
                    <p>희롱 및 괴롭힘</p>
                </li>
                <li>
                    <p>성인의 알몸 노출 및 성적 활동</p>
                </li>
                <li>
                    <p>미성년자 안전</p>
                </li>
                <li>
                    <p>진실성 및 진정성</p>
                </li>
                <li>
                    <p>개인 정보 침해</p>
                </li>
                <li>
                    <p>지적 재산권 침해</p>
                </li>
                <li>
                    <p>플랫폼 보안</p>
                </li>
            </ul>
            <p className='title-3'>▶ 폭력적 극단주의</p>
            <p>CELEBe 플랫폼 내외에서의 폭력에 대해 강경한 입장을 유지하고 있습니다.</p>
            <p>사람들이 CELEBe를 통해 위협 또는 폭력을 조장하거나 위험한 인물 및 단체를 장려하는 행위는 금지되어있습니다.</p>
            <p>대중의 안전에 위협이 되거나 특정 계정이 CELEBe 외에서 폭력을 조장 또는 미화하는데 이용된 경우, 해당 계정은 일시적으로 정지되거나 사용이 금지될 수 있습니다,</p>
            <p>필요 시 이러한 위협은 관련 사법 당국에 보고될 수 있습니다.&nbsp;</p>
            <p>CELEBe를 효과적으로 보호하기 위해, 폭력적이고 극단주의적인 인물과 단체를 파악하는데 있어 타 플랫폼 및 오프라인 정보를 참고하기도 할 것입니다.&nbsp;</p>
            <p>CELEBe 내에서 그러한 인물 또는 단체가 발견된 경우, 해당 계정이 삭제될 수 있습니다.&nbsp;</p>
            <p>&nbsp;</p>
            <p>
                <u>폭력의 위협 및 선동</u>
            </p>
            <p>CELEBe 내에서 폭력을 조장하거나 참여하는 인물과 조직을 허용하지 않습니다.&nbsp;</p>
            <p>폭력을 옹호, 지시 또는 조장하는 행위를 폭력 선동으로 간주하며, 심각한 해악을 초래할 수 있는 폭력의 위협이나 선동을 허용하지 않습니다.</p>
            <p>대량 학살범, 연쇄 살인마 및 강간범, 증오 단체, 범죄 조직, 테러 조직 및 기타 민간인을 타겟으로 한 비국가 무장 단체를 포함한 위험 인물과 조직은 CELEBe에서 삭제될 수 있습니다.&nbsp;</p>
            <p>
                <u>따라서 다음과 같은 콘텐츠는 게시, 업로드, 스트리밍 또는 공유 금지입니다. 목록은 일부에 불과하며, 모든 사례를 포함하고 있지는 않습니다.</u>
            </p>
            <ul style={{ paddingLeft: '15px' }} style={{ paddingLeft: '15px' }}>
                <li>
                    <p>개인이나 그룹을 대상으로 신체 부상을 입힐 의도 피력</p>
                </li>
                <li>
                    <p>타인에게 물리적 폭력을 옹호하거나 폭력 자행을 조장하는 이미지나 발언 &nbsp;</p>
                </li>
                <li>
                    <p>타인에게 폭력을 자행할 것을 권하는 염원이나 조건에 대한 발언&nbsp;</p>
                </li>
                <li>
                    <p>개인이나 그룹을 위협하거나 협박할 의도로 어떤 장소에 무기를 가져오라는 요청&nbsp;</p>
                </li>
                <li>
                    <p>폭력을 조장할 의도로 무기 제조 및 사용 방법 지시&nbsp;</p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p>
                <u>테러 조직</u>
            </p>
            <p>
                테러리스트와 테러 조직은 정치적, 종교적, 민족적 또는 이념적 목표를 달성하기 위해 민간인을 대상으로 폭력을 행사, 위협 및/또는 심각한 범죄(반인도주의적 범죄 등)를 자행하는 비국가 활동
                주체를 뜻합니다.
            </p>
            <p>&nbsp;</p>
            <p>
                <u>범죄 조직&nbsp;</u>
            </p>
            <p>
                범죄 조직은 폭력 범죄(예: 살인, 강간, 강도, 폭력), 밀매(예: 인신매매, 장기, 마약. 무기 밀매), 납치, 경제 범죄(예: 공갈, 갈취, 사기, 돈세탁) 또는 사이버 범죄를 포함한 심각한 범죄에
                관여된 초국가적, 국가적 또는 지역 내 그룹을 뜻합니다.
            </p>
            <p>
                <u>따라서 다음과 같은 콘텐츠는 게시, 업로드, 스트리밍 또는 공유 금지입니다. 목록은 일부에 불과하며, 모든 사례를 포함하고 있지는 않습니다.</u>
            </p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>위험한 인물 및/또는 조직을 칭찬, 장려, 미화 또는 지지하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>위험한 조직에 참여를 권장하거나 사람을 모집하는 콘텐츠 &nbsp;&nbsp;</p>
                </li>
                <li>
                    <p>위험한 인물 및/또는 조직을 상징하는 이름, 상징, 로고, 깃발, 슬로건, 유니폼, 제스처, 경례, 그림, 초상, 노래, 음악, 가사 또는 기타 물체가 포함되어 있는 콘텐츠</p>
                </li>
            </ul>
            <p className='title-3'>▶ 증오행동</p>
            <p>CELEBe는 어떠한 차별도 용납하지 않으며 다양성과 포용성을 중요시하는 플랫폼입니다.</p>
            <p>증오 발언이나 행동이 포함된 콘텐츠를 허용하지 않으며 증오 발언 관련 정책을 위반하거나 CELEBe 외에서 증오 발언에 연관된 계정을 일시적으로 정지하거나 사용 금지합니다.&nbsp;</p>
            <p>&nbsp;</p>
            <p>
                <u>보호 대상을 타겟으로 하는 공격</u>
            </p>
            <p>증오 발언 및 행동을 아래의 보호 대상에 해당되는 개인 또는 그룹을 공격, 위협, 폭력 조장 또는 여타 방식으로 비인간화하는 콘텐츠로 정의합니다.</p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>인종</p>
                </li>
                <li>
                    <p>민족</p>
                </li>
                <li>
                    <p>출신 국가</p>
                </li>
                <li>
                    <p>종교</p>
                </li>
                <li>
                    <p>출신 계층(Caste)&nbsp;</p>
                </li>
                <li>
                    <p>성적 지향&nbsp;</p>
                </li>
                <li>
                    <p>성별</p>
                </li>
                <li>
                    <p>젠더</p>
                </li>
                <li>
                    <p>젠더 정체성</p>
                </li>
                <li>
                    <p>심각한 질병&nbsp;</p>
                </li>
                <li>
                    <p>장애</p>
                </li>
                <li>
                    <p>이민자 자격&nbsp;</p>
                </li>
            </ul>
            <p>
                <u>따라서 다음과 같은 콘텐츠는 게시, 업로드, 스트리밍 또는 공유 금지입니다. 목록은 일부에 불과하며, 모든 사례를 포함하고 있지는 않습니다.</u>
            </p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>보호 대상을 포함한 개인 또는 그룹을 대상으로 한 증오성 콘텐츠</p>
                </li>
                <li>
                    <p>이들이 신체적, 정신적 또는 도덕적으로 열등하다고 주장&nbsp;</p>
                </li>
                <li>
                    <p>이들에 대한 폭력 요청 또는 정당화&nbsp;</p>
                </li>
                <li>
                    <p>이들이 범죄자라고 주장&nbsp;</p>
                </li>
                <li>
                    <p>이들을 동물, 무생물 또는 기타 인간 이하의 존재라고 지칭&nbsp;</p>
                </li>
                <li>
                    <p>이들에 대한 배제, 분리 또는 차별을 조장 또는 정당화</p>
                </li>
                <li>
                    <p>보호 대상 내 개인 또는 그룹에 자행되는 폭력을 묘사하는 콘텐츠</p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p>
                <u>차별적 속어(slurs)</u>
            </p>
            <p>차별적 속어는 민족, 인종 및 위 열거된 보호 대상을 폄하하려는 의도로 사용되는 비하성 언사로 정의됩니다.&nbsp;</p>
            <p>극도로 불쾌한 용어가 확산되는 것을 막기 위해, 차별적 속어는 삭제될 수 있습니다.</p>
            <p>예외는 그러한 용어가 변용되어 사용되거나, 자신을 지칭할 때 (예: 노래에서), 또는 경멸의 의미가 없는 경우입니다.&nbsp;</p>
            <p>
                <u>따라서 다음과 같은 콘텐츠는 게시, 업로드, 스트리밍 또는 공유 금지입니다. 목록은 일부에 불과하며, 모든 사례를 포함하고 있지는 않습니다.</u>
            </p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>차별적 속어를 사용하거나 포함한 콘텐츠&nbsp;</p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p>
                <u>증오 이념</u>
            </p>
            <p>증오 이념은 보호 대상이라는 이유로 사람들에게 적대심을 표출하는 이념을 의미합니다.&nbsp;</p>
            <p>증오 이념은 포용적이며 지지적인 CELEBe 가치와 상반되며 이런 이념을 장려하는 콘텐츠는 삭제될 수 있습니다.&nbsp;</p>
            <p>
                <u>따라서 다음과 같은 콘텐츠는 게시, 업로드, 스트리밍 또는 공유 금지입니다. 목록은 일부에 불과하며, 모든 사례를 포함하고 있지는 않습니다.</u>
            </p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>증오 이념을 칭찬, 조장, 미화 또는 지지하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>증오 이념과 관련된 이름, 상징, 로고, 깃발, 슬로건, 유니폼, 제스처, 경례, 그림, 초상, 노래, 음악, 가사 또는 기타 물체를 포함한 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>보호 대상에 피해를 준 많은 증거가 있음에도 과거의 폭력적 사건을 부인하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>특정 그룹을 보호 대상과 비교하며 우월성을 주장하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>증오 이념을 정당화하기 위해 사용되는 음모론을 다루는 콘텐츠</p>
                </li>
            </ul>
            <p className='title-3'>▶불법 활동 및 규제 대상 물품</p>
            <p>CELEBe는 법이나 규정을 위반하는 활동이 불가능한 플랫폼을 만들기 위해 노력하고 있습니다.&nbsp;</p>
            <p>인간 착취를 포함한 범죄 활동의 묘사, 조장 또는 촉진을 비롯, 특정 규제 대상 물품의 거래, 판매, 홍보 및 사용을 금지합니다.&nbsp;</p>
            <p>대부분의 나라 및 지역에서 불법이거나 규제되는 활동 또는 품목과 관련이 있는 경우, 게시된 관할지역에서 해당 활동이나 품목이 합법적일지라도 일부 콘텐츠가 삭제될 수 있습니다.&nbsp;</p>

            <p>
                <u>범죄 활동</u>
            </p>
            <p>범죄 활동은 절도, 폭행, 인간 착취, 위조 및 기타 유해 행동 등 법률에 의해 처벌될 수 있는 광범위한 활동을 포함합니다.&nbsp;</p>
            <p>이러한 행위가 일상화되거나 모방의 대상이 되거나 허용되지 않도록 우리는 범죄 활동을 용인하거나 장려하는 콘텐츠는 삭제될 수 있습니다.&nbsp;</p>
            <p>
                <u>따라서 다음과 같은 콘텐츠는 게시, 업로드, 스트리밍 또는 공유 금지입니다. 목록은 일부에 불과하며, 모든 사례를 포함하고 있지는 않습니다.</u>
            </p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>폭력이나 납치 등 신체적 상해를 묘사 또는 장려하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>허위 신고(swatting) 등 타인의 안전을 위협하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>인신 매매, 담보 노동(빚 갚기 위한 강제 노동), 가정 노예, 성 매매 또는 매춘을 포함한 인간 착취를 묘사 또는 장려하는 콘텐츠</p>
                </li>
                <li>
                    <p>밀렵 또는 불법 야생 동물 거래를 묘사 또는 장려하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>불법적으로 획득하거나 위조한 물품의 구매, 판매, 거래를 제안하거나 요청을 담고 있는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>범죄 행위를 저지르는 방법을 알려주는 콘텐츠</p>
                </li>
            </ul>
            <p>
                <u>무기</u>
            </p>
            <p>CELEBe는 총기, 탄약, 총기 부속품 또는 폭발성 무기에 대한 묘사, 홍보 또는 거래를 허용하지 않습니다.&nbsp;</p>
            <p>또한 무기류 제조 방법에 대한 콘텐츠도 금지됩니다.&nbsp;</p>
            <p>박물관 소장품의 일부이거나 경찰관 소지한 무기, 군사 퍼레이드 또는 사격장과 같이 안전하고 통제된 환경에서 사용되는 무기 관련 콘텐츠는 허용될 수 있습니다.</p>
            <p>
                <u>따라서 다음과 같은 콘텐츠는 게시, 업로드, 스트리밍 또는 공유 금지입니다. 목록은 일부에 불과하며, 모든 사례를 포함하고 있지는 않습니다.</u>
            </p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>총기, 총기 부속품, 탄약 또는 폭발성 무기를 보여주는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>총기, 부속품, 탄약, 폭발성 무기 또는 이의 제조법의 구매, 판매, 거래를 제안하거나 요청하는 콘텐츠</p>
                </li>
            </ul>

            <p>
                <u>마약, 규제 약물, 알코올 및 담배</u>

                <u>&nbsp;</u>
            </p>
            <p>CELEBe는 마약 또는 기타 규제 약물의 묘사, 장려 또는 거래를 허용하지 않습니다.&nbsp;</p>
            <p>담배 및 알코올 제품의 거래 또한 CELEBe 내에서 금지되어 있습니다.&nbsp;</p>
            <p>
                <u>따라서 다음과 같은 콘텐츠는 게시, 업로드, 스트리밍 또는 공유 금지입니다. 목록은 일부에 불과하며, 모든 사례를 포함하고 있지는 않습니다.</u>
            </p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>마약 및 마약 소비를 묘사 또는 장려하거나 타인에게 마약 및 기타 규제 약물의 제조, 사용 또는 거래를 권장하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>마약 및 기타 규제 약물, 알코올 또는 담배 (전자담배 제품 포함)제품의 구매, 판매, 거래를 제안하거나 요청하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>불법 또는 규제 약물 구매 방법에 대한 정보를 제공하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>불법 알코올 제품 제조를 묘사하거나 장려하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>합법적 약물의 오용이나 취하기 위한 약물의 수공 제조법을 묘사하거나 장려하는 콘텐츠&nbsp;</p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p>
                <u>도박</u>
            </p>
            <p>CELEBe는 도박 서비스를 장려하는 콘텐츠나 카지노, 스포츠 베팅 또는 기타 상업적 도박 행위의 광고로 간주할 수 있는 콘텐츠를 허용하지 않습니다.&nbsp;</p>
            <p>
                <u>따라서 다음과 같은 콘텐츠는 게시, 업로드, 스트리밍 또는 공유 금지입니다. 목록은 일부에 불과하며, 모든 사례를 포함하고 있지는 않습니다.</u>
            </p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>카지노, 스포츠 베팅, 포커, 복권, 도박 관련 소프트웨어와 앱 또는 기타 도박 서비스를 장려하는 콘텐츠&nbsp;</p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p>
                <u>사기 행위</u>
            </p>
            <p>CELEBe 통해 사용자의 신뢰를 악용하여 경제적 및 개인적 피해를 주는 것을 허용하지 않습니다.&nbsp;</p>
            <p>사기 또는 자산 절도 계획을 포함하여 불법적으로 경제적 또는 개인적 이득을 얻기 위해 사람을 속이는 콘텐츠는 삭제될 수 있습니다.</p>
            <p>
                <u>
                    따라서 다음과 같은 콘텐츠는 게시, 업로드, 스트리밍 또는 공유 금지입니다. 목록은 일부에 불과하며, 모든 사례를 포함하고 있지는 않습니다. 목록은 일부에 불과하며, 모든 사례를 포함하고
                    있지는 않습니다.
                </u>
            </p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>피싱을 묘사 또는 장려하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>폰지(Ponzi)형 사기, *다단계 마케팅 또는 피라미드형 사기를 묘사 또는 장려하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>고수익을 약속하는 투자형 사기, *승부 조작 또는 기타 모든 종류의 사기를 묘사 또는 장려하는 콘텐츠</p>
                </li>
                <li>
                    <p>현금 지급, &apos;벼락부자 되기&apos; 광고, 다단계 판매(다단계 구조에서 실제 제품 없이 돈만 지불)에 관한 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>빨리 부자가 될 수 있다거나 기적의 치료법으로 암 등의 만성 질환을 완치할 수 있다고 주장하는 과장 광고</p>
                </li>
                <li>
                    <p>현금 지급 또는 기타 다단계 판매 홍보</p>
                </li>
                <li>
                    <p>현금 지급을 주 목적으로 하는 계정</p>
                </li>
                <li>
                    <p>&apos;이 방법으로 내일이면 5만 달러를 벌 수 있습니다&apos;라고 약속하는 동영상</p>
                </li>
            </ul>
            <p className='title-3'>▶폭력적이고 불쾌한 콘텐츠</p>
            <p>CELEBe는 창의성을 중요시하지만 충격을 위한 폭력적인 콘텐츠는 지양합니다.&nbsp;</p>
            <p>불필요하게 충격적이거나 불쾌하고 잔인하거나 남을 괴롭히는 콘텐츠를 허용하지 않습니다.&nbsp;</p>
            <p>극단적인 폭력이나 고통을 장려, 일상화 또는 미화하는 콘텐츠도 마찬가지입니다.&nbsp;</p>
            <p>대중의 안전을 위협하는 경우, 우리는 해당 계정을 일시적으로 중지하거나 사용 금지하며, 필요시 관련 사법 당국에 보고할 수 있습니다.</p>
            <p>&nbsp;</p>
            <p>
                <u>따라서 다음과 같은 콘텐츠는 게시, 업로드, 스트리밍 또는 공유 금지입니다. 목록은 일부에 불과하며, 모든 사례를 포함하고 있지는 않습니다.</u>
            </p>
            <p>*사람에 대한 다음과 같은 콘텐츠</p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>끔찍한 사망이나 사고 관련&nbsp;</p>
                </li>
                <li>
                    <p>절단, 훼손, 불에 그을리거나 탄 사체&nbsp;</p>
                </li>
                <li>
                    <p>열린 상처나 부상 부위가 핵심 포커스인 피투성이 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>현실 세계에서의 신체 폭력, 싸움 및 고문</p>
                </li>
            </ul>
            <p>*동물에 대한 다음과 같은 콘텐츠</p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>동물의 도살 또는 기타 자연사가 아닌 죽음&nbsp;</p>
                </li>
                <li>
                    <p>절단, 훼손, 불에 그을리 거나 탄 동물의 사체&nbsp;</p>
                </li>
                <li>
                    <p>동물에 대한 잔혹 행위 및 피투성이 콘텐츠&nbsp;</p>
                </li>
            </ul>
            <p className='title-3'>▶자살, 자해 및 위험 행위</p>
            <p>CELEBe는 유저들의 건강과 웰빙을 소중하게 여깁니다.&nbsp;</p>
            <p>자살, 자해 또는 섭식 장애로 이어질 수 있는 활동을 묘사, 장려, 일상화 또는 미화하는 콘텐츠를 허용하지 않습니다.&nbsp;</p>
            <p>또한 유저들이 심각한 부상이나 사망으로 이를 수 있는 위험한 행위에 자신이 참여한 모습을 묘사하거나 타인에게 참여를 권하는 콘텐츠도 허용하지 않습니다.&nbsp;</p>
            <p>&nbsp;</p>
            <p>그러나 유저들이 이러한 주제에 대한 인식을 제고하고 커뮤니티의 도움을 얻기 위해 개인적 경험을 안전한 방식으로 공유하는 것은 지지합니다.&nbsp;</p>
            <p>
                또한 자해나 자살에 대한 생각으로 어려움을 겪고 있거나, 자살을 진지하게 고려하고 있는 사람을 알고 계신 사용자에게 즉시 현지 응급 서비스 또는 자살 예방 핫라인으로 신고할 것을
                권장합니다.&nbsp;
            </p>
            <p>CELEBe의 개입으로 스스로를 해할 위험에 처한 사용자들을 도울 수 있는 경우, CELEBe가 해당 현지 응급 서비스에 직접 연락할 수 있습니다.&nbsp;</p>
            <p>&nbsp;</p>
            <p>
                <u>자살</u>
            </p>
            <p>자살, 자살 충동 또는 유사한 행동을 유도할 수 있는 내용이 포함된 콘텐츠가 삭제될 수 있습니다.</p>
            <p>자살 시도 묘사 또는 자살로 이어질 가능성이 높은 행동에 참여하거나 참여할 의도를 보여주는 콘텐츠 또한 삭제될 수 있습니다.</p>
            <p>자살을 장려, 일상화 또는 미화하거나 자살 방법 공유, 자살을 영웅적 또는 명예로운 것으로 묘사하는 모든 형태의 콘텐츠를 금지합니다.&nbsp;</p>
            <p>
                <u>따라서 다음과 같은 콘텐츠는 게시, 업로드, 스트리밍 또는 공유 금지입니다. 목록은 일부에 불과하며, 모든 사례를 포함하고 있지는 않습니다.</u>
            </p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>자살 방법을 알려주는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>자살을 묘사, 장려, 일상화 또는 미화하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>자살 게임, 자살 도전, 자살 약속 또는 장난</p>
                </li>
            </ul>

            <p>
                <u>자해 및 섭식 장애&nbsp;</u>
            </p>
            <p>자해 행동을 일상화, 장려 또는 촉발하지 않기 위해, CELEBe는 유저의 게시 의도와 상관없이 그런 행동을 묘사하는 이미지를 허용하지 않습니다.&nbsp;</p>
            <p>자해로 인한 신체 부상을 초래할 가능성이 높은 행위를 조장 또는 일상화하는 콘텐츠는 삭제될 수 있습니다.&nbsp;</p>
            <p>건강에 악영향을 미칠 가능성이 높은 식이 습관을 장려하는 콘텐츠 또한 CELEBe 내에서 허용하지 않습니다.</p>
            <p>
                <u>따라서 다음과 같은 콘텐츠는 게시, 업로드, 스트리밍 또는 공유 금지입니다. 목록은 일부에 불과하며, 모든 사례를 포함하고 있지는 않습니다.</u>
            </p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>자해 또는 섭식 장애를 묘사, 장려, 일상화 또는 미화하는 콘텐츠</p>
                </li>
                <li>
                    <p>자해나 섭식 장애에 참여하는 방법을 알려주는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>자해 또는 섭식 장애 관련 게임, 도전, 약속 또는 장난과 관련된 콘텐츠</p>
                </li>
                <li>
                    <p>섭식 장애 등 위험한 체중 감량 행동을 묘사, 장려, 일상화 또는 미화하는 콘텐츠</p>
                </li>
            </ul>

            <p>
                <u>위험 행위</u>
            </p>
            <p>
                CELEBe는 위험 행위 또는 기타 위험한 행동을 비전문가가 필요한 기술과 안전 조치를 갖추지 않은 상태에서 수행하여 사용자 또는 대중의 심각한 부상 또는 사망으로 이를 수 있는 경우로
                정의합니다.&nbsp;
            </p>
            <p>아마추어 스턴트나 위험한 챌린지를 포함한 위험 행위를 묘사, 장려, 일상화 또는 미화하는 콘텐츠를 허용하지 않습니다.&nbsp;</p>
            <p>
                <u>따라서 다음과 같은 콘텐츠는 게시, 업로드, 스트리밍 또는 공유 금지입니다. 목록은 일부에 불과하며, 모든 사례를 포함하고 있지는 않습니다.</u>
            </p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>위험한 도구, 자동차 또는 물체를 부적절하게 사용하는 것을 보여주는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>식용이 아니거나 심각한 해를 끼칠 수 있는 물체를 먹는 것을 묘사 또는 장려하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>부상으로 이어질 수 있는 위험한 게임, 도전 또는 스턴트</p>
                </li>
            </ul>
            <p className='title-3'>▶희롱 및 괴롭힘</p>
            <p>CELEBe는 괴롭힘에 대한 걱정 없이 개성 있는 자기표현이 가능한 포용적인 플랫폼 지향합니다.&nbsp;</p>
            <p>커뮤니티 구성원들에 대한 모욕, 괴롭힘 또는 희롱을 허용하지 않습니다.&nbsp;</p>
            <p>학대성 콘텐츠나 행동은 심각한 심리적 스트레스를 초래할 수 있으며 해당 콘텐츠는 삭제될 수 있습니다.</p>
            <p>&nbsp;</p>
            <p>
                <u>학대성 행동</u>
            </p>
            <p>CELEBe는 협박이나 조롱, 모욕, 위협 또는 상처를 입히려는 의도의 비하성 발언을 포함한 모든 형태의 학대 관련 콘텐츠를 금지합니다.&nbsp;</p>
            <p>이러한 금지 정책은 CELEBe의 기능을 활용한 콘텐츠에도 적용됩니다.&nbsp;</p>
            <p>일반 대중에게 중요한 이슈에 대한 표현을 허용하기 위해, 공인에 대한 비판적인 댓글은 허용될 수 있습니다.&nbsp;</p>
            <p>그러나 공인을 상대로 한 심각한 학대성 행위는 금지됩니다.&nbsp;</p>
            <p>
                <u>따라서 다음과 같은 콘텐츠는 게시, 업로드, 스트리밍 또는 공유 금지입니다. 목록은 일부에 불과하며, 모든 사례를 포함하고 있지는 않습니다.</u>
            </p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>지능, 외모, 성격 또는 위생 상태를 기반으로 타인을 모욕 또는 폄하하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>여러 사람이 담합해서 누군가를 희롱하는 것을 권하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>폭력적 비극의 희생자들을 비하하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>CELEBe 인터랙티브 기능을 활용한 타인을 비하하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>사이버 스토킹이나 트롤링처럼 고의적인 피해나 협박을 묘사하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>특정인이나 공인의 죽음 또는 질병 등 심각한 피해를 바라는 콘텐츠</p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p>
                <br />

                <u>성희롱</u>
            </p>
            <p>성희롱은 다른 사람을 향한 원치 않거나 부적절한 성적 행위를 의미합니다.&nbsp;</p>
            <p>CELEBe는 사용자의 의도와 관계없이 댓글이나 접근을 포함하여 타인을 성적으로 희롱하는 콘텐츠를 허용하지 않습니다.&nbsp;</p>
            <p>이는 그러한 행동이 표적이 된 대상에게 극심한 스트레스를 입힐 수 있기 때문입니다.&nbsp;</p>
            <p>
                <u>따라서 다음과 같은 콘텐츠는 게시, 업로드, 스트리밍 또는 공유 금지입니다. 목록은 일부에 불과하며, 모든 사례를 포함하고 있지는 않습니다.</u>
            </p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>원치 않는 성적 접촉을 시도하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>말 또는 텍스트(이모지 포함) 및 앱 내 기능을 활용하여 다른 사용자와 성적 행위를 하는 시늉을 하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>타인의 성적 행위를 비하하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>타인의 이미지를 바꾸거나 변형하여 성 암시 또는 성적 행위에 가담함을 묘사 또는 암시하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>디지털 콘텐츠, 성적 과거, 과거 섹스 파트너의 이름을 공개하겠다는 협박을 포함하여 누군가의 사적인 성생활을 공개하거나 공개하겠다고 위협하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>누군가의 성적 지향을 동의 없이 또는 당사자 모르게 노출하거나 노출하겠다고 협박하는 콘텐츠</p>
                </li>
            </ul>

            <p>
                <u>해킹, 신상 털기(doxxing) 및 협박 위협</u>
                &nbsp;
            </p>
            <p>타인을 희롱 또는 협박하려는 의도로 해킹 또는 신상 털기를 하겠다고 위협하는 것은 심각한 감정적 피해 및 기타 오프라인 상의 피해를 끼칠 수 있습니다.&nbsp;</p>
            <p>CELEBe는 신상 털기(doxxing)를 개인 정보 또는 개인 식별 정보(PII)를 악의적인 목적으로 수집하고 공개하는 행위로 정의합니다.&nbsp;</p>
            <p>이러한 온라인 상 행동을 일종의 학대로 간주하며 CELEBe 내에서 금지되어 있습니다.</p>
            <p>
                <u>따라서 다음과 같은 콘텐츠는 게시, 업로드, 스트리밍 또는 공유 금지입니다. 목록은 일부에 불과하며, 모든 사례를 포함하고 있지는 않습니다.</u>
            </p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>
                        집 주소, 공개되지 않은 이메일 주소, 비공개 전화 번호, 은행 내역서, 사회 보장 번호 또는 여권 번호를 포함한 개인 정보 또는 개인 식별 정보(PII)를 공개하겠다고 위협하는
                        콘텐츠&nbsp;
                    </p>
                </li>
                <li>
                    <p>타인의 계정을 해킹 또는 갈취하겠다고 협박하는 콘텐츠</p>
                </li>
                <li>
                    <p>다른 사람들에게 특정인의 계정, 개인 정보 또는 개인 식별 정보(PII)를 해킹 또는 공개하라고 선동하거나 조장하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>다른 사람들이 오용, 트롤링(인터넷에서 화를 부추기기 위해 놀리기) 또는 희롱할 수 있도록 누군가의 계정, 개인 정보 또는 개인 식별 정보를 공개하는 콘텐츠</p>
                </li>
            </ul>
            <p className='title-3'>▶성인의 알몸 노출 및 성적 활동</p>
            <p>CELEBe는 안전한 플랫폼이 되고자 노력합니다.&nbsp;</p>
            <p>알몸 노출, 포르노 또는 성적으로 노골적인 콘텐츠는 CELEBe 내에서 금지되어 있습니다.</p>
            <p>동의 없는 성 행위를 묘사 또는 지지하거나 동의 되지 않은 성적 이미지를 공유, 성인의 성 착취를 묘사 또는 지지하는 콘텐츠 또한 허용하지 않습니다.</p>
            <p>&nbsp;</p>
            <p>
                <u>성 착취</u>
            </p>
            <p>
                성 착취는 성적 목적으로 본인의 신뢰도, 지위 또는 타인의 약점을 악용하거나 악용을 시도하는 것으로 정의되며 타인의 성 착취로부터 경제적, 사회적 또는 정치적 이득을 보는 행위도 포함됩니다.
            </p>
            <p>
                <u>따라서 다음과 같은 콘텐츠는 게시, 업로드, 스트리밍 또는 공유 금지입니다. 목록은 일부에 불과하며, 모든 사례를 포함하고 있지는 않습니다.</u>
            </p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>강간과 성폭력을 포함한 동의 없는 성 행위 또는 접촉을 묘사, 요청/제안, 조장, 일상화 또는 미화하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>동의 없이 촬영, 제작, 또는 공유된 성적 이미지를 포함, 동의 되지 않은 성적인 이미지를 공유하는 것을 묘사, 요청/제안, 장려, 일상화 또는 미화하는 콘텐츠</p>
                </li>
                <li>
                    <p>성 폭력을 묘사, 장려, 일상화 또는 미화하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>
                        성 파트너, 성적 채팅 또는 이미지, 성적 서비스, 프리미엄 성적 콘텐츠 또는 섹스캠(타인이 성 관계하는 모습을 실시간으로 보여주는 서비스)의 제안 및 요청을 포함한 성적 요청/제안을
                        묘사, 장려 또는 미화하는 콘텐츠
                    </p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
                <u>성인이 관여된 알몸 노출 및 성적 행위&nbsp;</u>
            </p>
            <p>알몸 노출 및 성적 행위에는 유방, 생식기, 항문 또는 둔부를 공공연하게 노출하거나 성행위를 모방, 암시 또는 보여주는 행동이 포함됩니다.&nbsp;</p>
            <p>CELEBe는 디지털 방식으로 제작되거나 조작된 콘텐츠를 포함하여 알몸 노출이나 성적 활동의 묘사를 허용하지 않습니다.&nbsp;</p>
            <p>특정 지역에서 불쾌감을 주거나 문화적으로 부적절하다고 간주될 수 있는 콘텐츠, 모든 연령대에 적절하지 않을 수도 있는 콘텐츠를 유념하고 있습니다.&nbsp;</p>
            <p>
                <u>따라서 다음과 같은 콘텐츠는 게시, 업로드, 스트리밍 또는 공유 금지입니다. 목록은 일부에 불과하며, 모든 사례를 포함하고 있지는 않습니다.</u>
            </p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>삽입 성교 및 비삽입 성교, 구강 성교 또는 성적인 키스를 포함한 노골적 또는 암시적으로 성적 행위를 묘사하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>성적 흥분이나 성적 자극을 묘사하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>성적 페티시를 묘사하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>노출된 인간의 생식기, 여성 젖꼭지 또는 유륜, 치골부 또는 둔부를 묘사하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>성적 만족을 위해 성적으로 노골적인 언어를 포함하는 콘텐츠</p>
                </li>
            </ul>

            <p className='title-3'>▶미성년자 안전</p>
            <p>CELEBe 내 미성년자를 학대, 착취 또는 위험에 빠뜨리거나 해치는 활동을 허용하지 않습니다.</p>
            <p>
                애니메이션 및 디지털 방식으로 제작 또는 조작된 미디어를 비롯, 미성년자에 대한 학대, 착취 또는 알몸 노출을 묘사하는 모든 콘텐츠는 우리 플랫폼 상에서 정책 위반으로 간주되어 발견되는 즉시
                삭제될 것입니다.&nbsp;
            </p>
            <p>필요 시 해당 콘텐츠는 국립 실종&middot;학대 아동 방지센터 (NCMEC) 및/또는 기타 관련 사법 당국에 보고할 수 있습니다.</p>
            <p>CELEBe는 미성년자를 18세 미만으로 정의합니다.&nbsp;</p>
            <p>
                <br />
                사용자들은 서비스 약관 명시된 CELEBe의 최소 연령 요건(13세 미만)을 만족해야 합니다.
            </p>
            <p>연령 요건을 만족하지 못하는 계정 소유자가 확인되면, 해당 계정을 삭제할 수 있습니다.</p>
            <p>&nbsp;</p>
            <p>
                <u>미성년자의 성 착취&nbsp;</u>
            </p>
            <p>미성년자의 성 착취는 성적 목적으로 본인의 신뢰도 또는 지위를 악용하는 것으로 정의되며 미성년자의 착취로부터 경제적, 사회적, 성적 또는 정치적 이득을 보는 것도 포함됩니다.&nbsp;</p>
            <p>아동 성학대 자료(CSAM)란 약탈적 성인 및 동년배 또는 미성년자 스스로가 담아낸 성적으로 노골적인 알몸 노출 또는 행위를 시각적으로 묘사한 것으로 정의됩니다.&nbsp;</p>
            <p>CELEBe는 성인과 미성년자 또는 나이 차이가 많이 나는 미성년자들 간의 성적 교류와 접근에 관한 콘텐츠 또는 계정에 대해 조치를 취합니다.&nbsp;</p>
            <p>
                <u>따라서 다음과 같은 콘텐츠는 게시, 업로드, 스트리밍 또는 공유 금지입니다. 목록은 일부에 불과하며, 모든 사례를 포함하고 있지는 않습니다.</u>
            </p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>CSAM을 공유, 재공유, 거래 또는 판매하겠다는 제안 및CSAM 입수 또는 유통을 위해 플랫폼 외로 사용자를 꾀어 내는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>미성년자와 성적으로 관련되거나, 여타 방식으로 미성년자를 성적 대상화(예: 듀엣과 같은 제품 기능을 통해)하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>미성년자 알몸 노출, 성적 대상화 또는 미성년자와의 성적 행위를 포함한 성적 학대 이미지를 묘사, 요청/제안, 미화 또는 권장하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>소아 성애 또는 미성년자 성폭력을 묘사, 장려, 일상화 또는 미화하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>제3자가 성폭력 또는 고백 내용을 재연하거나 공유함으로써 미성년자인 학대 피해자가 다시 피해를 입거나 악용될 수 있는 콘텐츠&nbsp;</p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
                <u>그루밍 행위</u>
            </p>
            <p>
                그루밍 행위이란 성인이 미성년자를 추후에 또는 지속적으로 성적 접촉, 성적 학대, 성매매 또는 기타 착취를 하기 위해 미성년자의 신뢰를 얻고자 이들과 정서적 관계를 구축하는
                행위입니다.&nbsp;
            </p>
            <p>이러한 행위에는 아첨, CELEBe 내외에서의 접촉 요청, 개인 정보 요청, 미성년자 성 학대 자료 요청/제안, 성적 요청/제안 또는 댓글 및 선물 주기 등이 있습니다.&nbsp;</p>
            <p>
                <u>따라서 다음과 같은 콘텐츠는 게시, 업로드, 스트리밍 또는 공유 금지입니다. 목록은 일부에 불과하며, 모든 사례를 포함하고 있지는 않습니다.</u>
            </p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>그루밍을 하기 위한 접근</p>
                </li>
                <li>
                    <p>그루밍 행위를 묘사, 장려, 일상화 또는 미화하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>현실 세계에서 미성년자와 성인 간 또는 나이 차이가 많은 미성년자들 간의 성적 접촉을 요청/제안하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>미성년자들에게 알몸 노출 이미지를 보여주거나 제안하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>협박이나 다른 강압적 수단을 통해 누드 이미지 또는 성적 접촉을 요청/제안하는 모든 콘텐츠&nbsp;</p>
                </li>
            </ul>

            <p>
                <u>미성년자가 관여된 알몸 노출 및 성적 활동&nbsp;</u>
            </p>
            <p>미성년자가 관여된 알몸 노출 및 성적 활동에는 유방, 생식기, 항문 또는 둔부를 공공연하게 노출하거나 미성년자가 관여된 성행위를 모방, 암시 또는 보여주는 콘텐츠입니다.&nbsp;</p>
            <p>CELEBe는는 디지털 방식으로 제작되거나 조작된 콘텐츠를 포함하여 알몸 노출 또는 성적 활동을 묘사하는 콘텐츠를 허용하지 않습니다.&nbsp;</p>
            <p>
                <u>따라서 다음과 같은 콘텐츠는 게시, 업로드, 스트리밍 또는 공유 금지입니다. 목록은 일부에 불과하며, 모든 사례를 포함하고 있지는 않습니다.</u>
            </p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>삽입 또는 비삽입 성교, 구강성교, 선정적인 키스를 포함한 미성년자의 성적 활동을 묘사 또는 암시하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>미성년자가 관여된 성적 흥분 또는 성적 자극을 묘사하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>미성년자가 관여된 성적 페티시를 묘사하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>미성년자의 생식기, 젖꼭지 또는 유륜, 치골부 또는 둔부를 묘사하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>성적으로 노골적인 언어로 미성년자를 묘사 또는 설명하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>성적으로 노골적인 노래 가사가 포함된 미성년자 묘사 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>트월킹(twerking), 가슴 흔들기, 골반 밀치기 또는 자신 또는 다른 사람의 사타구니나 유방 어루만지기 등 성적으로 노골적인 미성년자의 댄스가 포함된 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>미성년자가 옷을 벗는 것을 묘사하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>어떤 장소에서 상황상 적절하지 않게 미성년자가 옷을 거의 입지 않고 있는 것을 묘사하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>미성년자의 알몸 상태 또는 성적 활동을 숨기거나 암시하기 위한 성적인 댓글, 이모지, 텍스트 또는 기타 그래픽 콘텐츠</p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
                <u>미성년자에 의한 유해 활동</u>
            </p>
            <p>미성년자의 유해 활동에는 미성년자에게 금지된 물질의 소지와 소비, 합법적 약물의 오용, 불법 행위 가담, 미성년자의 웰빙에 위협이 되는 활동, 챌린지 및 도전 참여 등이 있습니다.</p>
            <p>그러한 콘텐츠는 삭제될 수 있습니다,</p>
            <p>
                <u>따라서 다음과 같은 콘텐츠는 게시, 업로드, 스트리밍 또는 공유 금지입니다. 목록은 일부에 불과하며, 모든 사례를 포함하고 있지는 않습니다.</u>
            </p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>미성년자의 알코올 음료, 담배 및 마약 소지 또는 소비를 암시, 묘사, 모방 또는 장려하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>미성년자를 타겟으로 알코올, 담배 또는 규제 약물의 구매, 판매 또는 거래 방법을 알려주는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>물리적 챌린지, 도전 및 스턴트를 포함한 청소년의 웰빙을 위협하는 활동을 묘사 또는 장려하는 콘텐츠</p>
                </li>
            </ul>

            <p>
                <u>미성년자에 대한 신체적 및 심리적 피해&nbsp;</u>
            </p>
            <p>미성년자에게 신체적 또는 심리적 피해를 끼칠 위험이 있는 행동에는 신체적 학대, 방치, 위험에 빠뜨리기 및 심리적 비하가 있습니다.</p>
            <p>그러한 콘텐츠는 삭제될 수 있습니다,</p>
            <p>
                <u>따라서 다음과 같은 콘텐츠는 게시, 업로드, 스트리밍 또는 공유 금지입니다. 목록은 일부에 불과하며, 모든 사례를 포함하고 있지는 않습니다.</u>
            </p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>미성년자의 신체적 학대, 방치, 위험에 빠뜨리기 및 심리적 비하를 묘사 또는 장려하는 콘텐츠</p>
                </li>
                <li>
                    <p>모방의 위험에 대한 경고 없이 극한 상황에서 살아남는 방법을 묘사 또는 장려하는 콘텐츠</p>
                </li>
            </ul>

            <p>
                <u>어린이에 대한 범죄</u>
            </p>
            <p>CELEBe는어린이에 대한 범죄로 유죄 판결을 받은 사용자가 우리 플랫폼 상에서 계정을 개설하는 것을 허용하지 않습니다.&nbsp;</p>
            <p>
                이러한 범죄에는 성폭력, 추행, 살인, 신체적 학대 또는 방치, 유괴, 부모에 의한 해외로의 납치, 밀매, 매춘을 위한 미성년자 착취, 미성년자에 대한 온라인 상 성 학대, 여행 및 관광 관련
                미성년자 성 착취, 아동 성 학대 자료(CSAM) 입수 및 유통 시도, CSAM 제작, 소지 및 유통이 포함됩니다.&nbsp;
            </p>
            <p>이러한 사용자의 계정은 발견 즉시 삭제됩니다.</p>
            <p>사용자 정보에서 계정 소유자가 소아 성애자 또는 미성년자 성범죄자라고 스스로 밝히는 경우, 그러한 정보를 사실로 간주하고 계정을 삭제할 것입니다.&nbsp;</p>
            <p className='title-3'>▶진실성 및 진정성</p>
            <p>
                CELEBe는 플랫폼의 진실성과 사용자들의 진정성을 해칠 수 있는 활동을 허용하지 않습니다. 스팸 또는 가짜 인터랙션, 사칭, 허위 정보를 통해 피해를 입히거나 지적재산권을 위반한 콘텐츠나
                계정을 삭제할 수 있습니다.
            </p>
            <p>&nbsp;</p>
            <p>
                <u>허위 정보</u>
            </p>
            <p>
                허위 정보란 부정확하거나 잘못된 정보로 정의됩니다. 우리는 CELEBe 커뮤니티에 중요한 주제에 관해 서로를 존중하는 대화를 권장하며, 의도와 상관없이 사용자 개인, 커뮤니티, 또는 일반 대중에
                해가 되는 허위 정보를 허용하지 않습니다.
            </p>
            <p>
                <u>따라서 다음과 같은 콘텐츠는 게시, 업로드, 스트리밍 또는 공유 금지입니다. 목록은 일부에 불과하며, 모든 사례를 포함하고 있지는 않습니다.</u>
            </p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>증오나 편견을 조장하는 허위 정보&nbsp;</p>
                </li>
                <li>
                    <p>혼란을 유발하는 비상 사태와 관련된 허위 정보&nbsp;</p>
                </li>
                <li>
                    <p>신체 건강에 해를 끼칠 수 있는 잘못된 의학 정보</p>
                </li>
                <li>
                    <p>선거나 시민 참여 과정에 대해 커뮤니티 구성원들을 오도하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>특정 보호 대상을 공격 또는 폭력적인 행동을 촉구하거나 과거에 발생한 폭력적 또는 비극적 사건을 부인하는 음모론 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>사건 관련 진실을 왜곡함으로써 사용자를 오도하고 동영상의 대상이나 기타 인물 또는 사회 전체에 해를 미치는 디지털 위조(미디어 합성 또는 조작) 콘텐츠</p>
                </li>
            </ul>
            <p>
                계정의 정체, 위치 또는 목적에 대해 개인과 우리 커뮤니티 또는 대중에게 잘못된 정보를 제공하면서 영향력을 행사하고 여론을 조작하기 위해 계정 생성 등 진실되지 않은 활동에 조직적으로
                관여하지 마시기 바랍니다.&nbsp;
            </p>
            <p>&nbsp;</p>
            <p>
                <u>스팸</u>
            </p>
            <p>CELEBe는 과도하게 자주 게시 되거나 반복되거나 뚜렷한 대상이 없고 다음 내용을 하나 이상 포함한 콘텐츠를 허용하지 않습니다.&nbsp;</p>
            <p>안전하고 긍정적인 커뮤니티를 유지하기 위해 계정에서 24시간 동안 업로드할 수 있는 영상 피드 수를 제한하고 있습니다.&nbsp;</p>
            <p>영상 피드를 업로드하려고 할 때 &apos;일일 업로드 한도 도달&apos; 메시지가 표시되었다면, 대략 24시간 후에 다시 업로드를 시도하는 것이 좋습니다.</p>
            <p>&nbsp;</p>
            <p>
                <u>
                    따라서 다음과 같은 콘텐츠는 게시, 업로드, 스트리밍 또는 공유 금지입니다. 목록은 일부에 불과하며, 모든 사례를 포함하고 있지는 않습니다. 목록은 일부에 불과하며, 모든 사례를 포함하고
                    있지는 않습니다.
                </u>
            </p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>시청자에게&nbsp;무언가를 보여주겠다고 약속하지만 보여주지 않고 외부 사이트로 유인하는 콘텐츠</p>
                </li>
                <li>
                    <p>동일한 콘텐츠를 하나 이상의 계정에 반복하여 게시하는 행위</p>
                </li>
                <li>
                    <p>시청자에게 빠른 수익 창출을 약속하면서 CELEBe 외부 사이트로&nbsp;클릭, 조회 또는 트래픽을 유도하는 콘텐츠</p>
                </li>
                <li>
                    <p>유해한 소프트웨어를 유포하거나 개인 정보를 수집하는 사이트 또는 부정적인 영향을 미치는 다른 사이트로 시청자를 유인하는 콘텐츠</p>
                </li>
                <li>
                    <p>품질이나 시청자 경험을 고려하지 않은 채&nbsp;컴퓨터로 자동 생성한 콘텐츠</p>
                </li>
                <li>
                    <p>소프트웨어를 설치하거나, 앱을 다운로드하거나, 다른 행위를 하는 시청자에게 현금, 제품, 소프트웨어 또는 게임 혜택을 무료로 제공한다고 약속하는 콘텐츠</p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
                <u>인센티브 스팸</u>
            </p>
            <p>CELEBe는 조회수, 좋아요 수, 댓글 수와 같은 참여도 측정항목을 판매하는 콘텐츠를 허용하지 않습니다.&nbsp;</p>
            <p>
                이러한 유형의 스팸에는&nbsp;구독자 수, 조회수 또는 기타 측정항목을 늘리는 것이 유일한 목적인 콘텐츠도 포함됩니다. 예를 들면 내 계정을 팔로우하는 조건으로만 다른 크리에이터의 계정을
                팔로우하겠다고 제안하는 &apos;맞팔로우 제안&apos; 콘텐츠가 여기에 해당합니다.
            </p>
            <p>플랫폼 상에서 인기를 실제보다 부풀리기 위한 모든 콘텐츠와 행동을 포함합니다. &nbsp;CELEBe는 인터랙션 수치를 늘리기 위한 모든 플랫폼 조작 시도를 금지합니다.&nbsp;</p>
            <p>
                <u>
                    따라서 다음과 같은 콘텐츠는 게시, 업로드, 스트리밍 또는 공유 금지입니다. 목록은 일부에 불과하며, 모든 사례를 포함하고 있지는 않습니다. 목록은 일부에 불과하며, 모든 사례를 포함하고
                    있지는 않습니다.
                </u>
            </p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>시청자의&nbsp;팔로우를 유도할&nbsp;목적의 동영상</p>
                </li>
                <li>
                    <p>&apos;맞팔로우 제안&apos; 동영상</p>
                </li>
                <li>
                    <p>다른 콘텐츠 없이 10만 번째 팔로워에게 계정을 넘기겠다고 제안하는 동영상</p>
                </li>
                <li>
                    <p>조회수, 좋아요, 팔로워, 공유 또는 댓글을 인위적으로 늘리는 방법 공유&nbsp;</p>
                </li>
                <li>
                    <p>조회수, 좋아요, 팔로워, 공유 또는 댓글에 대한 매매 행위나 시도&nbsp;</p>
                </li>
                <li>
                    <p>인위적인 트래픽 생성 서비스 홍보</p>
                </li>
                <li>
                    <p>상업적 스팸 배포를 위한 다수의 가명 또는 거짓 CELEBe 계정 운영</p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
                <u>댓글 스팸</u>
            </p>
            <p>
                CELEBe는 시청자의 개인 정보를 수집하거나 잘못된 정보로 시청자를 CELEBe 외부 사이트로 유인하거나 위에 설명된 금지 행동을 하는 것을 유일한 목적으로 작성된 댓글을 허용하지 않습니다.&nbsp;
            </p>
            <p>
                이러한 유형의 스팸에는&nbsp;구독자 수, 조회수 또는 기타 측정항목을 늘리는 것이 유일한 목적인 콘텐츠도 포함됩니다. 예를 들면 내 계정을 구독하는 조건으로만 다른 크리에이터의 계정을
                구독하겠다고 제안하는 &apos;맞구독 제안&apos; 콘텐츠가 여기에 해당합니다.
            </p>
            <p>
                <u>
                    따라서 다음과 같은 콘텐츠는 게시, 업로드, 스트리밍 또는 공유 금지입니다. 목록은 일부에 불과하며, 모든 사례를 포함하고 있지는 않습니다. 목록은 일부에 불과하며, 모든 사례를 포함하고
                    있지는 않습니다.
                </u>
            </p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>다단계 판매를 홍보하는 설문조사 또는 증정품 관련 댓글</p>
                </li>
                <li>
                    <p>&apos;클릭당 지불&apos; 추천 링크가 포함된 댓글</p>
                </li>
                <li>
                    <p>다음과 같은 유형의 전체 동영상 콘텐츠를 제공한다고 허위로 주장하는 댓글</p>
                    <ul style={{ paddingLeft: '15px' }}>
                        <li>
                            <p>영화</p>
                        </li>
                        <li>
                            <p>TV 프로그램</p>
                        </li>
                        <li>
                            <p>콘서트</p>
                        </li>
                    </ul>
                </li>
                <li>
                    <p>댓글에 유해한 소프트웨어 또는 피싱 사이트 링크 게시 (예: &apos;게임 머니&nbsp;받아 가세요! - [xyz phishing site].com&apos;)</p>
                </li>
                <li>
                    <p>모조품 매장 링크가 포함된 댓글</p>
                </li>
                <li>
                    <p>게시 된 동영상의 내용과 관련 없는 댓글 (예: &apos;제 계정/동영상을 확인해 보세요!&apos;)</p>
                </li>
                <li>
                    <p>내 계정 링크가 들어간 동일한 댓글을 반복적으로 게시</p>
                </li>
                <li>
                    <p>내용이 같거나 뚜렷한 대상이 없는 댓글을 반복적으로 게시</p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
                <u>혼동을 야기하는 썸네일</u>
            </p>
            <p>CELEBe는 썸네일, 설명란을 이용하여 사용자가 콘텐츠의 내용을 다른 내용으로 오해하도록 속이는 콘텐츠를 허용하지 않습니다.&nbsp;</p>
            <p>
                <u>
                    따라서 다음과 같은 콘텐츠는 게시, 업로드, 스트리밍 또는 공유 금지입니다. 목록은 일부에 불과하며, 모든 사례를 포함하고 있지는 않습니다. 목록은 일부에 불과하며, 모든 사례를 포함하고
                    있지는 않습니다.
                </u>
            </p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>콘텐츠와 무관한 유명인 사진을 썸네일에 사용</p>
                </li>
                <li>
                    <p>썸네일, 설명을 이용해 사용자가 콘텐츠의 내용을 다른 내용으로 오해하도록 하는 콘텐츠</p>
                </li>
            </ul>

            <p>
                <u>사칭</u>
            </p>
            <p>CELEBe는 다른 사람이나 단체로 사칭하는 계정을 허용하지 않습니다.&nbsp;</p>
            <p>사칭 관련 보고 접수 후 사칭이 사실로 확인된 경우, 해당 사용자에게 프로필 수정을 요청하거나 사용자의 계정을 일시적으로 정지 또는 사용 금지합니다.&nbsp;</p>
            <p>패러디, 논평, 팬 계정의 경우, 사용자가 프로필과 사용자 명에 팬, 논평 또는 패러디임을 밝히고 계정의 주제인 대상과 연관이 없음을 밝힌다면 이를 허용합니다.&nbsp;</p>
            <p>
                <u>따라서 다음과 같은 행위 금지입니다.</u>
            </p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>남을 속이기 위해 타인의 이름, 이력 정보 또는 프로필 사진을 사용하여 타인을 사칭</p>
                </li>
            </ul>
            <p className='title-3'>▶개인 정보 침해</p>
            <p>개인정보 침해 신고 절차에 대한 자세한 설명과 개인정보 침해 신고 평가 시 고려 요인에 대해 다룹니다.</p>
            <p>개인을 고유하게 식별할 수 있는지 여부를 평가할 때 고려되는 요인은 다음과 같습니다.</p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>이미지 또는 음성</p>
                </li>
                <li>
                    <p>이름</p>
                </li>
                <li>
                    <p>금융 정보</p>
                </li>
                <li>
                    <p>연락처 정보</p>
                </li>
                <li>
                    <p>기타 개인 식별 정보</p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p>
                개인 또는 계정의 명의를 도용할 목적을 가진 콘텐츠는 CELEBe에서 허용되지 않습니다. 또한 CELEBe는 상표권자의 권리를 행사합니다. 계정이나 계정의 콘텐츠로 인해 광고 중인 상품 및 서비스의
                출처에 대한 혼동이 발생할 수 있는 경우, 이는 허용되지 않습니다.
            </p>
            <p>아래에 설명된 내용 중 어느 하나라도 해당한다면 콘텐츠를 CELEBe에 게시하지 마세요.&nbsp;</p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>
                        계정 명의 도용:타인의 계정과 비슷하게 보이도록 다른 계정의 프로필, 배경 또는 전반적인 디자인 및 분위기를 모방한 계정입니다.&nbsp;다른 계정을 모방하려는 의도가 명확한 경우 100%
                        동일하지 않아도 계정 명의 도용에 해당됩니다.
                    </p>
                </li>
                <li>
                    <p>개인 명의 도용: 타인이 게시한 것처럼 보이도록 의도한 콘텐츠입니다.</p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p>
                <u>
                    따라서 다음과 같은 콘텐츠는 게시, 업로드, 스트리밍 또는 공유 금지입니다. 목록은 일부에 불과하며, 모든 사례를 포함하고 있지는 않습니다. 목록은 일부에 불과하며, 모든 사례를 포함하고
                    있지는 않습니다.
                </u>
            </p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>다른 계정의 이름에 공백을 하나 삽입하거나 영문자 O 대신 숫자 0을 사용하는 정도의 미미한 차이가 있을 뿐 다른 계정과 이름 및 이미지가 동일한 계정</p>
                </li>
                <li>
                    <p>타인의 실명, 사용자 이름, 이미지, 브랜드, 로고 또는 기타 개인정보를 사용해 해당 인물의 행세를 하는 콘텐츠&nbsp;</p>
                </li>
                <li>
                    <p>다른 계정과 정확히 일치하는 계정 설명</p>
                </li>
                <li>
                    <p>특정 인물의 이름과 이미지를 사용해 계정을 설정한 후 해당 인물이 이 계정에 콘텐츠를 게시하는 것으로 가장</p>
                </li>
                <li>
                    <p>특정 인물의 이름과 이미지를 사용해 계정을 설정한 후 해당 인물로 가장해 다른 계정에 댓글을 게시</p>
                </li>
                <li>
                    <p>&apos;팬 계정&apos;이라고 주장하지만 실제로는 타인의 계정로 가장해 해당 계정의 콘텐츠를 다시 업로드하는 계정</p>
                </li>
                <li>
                    <p>기존 뉴스 계정을 사칭하는 계정</p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p>
                <u>개인 정보 보호, 개인 정보 및 개인 식별 정보(PII)&nbsp;</u>
            </p>
            <p>CELEBe는 개인 정보 또는 개인 식별 정보(예: 사회 보장 정보)를 노출하는 콘텐츠를 허용하지 않습니다.</p>
            <p>개인 정보나 개인 식별 정보(PII)를 묘사하는 콘텐츠는 삭제될 수 있습니다.</p>
            <p>
                <u>
                    따라서 다음과 같은 콘텐츠는 게시, 업로드, 스트리밍 또는 공유 금지입니다. 목록은 일부에 불과하며, 모든 사례를 포함하고 있지는 않습니다. 목록은 일부에 불과하며, 모든 사례를 포함하고
                    있지는 않습니다.
                </u>
            </p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>개인 정보나 개인 식별 정보(PII)를 포함한 콘텐츠</p>
                </li>
            </ul>
            <p className='title-3'>▶지적 재산권 침해 </p>
            <p>CELEBe는 모든 사용자에게 오리지널 콘텐츠를 만들어 공유할 것을 권장합니다.&nbsp;</p>
            <p>타인의 지적 재산권을 침해하는 콘텐츠는 우리 플랫폼에서 금지되어 있으며, 발견 즉시 관련 콘텐츠는 삭제됩니다.&nbsp;</p>
            <p>&nbsp;</p>
            <p>
                <u>따라서 다음과 같은 콘텐츠는 게시, 업로드, 스트리밍 또는 공유 금지입니다. 목록은 일부에 불과하며, 모든 사례를 포함하고 있지는 않습니다.</u>
            </p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>TV 프로그램, 영화, 온라인 동영상 등의 시청각 작품</p>
                </li>
                <li>
                    <p>음원 및 음악 작품</p>
                </li>
                <li>
                    <p>강연, 기사, 도서, 음악작품 등의 저술 작품</p>
                </li>
                <li>
                    <p>그림, 포스터, 광고 등의 시각 작품</p>
                </li>
                <li>
                    <p>비디오 게임 및 컴퓨터 소프트웨어</p>
                </li>
                <li>
                    <p>연극, 뮤지컬 등의 극 작품</p>
                </li>
                <li>
                    <p>타인의 저작권, 상표권 또는 기타 지적재산권을 위반 또는 침해하는 콘텐츠</p>
                </li>
            </ul>
            <p className='title-3'>▶플랫폼 보안</p>
            <p>우리 정책은 CELEBe 서비스의 보안과 신뢰성을 저해할 수 있는 다음과 같은 모든 활동을 금하고 있습니다.&nbsp;</p>
            <p>
                <u>따라서 다음과 같은 행위 금지입니다.</u>
            </p>
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    <p>CELEBe 웹사이트, 앱 또는 관련 네트워크를 해킹하거나 사용자 액세스를 제한하기 위한 조치를 우회하려 하지 마시기 바랍니다.&nbsp;</p>
                </li>
                <li>
                    <p>바이러스, 트로이 목마 (악성코드), 웜, 논리 폭탄 및 기타 악의적 또는 유해한 내용을 포함하고 있는 파일을 배포하지 마시기 바랍니다.&nbsp;</p>
                </li>
                <li>
                    <p>파일, 표 또는 문서를 포함한 CELEBe에 기반한 파생적 제품을 수정, 변용, 번역, 역설계, 해체, 역컴파일 &nbsp;만들지 마시기 바랍니다.&nbsp;</p>
                </li>
                <li>
                    <p>CELEBe에 내제된 소스 코드, 알고리즘, 체계 또는 테크닉을 재생하려는 시도를 하지 마시기 바랍니다.</p>
                </li>
                <li>
                    <p>자동화된 스크립트를 사용해서 CELEBe로부터 정보를 수집하지 마시기 바랍니다.</p>
                </li>
            </ul>

            <p>&nbsp;</p>
            <p>활발한 글로벌 플랫폼 CELEBe의 일원으로 CELEBe가 모든 사용자에게 환영 받는 공간이 될 수 있도록 협력해주셔서 감사합니다.</p>
            <p>CELEBe 가이드 라인을 위반한다고 생각되는 콘텐츠나 계정을 발견한 경우, CELEBe가 검토하여 적절한 조치를 취할 수 있도록 알려주시기 바랍니다.&nbsp;</p>
            <p>&nbsp;</p>
        </div>
    );
}
