import React from "react";
import KoPolicy231016 from "./KoPolicy231016";
import KoPolicy231113 from "./KoPolicy231113";
import KoPolicy231209 from "./KoPolicy231209";

export default function policyKo({ theme, date }) {
    const policy = () => {
        switch (date) {
            case "231016":
                return <KoPolicy231016 />;
            case "231113":
                return <KoPolicy231113 />;
            case "231208":
                return <KoPolicy231209 />;
            default:
                return <KoPolicy231209 />;
        }
    };
    return <div className={`policy-ko ${theme === "dark" ? "dark" : ""}`}>{policy()}</div>;
}
