import React from "react";
import EnPolicy231016 from "./EnPolicy231016";
import EnPolicy231113 from "./EnPolicy231113";
import EnPolicy231208 from "./EnPolicy231208";

export default function policyEn({ theme, date }) {
    const policy = () => {
        switch (date) {
            case "231016":
                return <EnPolicy231016 />;
            case "231113":
                return <EnPolicy231113 />;
            case "231208":
                return <EnPolicy231208 />;
            default:
                return <EnPolicy231208 />;
        }
    };

    return <div className={`policy-en ${theme === "dark" ? "dark" : ""}`}>{policy()}</div>;
}
