import React, { useEffect, useState } from "react";
import AdvertisingInformationKo from "./AdvertisingInformationKo";
import AdvertisingInformationEn from "./AdvertisingInformationEn";
import "./styles.scss";

export default function AdvertisingInformation() {
    const [lang, setLang] = useState("ko");

    useEffect(() => {
        const uri = new URL(window.location);
        const lang = uri.searchParams.get("lang");
        if (lang) setLang(lang);
    }, []);

    return <div className="app-container">{lang === "ko" ? <AdvertisingInformationKo /> : <AdvertisingInformationEn />}</div>;
}
